import React, { useState, useRef, useEffect } from "react";
import { Handle } from "react-flow-renderer";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import nodes from "@/json/nodes";
import triggerTypes from "@/json/triggers.json";

import StepList from "@/components/compatibleStepList";
import Bubble from "@/components/bubble";

const handlerSourceStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "auto",
  bottom: "-7px",
  transform: "translateX(-50%)",
};

const TriggerNode = (node) => {
  const { elements } = useSelector((state) => state.flow);
  const { t } = useTranslation();

  const isValidConnection = (destinationNode) => {
    const currentNode = elements[node.id];
    const targetNode = elements[destinationNode];
    if (!targetNode) return;
    return nodes[currentNode.type].compatibleNode.includes(targetNode.type);
  };

  const showStepRef = useRef(null);
  const [showStepsBubble, setShowStepsBubble] = useState(false);

  const addChild = (type, elements) => {
    node.data.handleCreateNewNode(
      type,
      {
        ...elements[node.id],
        id: node.id,
      },
      elements
    );
    setShowStepsBubble(false);
  };

  const stepOptionsComponent = (
    <Bubble
      visible={showStepsBubble}
      style={{ maxHeight: "100px" }}
      className="overflow-y-auto"
      handleClickOutside={() => setShowStepsBubble(false)}
      wrapperRef={showStepRef}
    >
      <div className="py-4 px-2 w-60">
        <StepList
          visible={true}
          nodeType={elements[node.id]?.type}
          handleSelectStep={(step) => addChild(step, elements)}
        />
      </div>
    </Bubble>
  );

  // Set trigger node as 'selected' every initial load of workflow
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (Object.keys(elements[node.id]?.data || {}).length && initialRender) {
      node.data.handleShowNodeDetail(node);
      setInitialRender(false);
    }
  }, [elements]);

  // Get AccountId from localStorage for http_request trigger
  const [accountId, setAccountId] = useState();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("CPV3_User") || {});
    setAccountId(user?.AccountId || "");
  }, []);

  return (
    <div className="bg-white shadow rounded w-72 relative">
      <header className="text-base px-4 py-3 flex items-center font-bold text-gray-700 border-b border-gray-200 relative">
        <span className="-mt-6 inline-flex justify-center items-center w-12 h-12 p-1 rounded bg-gradient-to-tr from-blue-500 to-indigo-500 mr-2">
          <i className="material-icons text-white text-4xl">bolt</i>
        </span>
        {t(triggerTypes[node.data.trigger].titlets)}
      </header>
      <div
        role="presentation"
        onClick={() => node.data.handleShowNodeDetail(node)}
      >
        <figure className="px-4 py-2 flex flex-col">
          {node.data.trigger === "http_request" ? (
            <>
              <figcaption className="text-gray-500 text-xs mb-1">
                {t("column_labels.account_id")}
              </figcaption>
              <p className="text-base break-all font-medium text-gray-800">
                {accountId || "https://api.myproduct.com/v1"}
              </p>
            </>
          ) : (
            <>
              <figcaption className="text-gray-500 text-xs mb-1">
                {t("column_labels.subaccount_id")}
              </figcaption>
              <p className="text-base text-gray-800 font-bold break-all">
                {node.data.subAccountId}
              </p>
            </>
          )}
        </figure>
      </div>
      <footer className="relative">
        <span
          role="button"
          tabIndex={0}
          ref={showStepRef}
          onClick={(e) => {
            e.preventDefault();
            setShowStepsBubble(!showStepsBubble);
            node.data.handleHideSidebar();
          }}
          className={`cursor-pointer border-t border-gray-200 flex items-center justify-center text-blue-500 p-2 font-medium rounded-b transition-colors duration-100 ease-in hover:bg-blue-500 hover:text-white relative ${
            showStepsBubble ? "bg-blue-500 text-white" : ""
          }`}
        >
          <i className="material-icons text-lg mr-1">add</i>
          {!(node?.data?.target).length
            ? t("automation.add_next_step")
            : t("automation.insert_next_step")}
        </span>

        <Handle
          id="a"
          isValidConnection={({ target }) => isValidConnection(target)}
          type="source"
          position="bottom"
          style={handlerSourceStyle}
        />
        {stepOptionsComponent}
      </footer>
    </div>
  );
};

export default TriggerNode;
