import React, { useState, useEffect, useRef } from "react";
import { Handle } from "react-flow-renderer";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import { Select, Form, Popconfirm } from "antd";

const { Option } = Select;

import nodes from "@/json/nodes";
import Bubble from "@/components/bubble";
import StepList from "@/components/compatibleStepList";
import Tag from "@/components/tag";

const handlerTargetStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "-7px",
  bottom: "auto",
  transform: "translateX(-50%)",
};

const handlerSourceStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "auto",
  bottom: "-7px",
  transform: "translateX(-50%)",
};

const JumpToNode = (node) => {
  const { elements, selectedNode } = useSelector((state) => state.flow);
  const { t } = useTranslation();

  const formButtonRef = useRef(null);

  const isValidConnection = (destinationNode) => {
    const currentNode = elements[node.id];
    const targetNode = elements[destinationNode];
    if (!targetNode || destinationNode === node.id) return;
    return nodes[currentNode.type].compatibleNode.includes(targetNode.type);
  };

  const showStepRef = useRef(null);
  const [showStepsBubble, setShowStepsBubble] = useState(false);
  const [selectedStep, setSelectedStep] = useState({});
  const [jumpableStepsList, setJumpableStepsList] = useState([]);
  const [form] = Form.useForm();

  const addChild = (type) => {
    node.data.handleCreateNewNode(type, {
      ...elements[node.id],
      id: node.id,
    });
    setShowStepsBubble(false);
  };

  const stepOptionsComponent = (
    <Bubble
      visible={showStepsBubble}
      style={{ maxHeight: "100px" }}
      className="overflow-y-auto"
      handleClickOutside={() => setShowStepsBubble(false)}
      wrapperRef={showStepRef}
    >
      <div className="py-4 px-2 w-60">
        <StepList
          visible={true}
          nodeType={elements[node.id]?.type}
          handleSelectStep={(step) => addChild(step)}
        />
      </div>
    </Bubble>
  );

  const isExistInSource = (source, sourceHandle) => {
    if (sourceHandle !== "default-handle") return false;
    return elements[source].target.includes(node.id);
  };

  const isDirectParent = (nod, currentNode) => {
    return nod?.target && nod?.target.includes(currentNode?.inputs?.nameId);
  };

  const isBranchParent = (nod, nodes, currentNode) => {
    if (nod?.type.toLowerCase().match(/branch|voice|voicemessage/i)) {
      if (nod?.target && nod?.target.length) {
        return nod.target.some(
          (v) =>
            nodes[v]?.target &&
            nodes[v]?.target.includes(currentNode?.inputs?.nameId)
        );
      }
    }

    return false;
  };

  const isBranchDirectSibling = (nod, nodes, currentNode) => {
    if (nod?.source && nod?.source.length) {
      if (
        nodes[nod.source[0]]?.type.toLowerCase().match(/childbranch/i) &&
        nodes[nod.source[0]]?.source &&
        nodes[nod.source[0]]?.source.length
      ) {
        const branchParent = nodes[nod.source[0]].source[0];
        if (nodes[branchParent]) {
          return nodes[branchParent].target.some((v) => {
            return (
              nodes[v]?.target &&
              nodes[v]?.target.length &&
              nodes[v]?.target.includes(currentNode?.inputs?.nameId)
            );
          });
        }
      }
    }

    return false;
  };

  const isDirectTarget = (nod, currentNode) => {
    if (
      nod?.source &&
      nod?.source.length &&
      nod?.source.includes(currentNode?.inputs?.nameId)
    ) {
      return true;
    }

    return false;
  };

  const isNotValidJumpNode = (nod) => {
    return !["jumpto", "childbranch"].includes(nod?.type.toLowerCase());
  };

  const isJumpableNode = (nod, nodes, currentNode) => {
    return (
      isNotValidJumpNode(nod) &&
      !isDirectParent(nod, currentNode) &&
      !isBranchParent(nod, nodes, currentNode) &&
      !isBranchDirectSibling(nod, nodes, currentNode) &&
      !isDirectTarget(nod, currentNode)
    );
  };

  const getJumpableSteps = (nodes = {}, currentNode = {}) => {
    let jumpableSteps = Object.keys(nodes).reduce((a, b, i) => {
      const obj = {};

      if (
        nodes[b].source &&
        nodes[b].source.length &&
        nodes[b]?.inputs?.nameId &&
        nodes[b].type
      ) {
        obj.stepType = nodes[b]?.type;
        obj.stepName = nodes[b].inputs.nameId;
        obj.jumpable = isJumpableNode(nodes[b], nodes, currentNode);
        a.push(obj);
      }

      return a;
    }, []);

    if (jumpableSteps.length) {
      jumpableSteps = jumpableSteps.filter((v) => v.jumpable);
    }

    return jumpableSteps;
  };

  const handleStepChange = (value, jumpableSteps = []) => {
    const stepObj = jumpableSteps.find((v) => v.stepName === value);

    setSelectedStep(stepObj || {});

    const { id } = node;
    const newData = { ...elements[id] };
    delete newData.nodeId;

    // form.setFieldsValue({ stepName: value });

    node.data.handleUpdateNodeValue({
      id,
      inputs: { ...stepObj },
    });
  };

  useEffect(() => {
    if (
      (elements[node.id]?.source && elements[node.id]?.source.length) ||
      (elements[node.id]?.target && elements[node.id]?.target.length)
    ) {
      const jumpNodes = getJumpableSteps(elements, elements[node.id]);

      setJumpableStepsList(jumpNodes);

      let selected = jumpNodes.length ? jumpNodes[0] : {};

      if (elements[node.id]?.inputs?.stepName) {
        selected = { ...elements[node.id]?.inputs };
      }

      setSelectedStep(selected);

      form.setFieldsValue({ stepName: selected?.stepName });
    }
  }, [elements[node.id]]);

  return (
    <div className="bg-white shadow rounded w-72 relative custom_node">
      <header className="text-base px-4 py-3 flex items-center font-bold text-gray-700 border-b border-gray-200 relative">
        {!Object.keys(elements[node.id]?.inputs || {}).length ? (
          <Tag className="absolute -top-2 right-2 text-white bg-yellow-500">
            {t("app_labels.new").toUpperCase()}
          </Tag>
        ) : (
          ""
        )}
        <Handle
          type="target"
          position="top"
          style={handlerTargetStyle}
          isValidConnection={({ source, sourceHandle }) =>
            isValidConnection(source) && !isExistInSource(source, sourceHandle)
          }
        />
        <span className="-mt-6 inline-flex justify-center items-center w-12 h-12 p-1 rounded bg-gradient-to-tr from-blue-500 to-indigo-500 mr-2">
          <i className="material-icons text-white text-2xl">next_plan</i>
        </span>
        <span class="text-sm">{t("automation.jump_to_another_step")}</span>
      </header>
      <div ref={formButtonRef} className="px-4 py-3 flex flex-col relative">
        <figure>
          <figcaption className="text-gray-500 text-xs mb-1">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              className="space-y-5 w-60"
            >
              <Form.Item
                name="stepName"
                initialValue={selectedStep?.stepName}
                label={
                  <h3 class="text-gray-500 text-xs mb-1 font-bold">
                    {t("automation.select_step")}
                  </h3>
                }
                rules={[
                  {
                    required: true,
                    message: t("validations.required", {
                      value: t("automation.step"),
                    }),
                  },
                ]}
              >
                <Select
                  name="jumpToSteps"
                  showSearch
                  disabled={!jumpableStepsList.length}
                  placeholder={t("automation.select_step")}
                  onChange={(value) => {
                    handleStepChange(value, jumpableStepsList);
                  }}
                  className="w-100 block"
                >
                  {jumpableStepsList.map((item, i) => (
                    <Option value={item.stepName} key={i}>
                      {item.stepName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </figcaption>
        </figure>
        <div className="flex">
          <div className="flex-auto">
            {selectedStep?.stepType && (
              <div>
                <p class="text-xs mb-2">{t("automation.step_type")}</p>
                <p className="text-blue-600 bg-blue-100 py-1 px-2 text-xs rounded-full font-bold tracking-wide items-cente inline-flex r">
                  {selectedStep?.stepType || t("automation.no_step_selected")}
                </p>
              </div>
            )}
          </div>
          <div className="flex-shrink text-right ml-3 pt-4">
            <Popconfirm
              placement="top"
              title={t("confirmations.delete2", { item: t("automation.step") })}
              onConfirm={() => {
                node.data.handleDeleteNode(node);
              }}
              okText={`${t("actions.delete")} ${t("automation.step")}`}
              cancelText={t("actions.cancel")}
            >
              <a
                href="/"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
                className="text-gray-400 text-xs hover:text-red-500"
              >
                {t("actions.delete")}
              </a>
            </Popconfirm>
          </div>
        </div>
      </div>
      {/* <footer className="relative">
        {!(node?.data?.target).length ? (
          <span
            role="button"
            tabIndex={0}
            ref={showStepRef}
            onClick={(e) => {
              e.preventDefault();
              setShowStepsBubble(!showStepsBubble);
              node.data.handleHideSidebar();
            }}
            className={`cursor-pointer border-t border-gray-200 flex items-center justify-center text-blue-500 p-2 font-medium rounded-b transition-colors duration-100 ease-in hover:bg-blue-500 hover:text-white relative ${
              showStepsBubble ? "bg-blue-500 text-white" : ""
            }`}
          >
            <i className="material-icons text-lg mr-1">add</i>
            Add next step
          </span>
        ) : (
          <div className="border-t border-gray-200 font-medium text-blue-500 text-sm text-center px-4 py-3 ">
            {
              nodes[elements[node?.data?.target[0]]?.type || "trigger"]
                ?.handlerLabel
            }
          </div>
        )}
        <Handle
          id="a"
          isValidConnection={({ target }) => isValidConnection(target)}
          type="source"
          position="bottom"
          style={handlerSourceStyle}
        />
        {stepOptionsComponent}
      </footer> */}
    </div>
  );
};

export default JumpToNode;
