import React, { useState, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { Form, Radio, Space, Button } from "antd";

import { useTranslation, Trans } from "react-i18next";

import triggerTypes from "@/json/triggers.json";
import SmsForm from "./sms";
import ChatForm from "./chat";
import HttpForm from "./http";

const ACTIONS = {
  INITIALIZE_FORM: "initialize-form",
  UPDATE_VALUE: "update-value",
};

function reducer(formData, action) {
  const { item, value } = action.payload;
  switch (action.type) {
    case ACTIONS.INITIALIZE_FORM:
      return { ...action.payload };
    case ACTIONS.UPDATE_VALUE:
      return { ...formData, [item]: value };
    default:
      return formData;
  }
}

const TriggerForm = ({
  node,
  smsSubaccountOptions,
  chatSubaccountOptions,
  onUpdateNodeValue,
  sidebarScrollWrapper,
}) => {
  const [form] = Form.useForm();
  const { elements } = useSelector((state) => state.flow);

  const [formData, dispatch] = useReducer(reducer, {});

  const { t } = useTranslation();

  const [subaccountOptions, setSubaccountOptions] = useState([]);

  // Update subaccountOptions based on trigger type
  useEffect(() => {
    if (!smsSubaccountOptions && !chatSubaccountOptions) return;

    // Assign proper subaccount based on the trigger selected
    if (
      !formData.trigger ||
      formData.trigger === "inbound_sms" ||
      formData.trigger === "http_request"
    ) {
      setSubaccountOptions(smsSubaccountOptions);
    } else if (formData.trigger === "inbound_chat_apps") {
      setSubaccountOptions(chatSubaccountOptions);
    }
  }, [
    formData.trigger,
    smsSubaccountOptions,
    chatSubaccountOptions,
    setSubaccountOptions,
  ]);

  useEffect(() => {
    dispatch({
      type: ACTIONS.INITIALIZE_FORM,
      payload: { ...form.getFieldsValue(false) },
    });

    // Set scrollTop to 0
    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Re-render fields if selectedNode is updated but has same type with previous selectedNode
  useEffect(() => {
    form.resetFields();

    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [node]);

  const onChangeFormValue = (item, value) => {
    dispatch({
      type: ACTIONS.UPDATE_VALUE,
      payload: { item, value },
    });
  };

  // Update outputs value and formData when trigger is changed
  useEffect(() => {
    const trigger = triggerTypes[formData?.trigger];
    let outputs = {};

    if (trigger && formData?.trigger === "http_request") {
      // Get http_request default outputs if formData and node trigger type is http_request
      const outpt = Object.keys(node?.data?.outputs).length
        ? { ...node?.data?.outputs }
        : node?.data?.target && node?.data?.target.length
        ? { ...elements[node?.data?.target[0]]?.outputs }
        : {};
      outputs = { ...outpt };

      const formOutpust = Object.keys(outputs || {}).map((key) => {
        return {
          key: key.replace("custom_", ""),
          value: outputs[key],
        };
      });

      form.setFieldsValue({ outputs: formOutpust });
    } else {
      form.setFieldsValue({
        outputs: trigger?.variables || {},
      });
    }

    dispatch({
      type: ACTIONS.INITIALIZE_FORM,
      payload: { ...form.getFieldsValue(false), outputs },
    });
  }, [formData?.trigger]);

  const onSubmitForm = () => {
    form.validateFields().then((values) => {
      // Update store node data
      const { id } = node;
      const oldData = { ...elements[id].data };
      const { outputs, ...newValues } = values;

      // Create an object with a key and value based on outputs
      let newOutput = outputs;
      if (values?.trigger === "http_request") {
        newOutput = Object.keys(outputs || {}).reduce((acc, outputKey, i) => {
          const { key } = outputs[outputKey];
          return {
            ...acc,
            // this is a custom output variables
            [`custom_${key}`]: `variable ${i}`,
          };
        }, {});

        // remove outputs in the first node to be replaced by new outputs
        if (elements[id].target) {
          if (
            elements[elements[id].target].outputs &&
            Object.keys(elements[elements[id].target].outputs).length
          ) {
            onUpdateNodeValue({
              id: elements[id].target,
              outputs: {},
            });
          }
        }
      }

      onUpdateNodeValue({
        id,
        outputs: newOutput,
        data: { ...oldData, ...newValues },
      });

      // if subaccount has value, then assign subaccount to each step which requires subaaccount id
      if (values?.subAccountId) {
        Object.keys(elements).forEach((v) => {
          if (
            elements[v]?.type === "SMS" &&
            values?.trigger === "inbound_sms" &&
            elements[v]?.inputs &&
            !elements[v]?.inputs?.subAccountId
          ) {
            const id = v;
            const inputs = {
              ...elements[v].inputs,
              subAccountId: values?.subAccountId,
            };

            onUpdateNodeValue({
              id,
              inputs,
            });
          }

          if (
            elements[v]?.type === "ChatAppsMessage" &&
            values?.trigger === "inbound_chat_apps" &&
            elements[v]?.inputs &&
            !elements[v]?.inputs?.subAccountId
          ) {
            const id = v;
            const inputs = {
              ...elements[v].inputs,
              subAccountId: values?.subAccountId,
            };

            onUpdateNodeValue({
              id,
              inputs,
            });
          }
        });
      }
    });
  };

  const onSubmitFormFail = (values) => {
    // console.log("Failed to submit form");
  };

  // Render proper form based on trigger type
  let formComponent = null;
  if (formData?.trigger === "inbound_sms") {
    formComponent = (
      <SmsForm
        subAccountId={
          node?.data?.subAccountId || subaccountOptions[0]?.value || ""
        }
        subaccountOptions={subaccountOptions}
        node={node}
        form={form}
        onUpdateForm={onChangeFormValue}
      />
    );
  } else if (formData?.trigger === "inbound_chat_apps") {
    formComponent = (
      <ChatForm
        subAccountId={
          node?.data?.subAccountId || subaccountOptions[0]?.value || ""
        }
        subaccountOptions={subaccountOptions}
        node={node}
        form={form}
        onUpdateForm={onChangeFormValue}
      />
    );
  } else if (formData?.trigger === "http_request") {
    formComponent = (
      <HttpForm
        node={node}
        form={form}
        onUpdateForm={onChangeFormValue}
        subAccountId={
          node?.data?.subAccountId || subaccountOptions[0]?.value || ""
        }
        subaccountOptions={subaccountOptions}
      />
    );
  }

  return (
    <>
      <header className="bg-gradient-to-tr from-blue-500 to-indigo-500 px-5 pt-7 pb-14 w-96">
        <small className="text-xs text-blue-200 tracking-widest">
          {t("automation.trigger_config")}
        </small>
        <h1 className="font-bold text-lg text-white tracking-wider pr-20 leading-snug">
          {t("automation.change_the_trigger")}
        </h1>
      </header>
      <section className="w-96 -mt-14">
        <Form
          form={form}
          layout="vertical"
          name="trigger-form"
          autoComplete="off"
          onFinish={onSubmitForm}
          onFinishFailed={onSubmitFormFail}
          requiredMark={true}
          scrollToFirstError
          className="space-y-10"
        >
          <Form.Item
            name="trigger"
            initialValue={node?.data?.trigger || "SMS"}
            onChange={(e) => {
              onChangeFormValue("trigger", e.target.value);

              // Reset to blank subaccount if trigger is updated
              form.setFieldsValue({
                subAccountId: "",
              });
            }}
            label={
              <h3 className="text-gray-500 text-xs mb-1 font-bold">
                {t("validations.select", {
                  value: t("automation.trigger_type").toLowerCase(),
                })}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("automation.trigger_type").toLowerCase(),
                }),
              },
            ]}
            className="py-3 px-5 m-5 bg-white rounded shadow"
          >
            <Radio.Group name="triggertype" className="block">
              <Space direction="vertical">
                {Object.values(triggerTypes).map((type) => (
                  <Radio value={type.value} key={type.value}>
                    {t(type.labelts)}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
          {formComponent}
          <Form.Item className="px-5">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full sticky bottom-0"
              disabled={!form.isFieldsTouched(false)}
            >
              {t("actions.update")}
            </Button>
          </Form.Item>
        </Form>
        {/* <code className="whitespace-pre text-xs">
          {JSON.stringify(form.getFieldsValue(false), null, "\t")}
        </code> */}
      </section>
    </>
  );
};

export default TriggerForm;
