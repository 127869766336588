import React, { useState, useEffect } from "react";

import ReactFlow, {
  removeElements,
  addEdge,
  updateEdge,
  MiniMap,
  Controls,
  Background,
  useZoomPanHelper,
} from "react-flow-renderer";

import "./flow.scss";

import nodes from "@/json/nodes";
import TriggerNode from "../nodes/trigger.js";
import SmsNode from "../nodes/sms.js";
import WaitReplyNode from "../nodes/waitReply.js";
import HttpRequestNode from "../nodes/httpRequest.js";
import WaitNode from "../nodes/wait.js";
import ChatAppNode from "../nodes/chatApp.js";
import BranchNode from "../nodes/branch.js";
import ChildBranchNode from "../nodes/childBranch.js";
import VoiceNode from "../nodes/voice.js";
import JumpToNode from "../nodes/jumpTo.js";
import SendToConverseNode from "../nodes/converse.js";

import { updateElements } from "../../../store/reducers/flow";
import { getRandomId } from "../../../utils/common";

const nodeTypes = {
  trigger: TriggerNode,
  sms: SmsNode,
  waitReply: WaitReplyNode,
  httpRequest: HttpRequestNode,
  wait: WaitNode,
  chatApp: ChatAppNode,
  branch: BranchNode,
  childBranch: ChildBranchNode,
  voice: VoiceNode,
  jumpTo: JumpToNode,
  sendToConverse: SendToConverseNode,
};

let id = 0;
const getId = (type) =>
  // Use unix timestamp as id for new node to prevent duplicate
  `${(type || "").toLowerCase()}_${getRandomId()}`;

const NODE_HEIGHT = 280;
const NODE_WIDTH = 300;

const OverviewFlow = ({
  data,
  onUpdateEdge,
  onCreateNode,
  onUpdateNodePosition,
  setShowSidebar,
  reactFlowWrapper,
  reactFlowInstance,
  handleSetFlowInstance,
}) => {
  const { setCenter } = useZoomPanHelper();
  const [elements, setElements] = useState([]);

  useEffect(() => {
    setElements(data);
  }, [data]);

  const onLoad = (_reactFlowInstance) => {
    handleSetFlowInstance(_reactFlowInstance);
  };

  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  // const onConnect = (params) =>
  //   setElements((els) => addEdge({ ...params, type: "smoothstep" }, els));
  const onConnect = (params) => {
    onUpdateEdge(params);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    onCreateNode(type, { position });
  };

  const onEdgeUpdate = (oldEdge, newConnection) =>
    setElements((els) => {
      updateEdge(oldEdge, newConnection, els);
      // setFlowData({});
    });

  const onPaneClick = () => {
    // Set selectedNode to empty object
    setShowSidebar(false);
  };

  const onNodeDragStop = (e, node) => {
    const position = {
      x: node.position.x + NODE_WIDTH / 2,
      y: node.position.y + NODE_HEIGHT / 2,
    };

    onUpdateNodePosition({
      ...node,
      position,
    });
  };

  return (
    <ReactFlow
      elements={elements}
      nodeTypes={nodeTypes}
      defaultZoom={0.8}
      onElementsRemove={onElementsRemove}
      onPaneClick={onPaneClick}
      onConnect={onConnect}
      onLoad={onLoad}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onEdgeUpdate={onEdgeUpdate}
      onNodeDragStop={onNodeDragStop}
      snapToGrid
      snapGrid={[20, 20]}
      style={{ backgroundColor: "#F5F7FF" }}
      className="validationflow bg-blue-50"
      deleteKeyCode={null}
    >
      {/* <MiniMap
        nodeStrokeColor={(n) => {
          if (n.style?.background) return n.style.background;
          if (n.type === "input") return "#0041d0";
          if (n.type === "output") return "#ff0072";
          if (n.type === "default") return "#1a192b";
          if (n.type === "trigger") return "#0041d0";

          return "#eee";
        }}
        nodeColor={(n) => {
          if (n.style?.background) return n.style.background;

          return "#fff";
        }}
        nodeBorderRadius={2}
        maskColor="rgba(224, 231, 255, 0.5)"
        style={{ backgroundColor: "#F5F7FF" }}
      /> */}
      <Controls
        showInteractive={false}
        style={{
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
        }}
      />
      <Background gap={16} size="1" color="#E0E7FF" />
    </ReactFlow>
  );
};

export default OverviewFlow;
