import { CloseCircleFilled } from "@ant-design/icons";
import { Modal, Space, Typography } from "antd";
const { Text } = Typography;

/**
 * Shows an error modal containing parsed API error data.
 * This has a similar logic to the error handler of the main-portal app.
 * @param  {object} [result]  The return we got from a rejected promise
 * @param  {object} [t]       The i18n function from a view since importing it here directly causes an error
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {void}             Displays an error modal with
 */
export const showError = (result, t, options = {}) => {
  const message = getErrorMsg(result, t, options);
  reportError(result, message);
  Modal.error({
    title: (
      <Text type="danger">
        {message.originalError === "impersonation_forbidden_error"
          ? t("errors.read_only")
          : t("errors.oh_snap")}
      </Text>
    ),
    content: (
      <Space direction="vertical">
        <Text strong>{message.main}</Text>
        <Text strong type="danger">
          {message.info}
        </Text>
      </Space>
    ),
    icon: <CloseCircleFilled />,
    okText: "Close",
    centered: true,
    closable: true,
    destroyOnClose: true,
    afterClose: () => {
      if (options.refreshPage) window.location.reload();
    },
  });
};

/**
 * Parses the result and returns a filtered error object
 * @param  {object} [result]  The object we got from showError() function
 * @param  {object} [t]       The i18n function from showError() function
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing multiple key-value pairs for error handling
 */
const getErrorMsg = (result, t, options = {}) => {
  const error = (result && result.error) || result;
  let statusCode = 0;
  let main = t("errors.default");
  let info = t("errors.unexpected_error");
  let contactUs = "";
  let errors = [];
  let originalError = "Unexpected error";

  if (error.code) {
    statusCode = error.code;
  }

  if (error.message) {
    originalError = error.message;
    info = t(`apiErrors.${error.message}`);
    errors.push(info);
  }

  switch (statusCode) {
    case 400:
      main = t("errors.400");
      break;
    case 401:
      main = t("errors.401");
      break;
    case 422:
      main = t("errors.invalid");
      break;
    case 403:
      main = t("errors.403");
      break;
    case 404:
      main = t("errors.404");
      break;
    case 503:
    case 501:
    case 502:
    case 500:
      main = t("errors.500");
      break;
    default:
  }

  if ([0, 500].includes(statusCode)) {
    contactUs = t("errors.try_again");
  }

  if (info.match(/access forbidden/i)) {
    main = t("errors.403");
  }

  if (info.match(/Unexpected token/i)) {
    info = t("errors.unexpected_error");
    errors.push(info);
  }

  if (options.useMessage) {
    main = options.useMessage;
  }

  return {
    main,
    contactUs,
    statusCode,
    info,
    errors,
    originalError,
  };
};

/**
 * Reports errors to BugSnag for monitoring
 * @param  {object} [message] The result we got from an API
 * @return {void}             Sends the parsed error.info to BugSnag
 */
const reportError = (result, message) => {
  if (window.Bugsnag) {
    console.error(result); // eslint-disable-line
    window.Bugsnag.notify(new Error(message.info));
  }
};
