const dtmfFormDataToSelectNextStep = (dtmfValues = [], nameId = "") => {
  const id = nameId ? `${nameId}_` : "";

  const toObj = dtmfValues.reduce((a, b) => {
    if (b.condition === "==") {
      if (b.value) {
        a[`equal_to_${b.value}`] = `{{data.${id}step_dtmf == '${b.value}'}}`;
      } else {
        a[`equal_to_null`] = `{{data.${id}step_dtmf == null }}`;
      }
    }

    if (b.condition === "!=") {
      if (b.value) {
        a[
          `different_from_${b.value}`
        ] = `{{data.${id}step_dtmf != '${b.value}'}}`;
      } else {
        a[`different_from_null`] = `{{data.${id}step_dtmf != null }}`;
      }
    }

    return a;
  }, {});

  return toObj;
};

export default {
  dtmfFormDataToSelectNextStep,
};
