const TemplateButtons = ({ template }) => {
  const buttons = (template?.components || []).find(
    (t) => t.type === "BUTTONS"
  );

  const getButtonTypeIcon = (type) => {
    let button = null;

    if (type === "PHONE_NUMBER") {
      button = (
        <i className="material-icons text-xl text-purple-500 mr-2">call</i>
      );
    } else if (type === "URL") {
      button = (
        <i className="material-icons text-xl text-purple-500 mr-2">link</i>
      );
    }

    return button;
  };

  return buttons ? (
    <ul className="space-y-2 mt-3">
      {(buttons?.buttons || []).map((b, i) => (
        <li key={`template-button-${i}`}>
          <div className="flex items-center justify-center border border-purple-500 text-purple-500 font-bold rounded p-2">
            {getButtonTypeIcon(b?.type)}
            {b?.text}
          </div>
        </li>
      ))}
    </ul>
  ) : (
    ""
  );
};

export default TemplateButtons;
