const TemplateFooter = ({ template }) => {
  const footer = (template?.components || []).find((t) => t.type === "FOOTER");
  return footer ? (
    <p className="p-3 text-xs text-blue-500">{footer?.text}</p>
  ) : (
    ""
  );
};

export default TemplateFooter;
