import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal, message, Upload, Button, notification } from "antd";
import { InboxOutlined } from "@ant-design/icons";
const { Dragger } = Upload;
import { useTranslation, Trans } from "react-i18next";

import {
  getSmsSubaccounts,
  getCaSubaccounts,
  getVoiceSubaccounts,
} from "@/store/reducers/user";

export default function Uploader(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { visible, setModal, createWorkflow, loading } = props;

  const [uploadedFile, setUploadedFile] = useState(null);
  const [subaccount, setSubaccount] = useState(null);

  const {
    smsSubaccounts,
    caSubaccounts,
    voiceSubaccounts,
    voiceLoading,
    caLoading,
    timezones,
  } = useSelector((state) => state.user);

  const uploadProps = {
    name: "file",
    accept: "application/json",
    multiple: false,
    disabled: loading,
    maxCount: 1,
    beforeUpload(file) {
      if (file.type.toLowerCase() !== "application/json") {
        notification.error({ message: t("automation.workflow_valid") });
      }
      return file.type.toLowerCase() === "application/json"
        ? true
        : Upload.LIST_IGNORE;
    },

    onRemove(file) {
      setUploadedFile(null);
    },
    customRequest({ file, onSuccess }) {
      setTimeout(() => {
        setUploadedFile(file);
        onSuccess("ok");
      }, 0);
    },
  };

  const uploadWorkflow = () => {
    var reader = new FileReader();
    reader.readAsText(uploadedFile);
    reader.onload = function (evt) {
      try {
        const obj = JSON.parse(evt.target.result);

        // if (!obj.subAccountId) {
        //   // message.error(
        //   //   "The key `subAccountId` is required. Please replace it with a valid json file."
        //   // );
        //   return;
        // }

        if (!obj.trigger) {
          notification.error({
            message: t("automation.trigger_required", { value: "trigger" }),
          });
          return;
        }

        if (!obj.definition) {
          notification.error({
            message: t("automation.trigger_required", { value: "key" }),
          });
          return;
        }

        const { trigger, definition, subAccountId } = obj;

        createWorkflow({
          trigger,
          status: "disabled",
          subAccountId,
          definition,
        });
      } catch (e) {
        notification.error({
          message: t("errors.unable_to_parse", { value: "JSON file" }),
        });
      }
    };
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setModal({ visible: false });
      }}
      destroyOnClose
      footer={null}
    >
      <div class="mt-10 text-center">
        <h3 class="mb-5 text-xl">{t("automation.upload_workflow")}</h3>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t("upload_labels.click_or_drag")}</p>
          <p className="ant-upload-hint">
            {t("automation.support_single_file")}
          </p>
        </Dragger>
        <div class="mt-5 text-center">
          <Button
            onClick={() => {
              setModal({ visible: false });
            }}
          >
            {t("actions.cancel")}
          </Button>
          <Button
            loading={loading}
            className="ml-5"
            type="primary"
            disabled={!uploadedFile}
            onClick={uploadWorkflow}
          >
            {t("automation.upload_workflow")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
