import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import defaultNodes from "@/json/nodes";
import { useTranslation, Trans } from "react-i18next";

const StepMenu = (props) => {
  const onSelectStep = (stepType) => {
    if (!nodes[stepType].disabled) {
      props.handleSelectStep(stepType);
    }
  };

  const { t } = useTranslation();

  const labelts = {
    sms: t("sidebar_menu_parent_short.SMS"),
    "chat app": t("sidebar_menu_parent.Chat apps"),
    voice: t("sidebar_menu_parent.Voice"),
    http: t("automation.nodes.http_request"),
    "send to converse": t("automation.nodes.send_to_converse"),
    branch: t("automation.nodes.branch"),
    "wait for reply": t("automation.nodes.wait_for_reply"),
    wait: t("automation.nodes.wait"),
    "jump to": t("automation.nodes.jump_to"),
  };

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const {
    hasChatAppsAccess,
    hasSmsAccess,
    hasVoiceAccess,
    smsSubaccounts,
    caSubaccounts,
    voiceSubaccounts,
  } = useSelector((state) => state.user);
  const [nodes, setNodes] = useState({ ...defaultNodes });

  useEffect(() => {
    const newNode = { ...nodes };

    // Set SMS restriction
    newNode.SMS.disabled = !(hasSmsAccess && (smsSubaccounts || []).length);

    // Set CHATAPP restriction
    newNode.ChatAppsMessage.disabled = !(
      hasChatAppsAccess && (caSubaccounts || []).length
    );

    // Set VOICE restriction
    newNode.VoiceMessage.disabled = !(
      hasVoiceAccess && (voiceSubaccounts || []).length
    );

    setNodes(newNode);
  }, [
    setNodes,
    hasChatAppsAccess,
    hasSmsAccess,
    hasVoiceAccess,
    smsSubaccounts,
    caSubaccounts,
  ]);

  return (
    <ul {...props}>
      {Object.keys(nodes)
        .sort((n1, n2) => nodes[n1].disabled - nodes[n2].disabled)
        .map((stepType, i) =>
          stepType !== "trigger" && nodes[stepType].show ? (
            <li key={`stepType-${stepType}-${i}`}>
              <button
                onClick={() => onSelectStep(stepType)}
                onDragStart={(e) => {
                  if (nodes[stepType].disabled) return;

                  onDragStart(e, stepType);
                }}
                className={`border border-dashed border-transparent flex flex-col flex-wrap items-center justify-center w-full font-medium rounded transition-colors duration-200 ${
                  nodes[stepType].disabled
                    ? "text-gray-500"
                    : "hover:bg-indigo-50 text-gray-800"
                }`}
                disabled={nodes[stepType].disabled}
                draggable={!nodes[stepType].disabled}
              >
                <span
                  className={`w-10 h-10 rounded flex items-center justify-center shadow relative ${
                    nodes[stepType].disabled ? "bg-gray-300" : "bg-8x8-red"
                  }`}
                >
                  <i className="material-icons text-white text-xl">
                    {nodes[stepType].icon}
                  </i>
                </span>
                <span className="text-xs mt-2">
                  {labelts[nodes[stepType].label.toLowerCase()]}
                </span>
              </button>
            </li>
          ) : (
            ""
          )
        )}
    </ul>
  );
};

export default StepMenu;
