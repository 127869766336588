import { isNode } from "react-flow-renderer";
import dagre from "dagre";

import nodeList from "../json/nodes.json";
import triggerList from "../json/triggers.json";

import branchHelper from "@/utils/branch-helper";
import { getRandomId } from "@/utils/common";

import moment from "moment";
import "moment-duration-format";

const EDGE_TYPE = "smoothstep";
const NODE_POSITION = { x: 0, y: 0 };
const NODE_HEIGHT = 280;
const NODE_WIDTH = 300;

export const ApiToMapData = (data) => {
  try {
    // Return nothing if data doesn't contain 'definition'
    if (!(data || {}).hasOwnProperty("definition")) {
      return;
    }

    const { definition, ...triggerData } = data;
    const { steps: st, name, version, id = "" } = definition;

    let steps = st;

    let nodeMap = {
      trigger: {
        input: null,
        outputs: null,
        source: [],
        target: [],
        type: "trigger",
        data: { ...triggerData, name, version, id }, // Add data only to trigger to get details of workflow
        branch: false,
      },
    };

    // Set the default output values of trigger
    const nodeVariables = triggerList[data?.trigger || ""]?.variables;

    if (nodeVariables) {
      nodeMap.trigger.outputs = { ...nodeVariables };
    }

    const branchableNodes = Object.keys(nodeList).filter(
      (node) => nodeList[node]?.template?.branch
    );

    // remove wait dtmf steps and transfer it to voice say& capture

    // get the dtmf indexes
    const dtmfIndexes = [];

    steps.forEach((v, i) => {
      if (v.stepType.toLowerCase() === "waitfordtmf") {
        dtmfIndexes.push(i);
      }
    });

    if (dtmfIndexes.length) {
      dtmfIndexes.forEach((v) => {
        const index = parseInt(v, 10);
        const copyDTMFStep = steps[index];

        steps = steps.map((s) => {
          const obj = { ...s };
          if (obj.nextStepId === copyDTMFStep.id) {
            obj.selectNextStep = copyDTMFStep.selectNextStep;
            obj.nextStepId = "";
          }

          return obj;
        });
      });

      steps = steps.filter((v) => v.stepType.toLowerCase() !== "waitfordtmf");
    }

    const copySteps = JSON.parse(JSON.stringify(steps.slice(0)));

    const stepsWithJumpTos = [];

    // inserting jump to logic

    // gather all step ids
    const allStepIds = copySteps.reduce((a, b) => {
      if (b.nextStepId && b.nextStepId !== null) {
        a.push(b.nextStepId);
      }

      if (b.selectNextStep && Object.keys(b.selectNextStep).length) {
        Object.keys(b.selectNextStep).forEach((sns) => {
          a.push(sns);
        });
      }

      return a;
    }, []);

    // then gather all duplicated step ids
    // duplicate step ids could be jumpto targets
    const jumpToIds = allStepIds.reduce(
      (acc, v, i, arr) =>
        arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc,
      []
    );

    //get the first occurence of the duplicated step ids
    jumpToIds.forEach((v) => {
      const findIndex = copySteps.findIndex(
        (i) =>
          i?.nextStepId === v ||
          Object.keys(i?.selectNextStep || {}).includes(v)
      );

      if (findIndex) {
        copySteps[findIndex].excludedtoJump = v;
      }
    });

    // rearrange including jumpTo steps
    // jumpTo steps should not be the first occurence of duplicated step ids
    jumpToIds.forEach((j) => {
      copySteps.forEach((v, i) => {
        const jumpTo = {
          stepType: "JumpTo",
          inputs: {
            stepType: v.stepType,
          },
          nextStepId: null,
          selectNextStep: {},
        };
        if (
          v?.nextStepId &&
          j === v?.nextStepId &&
          (!v.excludedtoJump || v.excludedtoJump !== j) &&
          !v?.nextStepId.match(/jumpto/)
        ) {
          const jumpToId = `jumpto_${getRandomId()}`;
          jumpTo.id = jumpToId;
          jumpTo.inputs = { ...jumpTo.inputs, stepName: v.nextStepId };
          copySteps[i].nextStepId = jumpToId;
          copySteps.push(jumpTo);
        }

        if (
          v?.selectNextStep &&
          Object.keys(v?.selectNextStep).length &&
          (!v.excludedtoJump || v.excludedtoJump !== j) &&
          Object.keys(v?.selectNextStep).some((sns) => sns === j)
        ) {
          const jumpToId = `jumpto_${getRandomId()}`;
          Object.keys(v?.selectNextStep).forEach((sns, index) => {
            if (j === sns && !sns.match(/jumpto/)) {
              jumpTo.id = jumpToId;
              jumpTo.inputs = { ...jumpTo.inputs, stepName: sns };
              const copy = v.selectNextStep[sns];
              copySteps[i].selectNextStep = {
                ...copySteps[i].selectNextStep,
                [jumpToId]: copy,
              };
              delete copySteps[i].selectNextStep[sns];
              copySteps.push(jumpTo);
            }
          });
        }
      });
    });

    // reassign to steps
    steps = copySteps;

    //@davy:for:review check if steps has branch steps and create children steps
    const branchSteps = steps.filter(
      (v) =>
        v.stepType.toLowerCase() === "branch" ||
        (v.stepType.toLowerCase() === "voicemessage" &&
          v.inputs?.action === "say&capture")
    );

    //@davy:for:review add details for child branches
    branchSteps.forEach((v) => {
      if (Object.keys(v.selectNextStep).length) {
        const { conditionName, condition } = branchHelper.extractSelectNextStep(
          v.selectNextStep
        );

        const indexOfBranch = steps.map((s) => s.id).indexOf(v.id) + 1;
        Object.keys(v.selectNextStep).forEach((c, i) => {
          /**
            NOTE: Always insert generated `ChildBranch` right after the parent Branch;
            IT WILL NOT render properly unless we do so.
            hence we're splicing the array to the specific index + 1 after branch index (indexOfStepList)
          **/
          const newSteps = [...steps];

          newSteps.splice(indexOfBranch, 0, {
            stepType: "ChildBranch",
            id: `cb_${c}`,
            inputs: {
              conditionName,
              condition,
              ...branchHelper.extractStepData(v.selectNextStep[c]),
              index: i,
            },
            nextStepId: c,
          });
          steps = newSteps;
        });
      }
    });

    // Iterate through all the steps and generate new data structure
    for (let i = 0; i < steps.length; i++) {
      const {
        id: stepId,
        stepType: type,
        selectNextStep,
        nextStepId,
        inputs = null,
        outputs = null,
      } = steps[i];

      nodeMap[stepId] = {
        target: [],
        ...nodeMap[stepId],
        type,
        inputs: { ...inputs, nameId: stepId }, // Add 'name' field in 'inputs' data
        outputs,
        branch: branchableNodes.includes(type), // If node branches out to multiple nodes
      };

      // Inialize 'source' value
      if (!nodeMap.hasOwnProperty(stepId)) {
        nodeMap[stepId].source = [];
      } else if (!nodeMap[stepId].hasOwnProperty("source")) {
        const sourceValue = i <= 0 ? "trigger" : nodeMap[stepId].source || "";
        if (sourceValue) {
          nodeMap[stepId].source = [sourceValue];
        } else {
          nodeMap[stepId].source = [];
        }
      }

      // Add current node id to parent node if not yet included in `target` array
      const parentNode = nodeMap[nodeMap[stepId]?.source[0]];
      if (
        parentNode &&
        parentNode.target &&
        !parentNode.target.includes(stepId)
      ) {
        parentNode.target.push(stepId);
      }

      // Get children value from either 'nextStepId' or 'selectNextStep'
      let children = [];
      if (nextStepId) {
        children = [nextStepId];
      } else if (type === "WaitForReply") {
        // No reply should be last. hence we are inserting "EMPTY" to match it with the correct handler
        children = Object.keys(selectNextStep || {});
        if (
          (Object.values(selectNextStep || {})[0] || "").includes("== null")
        ) {
          children.unshift("EMPTY");
        }

        // WaitForReply should have 2 items all the time
        if (children.length < 2) {
          children.push("EMPTY");
        }
      } else if (
        type.toLowerCase() === "branch" ||
        (type.toLowerCase() === "voicemessage" &&
          nodeMap[stepId]?.inputs?.action === "say&capture")
      ) {
        // @davy:for:review -  branch details
        delete nodeMap[stepId].nextStepId;

        // apply only in branching since sayandcapture have inputs already
        if (type.toLowerCase() === "branch") {
          nodeMap[stepId].inputs = {
            ...(nodeMap[stepId]?.inputs || {}),
            ...(branchHelper.extractSelectNextStep(selectNextStep) || {}),
          };
          nodeMap[stepId].outputs = {};
        }

        nodeMap[stepId].selectNextStep = Object.keys(selectNextStep).reduce(
          (acc, curr) => {
            return {
              ...acc,
              [`cb_${curr}`]: selectNextStep[curr],
            };
          },
          {}
        );

        Object.keys(selectNextStep || {}).forEach((v, i) => {
          children.push(`cb_${v}`);
        });
      }

      // Set the children of current node
      nodeMap[stepId].target = children;

      // If node has children then create new keys for it
      for (let ci = 0; ci < children.length; ci++) {
        const child = children[ci];
        if (!nodeMap.hasOwnProperty(child)) {
          nodeMap[child] = {
            ...nodeMap[child],
            source: [stepId],
          };
        }
      }
    }

    // console.log("nodeMap", nodeMap);

    return nodeMap;
  } catch (err) {
    console.error(err);
  }
};

export const MapToFlowData = (data, props = {}) => {
  if (!Object.keys(data || {}).length) {
    return [];
  }

  const flowData = Object.keys(data).reduce((acc, curr) => {
    // Dont render "EMPTY" node/step
    if (curr === "EMPTY") return acc;

    const nodeData = data[curr];
    const { target } = nodeData;

    const type = Object.keys(nodeList).includes(nodeData.type)
      ? nodeList[nodeData.type].nodeType
      : "default";
    let nodeConfig = {};

    switch (nodeData.type) {
      case "trigger":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            ...nodeData?.data,
            outputs: nodeData?.outputs,
          },
        };
        break;
      case "SMS":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            ...nodeData?.inputs,
            outputs: nodeData?.outputs,
          },
        };
        break;
      case "ChatAppsMessage":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            ...nodeData?.inputs,
            outputs: nodeData?.outputs,
          },
        };
        break;
      case "SendToConverse":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            ...nodeData?.inputs,
            outputs: nodeData?.outputs,
          },
        };
        break;
      case "HttpRequest":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            ...nodeData?.inputs,
            outputs: nodeData?.outputs,
          },
        };
        break;
      case "VoiceMessage":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            ...nodeData?.inputs,
            outputs: nodeData?.outputs,
            selectNextStep: { ...(nodeData?.selectNextStep || {}) },
          },
        };
        break;
      case "WaitForReply":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            ...nodeData?.inputs,
            outputs: nodeData?.outputs,
          },
        };
        break;
      case "Wait":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            ...nodeData?.inputs,
            outputs: nodeData?.outputs,
          },
        };
        break;
      case "Branch":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            inputs: { ...(nodeData?.inputs || {}) },
            outputs: nodeData?.outputs || {},
            selectNextStep: { ...(nodeData?.selectNextStep || {}) },
          },
        };
        break;

      case "ChildBranch":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            inputs: { ...(nodeData?.inputs || {}) },
            outputs: nodeData?.outputs || {},
          },
        };
        break;

      case "JumpTo":
        nodeConfig = {
          type,
          data: {
            nodeId: curr,
            ...props,
            target,
            inputs: { ...(nodeData?.inputs || {}) },
            outputs: nodeData?.outputs || {},
          },
        };
        break;
      default:
        nodeConfig = {
          type: "default",
          data: {
            type: nodeData.type,
            label: `${nodeData.type} ${curr}`,
          },
        };
        break;
    }

    const node = {
      id: curr,
      position: nodeData?.position || {},
      ...nodeConfig,
    };

    // Create edges based on current node's 'target'
    const edges = (nodeData?.target || [])
      .map((target, i) => {
        if (target === "EMPTY") return;
        return {
          id: `edge_${curr}_to_${target}`,
          type: EDGE_TYPE,
          source: curr,
          target: target,
          // Assign which souce handle the edge will be connected to
          sourceHandle: String.fromCharCode(97 + i),
        };
      })
      .filter((edge) => edge);

    acc.push(node, ...edges);

    return acc;
  }, []);

  return flowData;
};

export const MapToApiData = (elements) => {
  const { trigger: triggerNode, ...stepsObj } = elements;
  const { outputs: triggerOutputs } = triggerNode;
  const {
    id = "",
    name,
    version,
    accountId: account,
    ...info
  } = triggerNode?.data;

  // Put the target's child in the first index of the array to determine that it's the root node
  const sortedSteps = Object.keys(stepsObj) || [];
  const firstChild = triggerNode.target[0];
  const targetIndex = sortedSteps.indexOf(firstChild);
  if (targetIndex > 0) {
    const oldFirstChild = sortedSteps[0];
    sortedSteps.splice(0, 1, firstChild);
    sortedSteps.splice(targetIndex, 1, oldFirstChild);
  }

  const stepNodeIds = sortedSteps.reduce((acc, step) => {
    return {
      ...acc,
      [step]: stepsObj[step]?.inputs?.nameId || step,
    };
  }, {});

  // Set user accountId if `account` id in workflow is not defined
  let accountId = account;
  if (!account) {
    try {
      const user = JSON.parse(localStorage.getItem("CPV3_User"));
      accountId = user?.AccountId || "";
    } catch (err) {
      console.warn(err);
    }
  }

  let steps = sortedSteps.reduce((acc, step) => {
    // Don't include steps that has a value of `EMPTY`
    if (step === "EMPTY") return acc;

    const currentStep = stepsObj[step];

    // @davy:for:review - skip childBranch
    if (currentStep.type.toLowerCase() === "childbranch") return acc;

    const {
      inputs,
      outputs: outputsValue,
      type: stepType,
      target,
    } = currentStep;
    const { nameId, ...inputValues } = inputs;

    // Create the next step object if it's either `nextStepId` or `selectNextStep` based on `branch` template value
    let nextStepId = {};
    let selectNextStep = {};
    if (
      Object.keys(nodeList[stepType] || {}).length &&
      nodeList[stepType]?.template?.branch
    ) {
      let branchNextSteps = {};
      if (stepType.toLowerCase() === "waitforreply") {
        branchNextSteps = [
          `{{ step.reply != null }}`,
          `{{ step.reply == null }}`,
        ].reduce((acc, curr, i) => {
          const targetName =
            target[i] && target[i] !== "EMPTY" ? target[i] : "";
          if (!targetName) return acc;

          return {
            ...acc,
            [stepNodeIds[target[i]]]: curr,
          };
        }, {});
      } else if (
        stepType.toLowerCase() === "branch" ||
        (stepType.toLowerCase() === "voicemessage" &&
          inputs?.action === "say&capture")
      ) {
        branchNextSteps = Object.keys(currentStep.selectNextStep).reduce(
          (a, b, i) => {
            if (target[i]) {
              a[target[i]] = currentStep.selectNextStep[b];
            } else {
              a[b] = currentStep.selectNextStep[b];
            }

            return a;
          },
          {}
        );
      }

      selectNextStep = (target || []).length
        ? { selectNextStep: branchNextSteps }
        : {};
    } else if (
      Object.keys(nodeList[stepType] || {}).length &&
      !nodeList[stepType]?.template?.branch
    ) {
      const firstTarget = (target || []).length ? target[0] : "";
      nextStepId = firstTarget
        ? { nextStepId: stepNodeIds[firstTarget] || "" }
        : {};
    }

    const outputs = Object.keys(outputsValue || {}).length
      ? { outputs: { ...outputsValue } }
      : {};

    const stepItem = {
      id: nameId || stepNodeIds[step],
      inputs: inputValues,
      stepType,
      ...outputs,
      ...nextStepId,
      ...selectNextStep,
    };

    //@davy:for:review remove keys not included in branching
    if (
      stepType.toLowerCase() === "branch" ||
      (stepType.toLowerCase() === "voicemessage" &&
        inputs?.action === "say&capture")
    ) {
      if (stepType.toLowerCase() === "branch") {
        delete stepItem.inputs;
        delete stepItem.outputs;
      }

      delete stepItem.nextStepId;

      stepItem.selectNextStep = Object.keys(stepItem.selectNextStep).reduce(
        (a, b) => {
          const isTarget =
            stepsObj[b] && stepsObj[b].target && stepsObj[b].target.length;

          if (isTarget) {
            stepsObj[b].target.forEach((t) => {
              // If key already has value then insert or || between the conditions
              if (stepNodeIds[t] in a) {
                let existingCondition = a[stepNodeIds[t]];
                existingCondition = String(existingCondition || "").replace(
                  /{{|}}/g,
                  ""
                );
                let currentCondition = stepItem.selectNextStep[b];
                currentCondition = String(currentCondition || "").replace(
                  /{{|}}/g,
                  ""
                );
                a[
                  stepNodeIds[t]
                ] = `{{${existingCondition} || ${currentCondition}}}`;
              } else {
                a[stepNodeIds[t]] = stepItem.selectNextStep[b];
              }
            });
          } else {
            a[b] = stepItem.selectNextStep[b];
          }

          return a;
        },
        {}
      );
    }

    acc.push(stepItem);
    return acc;
  }, []);

  // find say and capture indexes
  const voiceSayAndCaptureIndexes = [];

  steps.forEach((v, i) => {
    if (
      v.stepType.toLowerCase() === "voicemessage" &&
      v.inputs?.action === "say&capture"
    ) {
      voiceSayAndCaptureIndexes.push(i);
    }
  });

  if (voiceSayAndCaptureIndexes.length) {
    voiceSayAndCaptureIndexes.forEach((v) => {
      const index = parseInt(v, 10);
      // copy step obj before we delete some of them
      const copyStep = { ...steps[index] };

      // delete unnecessary data for say&capture
      delete steps[index].selectNextStep;
      delete steps[index].outputs[`${steps[index].id}_step_dtmf`];
      delete steps[index].inputs.dtmfValues;

      // create ID for new dtmf step (which is always the next step for say&capture)
      const dtmfId = `wait_for_dtmf_${index}`;
      steps[index].nextStepId = dtmfId;

      // transfer data to new DTMF step
      const dtmfTimeout = moment
        .duration(copyStep?.inputs?.params?.digitTimeout)
        .format("HH:mm:ss", { trim: false });

      const dtmfObj = {
        id: dtmfId,
        stepType: "WaitForDTMF",
        inputs: {
          dtmfRequestId: `{{data.${steps[index].id}_step_clientRequestId}}`,
          timeout: dtmfTimeout,
        },
        outputs: {
          [`${steps[index].id}_step_dtmf`]:
            "{{step.dtmfData.actionDetails.dtmf}}",
          // [`${steps[index.id]}_step_clientRequestId`]:
          //   "{{step.response.clientRequestId}}",
        },
        selectNextStep: copyStep.selectNextStep,
      };

      // insert next to voice - say and capture step
      steps.splice(index + 1, 0, dtmfObj);
    });
  }

  //  find jumpto indexes
  const jumptoIndexes = [];

  steps.forEach((v, i) => {
    if (v.stepType.toLowerCase() === "jumpto") {
      jumptoIndexes.push(i);
    }
  });

  if (jumptoIndexes.length) {
    // find jumpTo steps
    jumptoIndexes.forEach((j) => {
      const jumpStep = steps[j];
      steps.forEach((s, i) => {
        // find steps that are using temporary jumpTo node id
        // and replace with real step id
        if (s.nextStepId && s.nextStepId === jumpStep.id) {
          steps[i].nextStepId = jumpStep?.inputs?.stepName;
        }

        if (s.selectNextStep && Object.keys(s.selectNextStep).length) {
          Object.keys(s.selectNextStep).forEach((sns) => {
            if (sns === jumpStep.id) {
              steps[i].selectNextStep[jumpStep?.inputs?.stepName] =
                steps[i].selectNextStep[sns];
              delete steps[i].selectNextStep[sns];
            }
          });
        }
      });
    });

    // remove jumpto steps
    steps = steps.filter((v) => v.stepType.toLowerCase() !== "jumpto");
  }

  if (info?.trigger && info?.trigger === "http_request") {
    if (steps.length && Object.keys(triggerOutputs || {}).length) {
      const triggerOutpts = Object.keys(triggerOutputs).reduce((a, b) => {
        const removeCustom = b.replace("custom_", "");

        a[removeCustom] = triggerOutputs[b];
        return a;
      }, {});

      steps[0].outputs = { ...steps[0].outputs, ...triggerOutpts };
    }
  }

  return {
    ...info,
    status:
      info.status === "enabled" || info.status === "disabled"
        ? info.status
        : "disabled", // Disable workflow by default
    accountId,
    definition: {
      id,
      name,
      steps,
    },
  };
};

export const GetLayoutedElements = (dagreGraph, elements, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      // If element has already default position then set it to default
      const nodeWithPosition = Object.keys(el.position || {}).length
        ? el.position
        : dagreGraph.node(el.id);

      el.targetPosition = isHorizontal ? "left" : "top";
      el.sourcePosition = isHorizontal ? "right" : "bottom";

      el.position = {
        x: nodeWithPosition.x - NODE_WIDTH / 2,
        y: nodeWithPosition.y - NODE_HEIGHT / 2,
      };
    }

    return el;
  });
};
