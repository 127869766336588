/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from "react";
import { Handle } from "react-flow-renderer";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment-duration-format";
import { useTranslation, Trans } from "react-i18next";

import nodes from "@/json/nodes";

import Tag from "@/components/tag";

const handlerTargetStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "-7px",
  bottom: "auto",
  transform: "translateX(-50%)",
};

const handlerSourceStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "auto",
  bottom: "-7px",
  transform: "translateX(-50%)",
};

const replyStatus = {
  success: {
    label: "REPLIED",
    labelts: "automation.reply_status.replied",
    icon: "done",
    color: "text-green-500",
  },
  failure: {
    label: "NO REPLY",
    labelts: "automation.reply_status.no_reply",
    icon: "close",
    color: "text-red-500",
  },
};

const displayTimeoutFormat = "H [hours] m [minutes] s [seconds]";

const WaitReplyNode = (node) => {
  const { elements } = useSelector((state) => state.flow);
  const { t } = useTranslation();

  const isValidConnection = (destinationNode) => {
    const currentNode = elements[node.id];
    const targetNode = elements[destinationNode];
    if (!targetNode || destinationNode === node.id) return;
    return nodes[currentNode.type].compatibleNode.includes(targetNode.type);
  };

  const handlers = (
    <ul className="list-none border-t border-gray-200 divide-x divide-gray-200 flex flex-nowrap">
      {["success", "failure"].map((status, i) => (
        <li
          className={`flex justify-center items-center w-full text-center px-4 py-2 text-gray-500 text-sm font-medium relative flex items-center ${replyStatus[status].color}`}
          key={`handler-${node?.data?.nodeId}-${i}`}
        >
          <i className="material-icons text-xl mr-1">
            {replyStatus[status].icon}
          </i>
          {t(replyStatus[status].labelts)}
          <Handle
            id={String.fromCharCode(97 + i)}
            isValidConnection={({ target }) => isValidConnection(target)}
            type="source"
            position="bottom"
            style={handlerSourceStyle}
          />
        </li>
      ))}
    </ul>
  );

  const isExistInSource = (source, sourceHandle) => {
    if (sourceHandle !== "default-handle") return false;
    return elements[source].target.includes(node.id);
  };

  // Set node as 'selected' if input is empty upon initialize so that this node will appear on the sidebar
  useEffect(() => {
    if (!Object.keys(elements[node.id]?.inputs || {}).length) {
      node.data.handleShowNodeDetail(node);
    }
  }, []);

  // // Format the node display name
  // const formatNodeName = useMemo(() => {
  //   const name = node.data.nodeId;
  //   const defaultName = "Wait for reply";

  //   // If generated name (e.g. sms-1637730438 {timestamp}) then return defaultName (e.g. "Send SMS")
  //   if (name.match(/^[a-z]*-\d{10}$/g)) return defaultName;
  //   return name.toUpperCase().replaceAll("_", " ");
  // }, [node.data.nodeId]);

  const getDuration = (timeout) => {
    const days = (timeout || "00:01:00").replace(
      /([0-9]*)\.[0-9]{2}:[0-9]{2}:[0-9]{2}/g,
      "$1"
    );
    const duration = (timeout || "00:01:00").replace(
      /[0-9]*\.([0-9]{2}:[0-9]{2}:[0-9]{2})/g,
      "$1"
    );
    const daysLabel =
      days && days !== "0" && days.match(/^\d+$/g) ? `${days} days ` : "";
    return `${daysLabel}${moment
      .duration(duration, "HH:mm:ss")
      .format(displayTimeoutFormat, { trim: "all" })}`;
  };

  return (
    <div className="bg-white shadow rounded w-72 relative">
      <header className="text-base px-4 py-2 flex items-center font-bold text-gray-700 border-b border-gray-200 relative">
        {!Object.keys(elements[node.id]?.inputs || {}).length ? (
          <Tag className="absolute -top-2 right-2 text-white bg-yellow-500">
            {t("app_labels.new").toUpperCase()}
          </Tag>
        ) : (
          ""
        )}
        <Handle
          type="target"
          position="top"
          style={handlerTargetStyle}
          isValidConnection={({ source, sourceHandle }) =>
            isValidConnection(source) && !isExistInSource(source, sourceHandle)
          }
        />
        <span
          role="presentation"
          onClick={() => node.data.handleShowNodeDetail(node)}
          class="w-full flex"
        >
          <span className="-mt-6 inline-flex justify-center items-center w-12 h-12 p-1 rounded bg-gradient-to-tr from-blue-500 to-indigo-500 mr-2">
            <i className="material-icons text-white text-2xl">reply</i>
          </span>
          {elements[node.id]?.inputs?.nameId || node?.data?.nameId ? (
            <span className="block ml-1 truncate text-gray-600 bg-gray-100 py-1 px-2 text-sm rounded-full font-bold text-center">
              {elements[node.id]?.inputs?.nameId || node?.data?.nameId}
            </span>
          ) : (
            <span class="text-sm">{t("automation.nodes.wait_for_reply")}</span>
          )}
        </span>
      </header>
      <figure
        className="px-4 py-2 flex flex-col"
        onClick={() => node.data.handleShowNodeDetail(node)}
      >
        <figcaption className="text-gray-500 text-xs mb-1 capitalize">
          {t("automation.timeout")}
        </figcaption>
        <p className="text-xl text-gray-800 font-bold">
          {node?.data?.timeout && node?.data?.timeout !== "0.00:01:00"
            ? getDuration(node?.data?.timeout)
            : "00:01:00"}
        </p>
      </figure>
      {handlers}
    </div>
  );
};

export default WaitReplyNode;
