import { useRef, useEffect, useState, Fragment } from "react";
import { sanitize } from "dompurify";
import { parsePhoneNumber } from "awesome-phonenumber";
import { Form, Input, Radio, Space, Button } from "antd";
const { TextArea } = Input;

import { useTranslation, Trans } from "react-i18next";

import TemplateModal from "./templateModal";
import TemplateHeader from "./templateHeader";
import TemplateFooter from "./templateFooter";
import TemplateButtons from "./templateButtons";
import { Footer } from "antd/lib/layout/layout";

const contentTypes = [
  {
    label: "Select a template",
    value: "template",
    labelts: "mcs.messages.index.1",
  },
  {
    label: "Compose a message",
    value: "text",
    labelts: "mcs.messages.index.2",
  },
];

const WhatsAppForm = ({
  node,
  chatUserObject,
  form,
  formData,
  onChangeFormValue,
  onDragOverInput,
  onDropInput,
  subaccount,
  loadingTemplates,
  loadingEnums,
  templates,
  getTemplates,
  enums,
  getEnums,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const destinationRef = useRef(null);
  const textInputRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    onChangeFormValue(
      "type",
      node?.data?.type ? node?.data?.type : contentTypes[0].value
    );

    // Fetch templates on load
    const channelId = subaccount?.whatsAppChannelId;
    getTemplates({ channelId });
  }, [node]);

  useEffect(() => {
    const targetTemplateName =
      selectedTemplate?.templateName === node?.data?.content?.template?.name
        ? selectedTemplate?.templateName
        : node?.data?.content?.template?.name;

    if (!targetTemplateName || !(templates || []).length) return 0;

    const template = templates.find(
      (template) => template.templateName === targetTemplateName
    );

    setSelectedTemplate(template);

    // Update initial values of parameters table on template or node change
    const text = template?.text || "";
    const variables = text.match(/{{*.}}/g) || [];

    const parameters = variables.map((variable, i) => {
      const body = (node?.data?.content?.template?.components || []).find(
        (c) => c.type === "body"
      );

      if (!body) return "";

      const parameters = body?.parameters;
      if (!parameters || !parameters.length) return "";

      return parameters[i]?.text;
    });

    // Update variables
    form.setFieldsValue({ text });
    const varList = text.match(/{{*.}}/g) || [];
    setTemplateVariables(varList);

    form.setFieldsValue({ parameters });
    onChangeFormValue("parameters", [...parameters]);
  }, [node, templates]);

  // Extracted array of variables from templates e.g `['{{1}}', '{{2}}']`
  const [templateVariables, setTemplateVariables] = useState([]);

  // Set template buttons
  const [templateButtons, setTemplateButtons] = useState([]);
  useEffect(() => {
    if (!selectedTemplate) return;

    const buttonsComponent = (selectedTemplate?.components || []).find(
      (component) => component?.type === "BUTTONS"
    );

    const buttons = buttonsComponent?.buttons || [];

    // Get the default button values from template
    const defaultButtonValues = buttons.map(
      (button) => button.phoneNumber || button.url || button.text || ""
    );

    // Get the default button values from node data
    const nodeButtonList = (
      node?.data?.content?.template?.components || []
    ).filter((component) => component?.type === "button");

    const nodeButtonValues = nodeButtonList.map(
      (button) => button?.parameters[0]?.text
    );

    setTemplateButtons(buttons);
    onChangeFormValue("buttons", [...buttons]);
    form.setFieldsValue({
      buttons:
        (nodeButtonValues || []).length &&
        node?.data?.content?.template?.name === selectedTemplate?.templateName
          ? nodeButtonValues
          : defaultButtonValues,
    });
  }, [selectedTemplate]);

  const [visibleTemplateModal, setVisibleTemplateModal] = useState(false);

  const handleSelectTemplate = (t) => {
    setSelectedTemplate(t);

    const text = t?.text || "";
    onChangeFormValue("text", text);
    form.setFieldsValue({ text });

    // Set new template variables
    const variables = text.match(/{{*.}}/g) || [];
    setTemplateVariables(variables);

    // Update template name
    const templateName = t?.templateName || "";
    onChangeFormValue("templateName", templateName);
    form.setFieldsValue({ templateName });

    // Update template language
    const language = t?.language || "";
    onChangeFormValue("language", language);
    form.setFieldsValue({ language });

    const parameters = variables.map((variable, i) => {
      const body = (node?.data?.content?.template?.components || []).find(
        (c) => c.type === "body"
      );

      if (!body) return "";

      const parameters = body?.parameters;
      if (!parameters || !parameters.length || i > variables.length - 1)
        return "";

      return parameters[i]?.text;
    });
    onChangeFormValue("parameters", parameters);
    form.setFieldsValue({ parameters });
  };

  // Drag and drop for dynamic parameter fields
  const parametersValuesInputRef = useRef([]);

  // Drag and drop for dynamic button fields
  const buttonsValuesInputRef = useRef([]);

  // Update preview text when form parameter field changes
  const [previewText, setPreviewText] = useState();
  useEffect(() => {
    if (!formData?.parameters) return;

    const text = form.getFieldsValue(false)?.text || "";
    const parameters = formData?.parameters || [];
    const prevText = parameters.reduce((acc, curr, i) => {
      acc = acc.replace(
        `{{${i + 1}}}`,
        `<span class="text-xs text-gray-700 bg-gray-100 py-1 px-2 rounded-full font-bold tracking-wide">${
          curr || `{{${i + 1}}}`
        }</span>`
      );
      return acc;
    }, text);
    setPreviewText(prevText);
  }, [formData?.parameters]);

  return (
    <>
      <Form.Item
        name={chatUserObject?.destination || "msisdn"}
        className="px-5"
        initialValue={
          node?.data?.user?.[chatUserObject?.destination || "msisdn"] ||
          form?.from ||
          ""
        }
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("column_labels.destinations").split("|")[0]}
          </h3>
        }
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.destinations").split("|")[0],
            }),
          },
          {
            validator(rule, value, callback) {
              const isVariable = String(value || "").match(/^{{.*}}$/g);
              if (!isVariable && !parsePhoneNumber(value).valid) {
                return Promise.reject(
                  t("validations.invalid", {
                    value: t("column_labels.phone_no").toLowerCase(),
                  })
                );
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          ref={destinationRef}
          placeholder={t("automation.enter_or_drag")}
          onChange={(e) =>
            onChangeFormValue(
              chatUserObject?.destination || "msisdn",
              e.target.value
            )
          }
          onDragEnter={() => destinationRef.current.focus()}
          onDragOver={onDragOverInput}
          onDrop={(e) =>
            onDropInput(e, chatUserObject?.destination || "msisdn")
          }
        />
      </Form.Item>
      <Form.Item
        name="type"
        className="p-5"
        initialValue={node?.data?.type || form?.type || "template"}
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("mcs.messages.index.0")}
          </h3>
        }
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.message").toLowerCase(),
            }),
          },
        ]}
      >
        <Radio.Group
          onChange={(e) => {
            onChangeFormValue("type", e.target.value);
            form.setFieldsValue({ text: "" });
            setTemplateVariables([]);
            setTemplateButtons([]);

            // Empty parameters when change template
            onChangeFormValue("parameters", []);
            form.setFieldsValue({ parameters: [] });
          }}
        >
          <Space direction="vertical">
            {contentTypes.map((type) => (
              <Radio key={`wa-content-${type.value}`} value={type.value}>
                {t(type.labelts)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      {(formData?.type || "template") === "template" ? (
        <>
          <Form.Item className="p-5 pt-0">
            <TemplateModal
              visible={visibleTemplateModal}
              setVisible={setVisibleTemplateModal}
              subaccount={subaccount}
              loading={loadingTemplates || loadingEnums}
              templates={templates || []}
              enums={enums}
              getTemplates={getTemplates}
              getEnums={getEnums}
              handleSelectTemplate={handleSelectTemplate}
            />
            <Button
              type="dashed"
              danger={form.getFieldsValue(false)?.text}
              onClick={() => setVisibleTemplateModal(true)}
            >
              {form.getFieldsValue(false)?.text
                ? t("mcs.messages.sms.select.1")
                : t("mcs.messages.sms.select.0")}
            </Button>
            <small className="block mt-2 text-gray-500">
              {form.getFieldsValue(false)?.templateName ||
                formData?.templateName}
            </small>
            <Form.Item
              name="templateName"
              initialValue={
                node?.data?.content?.template?.name ||
                formData?.templateName ||
                ""
              }
              noStyle
            >
              <Input
                value={form.getFieldsValue(false)?.templateName}
                className="hidden"
              />
            </Form.Item>
            <Form.Item
              name="language"
              initialValue={
                node?.data?.content?.template?.language ||
                formData?.language ||
                ""
              }
              noStyle
            >
              <Input
                value={form.getFieldsValue(false)?.language}
                className="hidden"
              />
            </Form.Item>
            <Form.Item
              name="text"
              label={
                <h3 class="text-gray-500 text-xs mb-1 font-bold">
                  {t("column_labels.message")}
                </h3>
              }
              initialValue={form?.templateName || ""}
              rules={[
                {
                  required: true,
                  message: t("validations.required", {
                    value: t("sidebar_menu_children.WhatsApp templates"),
                  }),
                },
              ]}
              className="mt-5 mb-0"
            >
              <TextArea
                value={form.getFieldsValue(false)?.text}
                className="hidden"
              />
              <Form.Item noStyle>
                {form.getFieldsValue(false)?.text ? (
                  <>
                    <dialog className="p-0 bg-white rounded shadow whitespace-pre-wrap block break-words relative w-full border border-gray-100 divide-y divide-gray-100">
                      <TemplateHeader template={selectedTemplate} />
                      <div
                        className="p-3 leading-loose"
                        dangerouslySetInnerHTML={{
                          __html: sanitize(previewText),
                        }}
                      />
                      <TemplateFooter template={selectedTemplate} />
                    </dialog>
                    <TemplateButtons template={selectedTemplate} />
                  </>
                ) : (
                  <p className="bg-gray-100 text-gray-500 p-5 rounded text-xs text-center">
                    {t("validations.select", {
                      value: t("automation.whats_app_template"),
                    })}
                  </p>
                )}
              </Form.Item>
            </Form.Item>
          </Form.Item>
          {(formData?.parameters || [])?.length ? (
            <Form.Item
              className="p-5"
              label={
                <h3 class="text-gray-500 text-xs mb-1 font-bold">
                  {t("column_labels.parameters")}
                </h3>
              }
            >
              <Form.List name="parameters">
                {(variables) => (
                  <dl>
                    <dt className="w-1/3 rounded-l float-left p-2 bg-gray-50 text-gray-500 font-bold text-xs">
                      {t("column_labels.variables").replace("s", "")}
                    </dt>
                    <dd className="w-2/3 rounded-r float-left p-2 bg-gray-50 text-gray-500 font-bold text-xs">
                      {t("column_labels.value")}
                    </dd>
                    {variables.map((variable, i) => (
                      <Fragment key={`parameter-item-${i}`}>
                        <dt className="w-1/3 float-left mb-2 p-2 border-b border-gray-100 font-normal text-blue-500">
                          {templateVariables[variable.key]}
                        </dt>
                        <dd className="w-2/3 float-left mb-2 p-1">
                          <Form.Item
                            {...variable}
                            rules={[
                              {
                                required: true,
                                message: t("automation.enter_or_drag"),
                              },
                            ]}
                          >
                            <Input
                              ref={(el) =>
                                (parametersValuesInputRef.current[
                                  variable.key
                                ] = el)
                              }
                              bordered={false}
                              style={{
                                borderBottom: "1px solid rgb(241 245 249)",
                                paddingBottom: "8px",
                              }}
                              placeholder={t(
                                "mcs.messages.chat_apps.custom_fields.6"
                              )}
                              onDragEnter={() =>
                                parametersValuesInputRef.current[
                                  variable.key
                                ].focus()
                              }
                              onDragOver={onDragOverInput}
                              onChange={(e, formItem = "parameters") => {
                                const value = e.target.value;
                                let newArrayValue = [
                                  ...form.getFieldsValue(true)[formItem],
                                ];
                                newArrayValue.splice(i, 1, value);
                                onChangeFormValue(formItem, newArrayValue);
                              }}
                              onDrop={(e, formItem = "parameters") => {
                                e.preventDefault();
                                const variable =
                                  e.dataTransfer.getData("variable");
                                const { value, selectionEnd, selectionStart } =
                                  e.target;
                                const newValue =
                                  value.slice(0, selectionStart) +
                                  variable +
                                  value.slice(selectionEnd);
                                let newArrayValue = [
                                  ...form.getFieldsValue(true)[formItem],
                                ];
                                newArrayValue.splice(i, 1, newValue);
                                onChangeFormValue(formItem, newArrayValue);
                                form.setFieldsValue({
                                  [formItem]: [...newArrayValue],
                                });
                              }}
                            />
                          </Form.Item>
                        </dd>
                      </Fragment>
                    ))}
                  </dl>
                )}
              </Form.List>
            </Form.Item>
          ) : (
            ""
          )}
          {/* {(formData?.buttons || [])?.length ? (
            <Form.Item
              className="p-5"
              label={
                <h3 class="text-gray-500 text-xs mb-1 font-bold">Buttons</h3>
              }
            >
              <Form.List name="buttons">
                {(buttons) => (
                  <dl>
                    <dt className="w-1/3 rounded-l float-left p-2 bg-gray-50 text-gray-500 font-bold text-xs">
                      BUTTON
                    </dt>
                    <dd className="w-2/3 rounded-r float-left p-2 bg-gray-50 text-gray-500 font-bold text-xs">
                      PAYLOAD
                    </dd>
                    {buttons.map((button, i) => (
                      <Fragment key={`buttons-item-${i}`}>
                        <dt className="w-1/3 float-left mb-2 p-2 border-b border-gray-100 font-normal text-blue-500 truncate">
                          {templateButtons[button.key]?.text}
                        </dt>
                        <dd className="w-2/3 float-left mb-2 border-b border-gray-100 p-1">
                          <Form.Item
                            {...button}
                            noStyle
                            rules={[
                              { required: true, message: "Value is required!" },
                            ]}
                          >
                            <Input
                              ref={(el) =>
                                (buttonsValuesInputRef.current[button.key] = el)
                              }
                              bordered={false}
                              placeholder="Choose a variable or enter a value"
                              onDragEnter={() =>
                                buttonsValuesInputRef.current[
                                  button.key
                                ].focus()
                              }
                              onDragOver={onDragOverInput}
                              onChange={(e, formItem = "buttons") => {
                                const value = e.target.value;
                                let newArrayValue = [
                                  ...form.getFieldsValue(true)[formItem],
                                ];
                                newArrayValue.splice(i, 1, value);
                                onChangeFormValue(formItem, newArrayValue);
                              }}
                              onDrop={(e, formItem = "buttons") => {
                                e.preventDefault();
                                const button =
                                  e.dataTransfer.getData("variable");
                                const { value, selectionEnd, selectionStart } =
                                  e.target;
                                const newValue =
                                  value.slice(0, selectionStart) +
                                  button +
                                  value.slice(selectionEnd);
                                let newArrayValue = [
                                  ...form.getFieldsValue(true)[formItem],
                                ];
                                newArrayValue.splice(i, 1, newValue);
                                onChangeFormValue(formItem, newArrayValue);
                                form.setFieldsValue({
                                  [formItem]: [...newArrayValue],
                                });
                              }}
                            />
                          </Form.Item>
                        </dd>
                      </Fragment>
                    ))}
                  </dl>
                )}
              </Form.List>
            </Form.Item>
          ) : (
            ""
          )} */}
        </>
      ) : (
        ""
      )}
      {(formData?.type || "") === "text" ? (
        <Form.Item
          name="text"
          initialValue={form?.text || node?.data?.content?.text || ""}
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              {t("column_labels.message")}
            </h3>
          }
          rules={[
            {
              required: true,
              message: t("validations.required", {
                value: t("column_labels.message"),
              }),
            },
          ]}
          className="p-5"
        >
          <TextArea
            ref={textInputRef}
            rows={8}
            placeholder={t("automation.enter_or_drag")}
            onChange={(e) => onChangeFormValue("text", e.target.value)}
            onDragEnter={() => textInputRef.current.focus()}
            onDragOver={onDragOverInput}
            onDrop={(e) => onDropInput(e, "text")}
          />
        </Form.Item>
      ) : (
        ""
      )}
      {/* <code className="whitespace-pre-wrap block border-b border-gray-500 mb-5 pb-5">
        {JSON.stringify(node, null, "\t")}
      </code> */}
      {/* <code className="whitespace-pre-wrap block border-b border-gray-500 mb-5 pb-5">
        {JSON.stringify(selectedTemplate?.components, null, "\t")}
        {JSON.stringify(form.getFieldsValue(false), null, "\t")}
        {JSON.stringify(templateButtons, null, "\t")}
      </code> */}
    </>
  );
};

export default WhatsAppForm;
