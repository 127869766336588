import React, { useState } from "react";
import { Form, Select, Input, Button } from "antd";
const { Option } = Select;

import { useTranslation, Trans } from "react-i18next";

const ChatTriggerForm = ({
  subAccountId,
  subaccountOptions,
  node,
  form,
  onUpdateForm,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        name="subAccountId"
        initialValue={subAccountId || ""}
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("validations.select", {
              value: `${t("app_labels.new").toLowerCase()} ${t(
                "fields.subaccount"
              ).toLowerCase()}`,
            })}
          </h3>
        }
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("fields.subaccount").toLowerCase(),
            }),
          },
        ]}
        className="px-5"
      >
        <Select
          name="triggersubaccount"
          showSearch
          placeholder={t("validations.select", {
            value: t("fields.subaccount").toLowerCase(),
          })}
          filterOptions={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(value) => onUpdateForm("subAccountId", value)}
          className="w-60 block"
        >
          {subaccountOptions.map((account) => (
            <Option value={account.SubAccountId} key={account.SubAccountUid}>
              {account.SubAccountId}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item className="hidden">
        <Form.Item className="bg-gray-50 p-5">
          <Form.List
            name="outputs"
            initialValue={Object.keys(node?.data?.outputs || []).map((key) => {
              let value = node?.data?.outputs[key];
              if (typeof value === "object") {
                value = JSON.stringify(node?.data?.outputs[key]);
              }
              return {
                key,
                value: value,
              };
            })}
          >
            {(outputsData, { add, remove }) => (
              <div className="w-full">
                {outputsData.map((output, i) => (
                  <div
                    key={output.key}
                    className="w-full flex flex-nowrap space-x-2"
                  >
                    <Form.Item
                      name={[i, "key"]}
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: t("validations.required", {
                            value: t("column_labels.key"),
                          }),
                        },
                        {
                          validator(rule, value, callback) {
                            const outputs = form
                              .getFieldsValue(true)
                              .outputs.map((i) => i.key);
                            const dupOutputs = outputs.filter(
                              (e, i, a) => a.indexOf(e) !== i
                            );
                            if (dupOutputs.includes(value)) {
                              return Promise.reject(
                                t("automation.duplicate_header", {
                                  item: t("column_labels.queries")
                                    .split("|")[0]
                                    .toLowerCase(),
                                })
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          onUpdateForm("outputs", [
                            ...form.getFieldsValue(true).outputs,
                            { key: e.target.value },
                          ])
                        }
                        placeholder="key"
                        className="w-full text-xs px-0 py-2"
                        bordered={false}
                        style={{
                          borderBottom: "1px solid #D1D5DB",
                          borderRadius: 0,
                        }}
                      />
                    </Form.Item>
                    <Form.Item name={[i, "value"]} className="w-full">
                      <Input
                        onChange={(e) =>
                          onUpdateForm("outputs", [
                            ...form.getFieldsValue(true).outputs,
                            { value: e.target.value },
                          ])
                        }
                        placeholder="value"
                        className="w-full text-xs px-0 py-2"
                        bordered={false}
                        style={{
                          borderBottom: "1px solid #D1D5DB",
                          borderRadius: 0,
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <i
                        role="button"
                        tabIndex={0}
                        className="cursor-pointer material-icons mr-1 text-base text-gray-300 hover:text-red-500"
                        onClick={() => {
                          // Remove item in form
                          remove(output.name);
                          const newOutputs = [
                            ...form.getFieldsValue(true).outputs,
                          ];
                          newOutputs.splice(output.key, 1);
                          onUpdateForm("outputs", newOutputs);
                        }}
                      >
                        delete_outline
                      </i>
                    </Form.Item>
                  </div>
                ))}
                <Form.Item noStyle>
                  <Button
                    type="dashed"
                    size="small"
                    className="flex items-center"
                    onClick={() => {
                      const newArrayValue = [
                        ...Object.keys(form.getFieldsValue(true).outputs || []),
                      ];
                      newArrayValue.push({ key: "", value: "" });
                      onUpdateForm("outputs", newArrayValue);
                      form.setFieldsValue({
                        outputs: [...newArrayValue],
                      });
                    }}
                  >
                    <i className="material-icons text-base mr-1">add</i>
                    {t("app_labels.new")} {t("column_labels.output")}
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form.Item>
      </Form.Item>
    </>
  );
};

export default ChatTriggerForm;
