import conditions from "@/json/branch-conditions";
import dtmfConditions from "@/json/dtmf-conditions";

const extractStepData = (str = "") => {
  if (!str) return {};

  if (str.match(/data./) && str.match(/dtmf/)) {
    const stepVal = str;

    let dtmf = dtmfConditions.find((v) => v.condition === "==");

    if (stepVal.match(/!=|!==/)) {
      dtmf = dtmfConditions.find((v) => v.condition === "!=");
    }
    const dtmfValue = stepVal.match(/'(.*?)'}}/);

    const value =
      dtmfValue && dtmfValue.length && dtmfValue.length > 1
        ? Number(dtmfValue[1])
        : "null";

    return { ...dtmf, dtmfValue: value, conditionName: dtmf.label };
  }

  const hasConditions = str.trim().match(/\|\||\&\&/);

  const hasParenthesis = str.trim().match(/\((.*)\)/);

  let s = [];

  if (hasParenthesis && hasParenthesis.length && hasParenthesis.length > 1) {
    s = hasParenthesis[1].split(",");

    s = s.map((v) => v.replace(/'/g, "").trim());
  }

  if (
    str.match(/{{isCountryCode\((.*),(.*)\)}}/i) &&
    !hasConditions &&
    s.length
  ) {
    return {
      country: s[1],
      mobile: s[0].match(/data./i) ? `{{${s[0]}}}` : s[0],
    };
  } else if (
    (str.match(/{{stringContains\((.*),(.*),(.*)\)}}/i) ||
      str.match(/{{stringContains\((.*),(.*)\)}}/i)) &&
    !hasConditions &&
    s.length
  ) {
    return {
      keyword: s[1],
      stringValue: s[0].match(/data./i) ? `{{${s[0]}}}` : s[0],
      isCaseSensitive:
        // Reversing the value since the value used in the API is the opposite
        s.length > 2 ? (s[2] && s[2] === "true" ? false : true) : true,
    };
  } else if (
    str.match(/{{(!*)isTimeOfDayBetween\((.*),(.*),(.*),(.*)\)}}/i) &&
    !hasConditions &&
    s.length
  ) {
    return {
      date: s[0].match(/data./i) ? `{{${s[0]}}}` : s[0],
      time: [s[1], s[2]],
      timezone: s[3],
      timefallsOutside: str.match(/!isTimeOfDayBetween/i) ? true : false,
    };
  } else if (
    str.match(/{{isDayOfWeek\((.*),(.*)\)}}/i) &&
    !hasConditions &&
    s.length
  ) {
    return {
      date: s[0].match(/data./i) ? `{{${s[0]}}}` : s[0],
      day: s[1],
    };
  } else {
    if (!str) return { script: null };

    return str.trim().match(/{{(.*)}}/g)
      ? { script: str.trim().replace(/[{}]/g, "") }
      : {};
  }
};

const getConditon = (str) => {
  let condition = 5;

  const hasConditions = str.trim().match(/\|\||\&\&/);

  if (str.match(/{{isCountryCode\((.*),(.*)\)}}/i) && !hasConditions)
    condition = 1;

  if (
    (str.match(/{{stringContains\((.*),(.*)\)}}/i) ||
      str.match(/{{stringContains\((.*),(.*),(.*)\)}}/i)) &&
    !hasConditions
  )
    condition = 2;

  if (
    str.match(/{{(!*)isTimeOfDayBetween\((.*),(.*),(.*),(.*)\)}}/i) &&
    !hasConditions
  )
    condition = 3;

  if (str.match(/{{isDayOfWeek\((.*),(.*)\)}}/i) && !hasConditions)
    condition = 4;

  return {
    condition,
    conditionName: (conditions.find((v) => v.id === condition) || {}).name,
  };
};

const extractSelectNextStep = (selectNextStep = {}) => {
  // detect condition
  if (Object.keys(selectNextStep).length) {
    const steps = Object.keys(selectNextStep);
    const str = JSON.stringify(selectNextStep);
    const condition = getConditon(str);

    let sns = {};
    const formVals = [];

    steps.forEach((v) => {
      formVals.push(extractStepData(selectNextStep[v]));
    });

    const { condition: cid } = condition;

    switch (cid) {
      case 1: {
        const mobile = formVals[0].mobile;

        sns = {
          countries: formVals.map((v) => {
            delete v.mobile;

            return v;
          }),
          mobile,
        };
        break;
      }

      case 2: {
        const stringValue = formVals[0].stringValue;
        const isCaseSensitive = formVals[0].isCaseSensitive;

        sns = {
          keywords: formVals.map((v) => {
            delete v.stringValue;

            return v;
          }),
          stringValue,
          isCaseSensitive,
        };

        break;
      }

      case 3: {
        sns = { timeOfDay: formVals };
        break;
      }

      case 4: {
        sns = { dayOfWeek: formVals };
        break;
      }

      case 5: {
        sns = { scripts: formVals };
        break;
      }

      default:
    }

    return {
      ...condition,
      ...sns,
    };
  }

  return {};
};

export default {
  extractStepData,
  extractSelectNextStep,
};
