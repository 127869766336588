/* eslint-disable jsx-a11y/label-has-associated-control */
import { useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Input, Switch } from "antd";
import { updateElements } from "@/store/reducers/flow";
import Moment from "moment";
import _ from "lodash";
import { useTranslation, Trans } from "react-i18next";

const DefinitionInformation = ({
  elements,
  className,
  loading,
  selectedWorkflow,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [definitionName, setDefinitionName] = useState("");

  const titleInputRef = useRef(null);

  const handleChangeTitle = _.debounce((e) => {
    if (!Object.keys(elements || {}).length) return;
    const name = e.target.value;

    // If empty set it to it's previous value
    if (!name.trim()) {
      titleInputRef.current.value = definitionName;
      // console.log("HELLO: ", titleInputRef.current);
      return;
    }

    const newTrigger = { ...(elements["trigger"] || {}) };
    newTrigger.data = {
      ...newTrigger.data,
      name,
    };

    setDefinitionName(definitionName);
    dispatch(updateElements({ ["trigger"]: newTrigger }));
  }, 1000);

  const handleChangeStatus = (value) => {
    const newTrigger = { ...(elements["trigger"] || {}) };
    newTrigger.data = {
      ...newTrigger.data,
      status: value ? "enabled" : "disabled",
    };
    dispatch(updateElements({ ["trigger"]: newTrigger }));
  };

  useEffect(() => {
    if (Object.keys(elements || {}).length) {
      setDefinitionName(elements["trigger"].data?.name);
    }
  }, [elements]);

  return (
    <div className="bg-white shadow p-2 rounded-sm">
      {Object.keys(elements).length && definitionName ? (
        <>
          <div className="flex items-center justify-between">
            <label className="text-xs text-gray-600 block mr-4">
              {t("automation.enable_workflow")}
            </label>
            <Switch
              size="small"
              loading={loading}
              onChange={(value) => {
                handleChangeStatus(value);
              }}
              defaultChecked={elements?.trigger?.data?.status === "enabled"}
            />
          </div>
          <label className="text-xs text-gray-600 mt-5 mb-1 block">
            {t("column_labels.name")}
          </label>
          <Input
            maxlength="25"
            ref={titleInputRef}
            type="text"
            defaultValue={definitionName}
            onChange={(e) => handleChangeTitle(e)}
          />
          <div class="flex">
            <div class="flex-1">
              {elements?.trigger?.data?.createdAt ? (
                <>
                  <label className="text-xs text-gray-600 mt-5 mb-1 block">
                    {t("automation.created_at")}
                  </label>
                  <p className="text-xs text-gray-900 font-bold">
                    {Moment(elements?.trigger?.data?.createdAt).format(
                      "D MMM, hh:mm A"
                    )}
                  </p>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default DefinitionInformation;
