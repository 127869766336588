import FB from "@/assets/icons/facebook.svg";
import GG from "@/assets/icons/googlercs.svg";
import KK from "@/assets/icons/kakao.svg";
import LN from "@/assets/icons/line.svg";
import SM from "@/assets/icons/sms.svg";
import VB from "@/assets/icons/viber.svg";
import WC from "@/assets/icons/wechat.svg";
import WA from "@/assets/icons/whatsapp.svg";
import ZL from "@/assets/icons/zalo.svg";

const icons = {
  FB,
  GG,
  KK,
  LN,
  SM,
  VB,
  WC,
  WA,
  ZL,
};

export const getChannelIconByCode = (channelCode) => icons[channelCode];
