import React, { useState, useEffect, useReducer, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import useDynamicRefs from "use-dynamic-refs";

import {
  Form,
  Button,
  Popconfirm,
  Select,
  message,
  Input,
  notification,
} from "antd";
const { Option } = Select;

import { useTranslation, Trans } from "react-i18next";

// import { FlattenObject as flattenObject } from "@/utils/FlattenObject";
import { removeCurlyBraces, hasEnclosedCurlyBraces } from "@/utils/common";
import branchHelper from "@/utils/branch-helper";

import Variables from "@/components/variables";
import branchConditions from "@/json/branch-conditions";

import CountryCodeForm from "./countryCode";
import StringContainsForm from "./stringContains";
import TimeOfDayBetweenForm from "./timeOfDayBetween";
import DayOfWeekForm from "./dayOfWeek";
import CustomScriptForm from "./customScript";

const ACTIONS = {
  INITIALIZE_FORM: "initialize-form",
  UPDATE_VALUE: "update-value",
};

function reducer(formData, action) {
  switch (action.type) {
    case ACTIONS.INITIALIZE_FORM:
      return { ...action.payload };
    case ACTIONS.UPDATE_VALUE:
      return { ...formData, ...action.payload };
    default:
      return formData;
  }
}

const initialValues = {
  nameId: undefined,
  condition: 1,
  mobile: undefined,
  countries: [{ country: undefined }],
  stringValue: undefined,
  isCaseSensitive: false,
  keywords: [{ keyword: undefined }],
  timeOfDay: [
    {
      date: "",
      timezone: undefined,
      time: [undefined, undefined],
      timefallsOutside: false,
    },
  ],

  dayOfWeek: [{ date: undefined, day: undefined }],
  scripts: [{ script: undefined }],
};

const BranchForm = ({
  node,
  onUpdateNodeValue,
  sidebarScrollWrapper,
  onDeleteNode,
  timezones,
  stepNameList,
  onUpdateStepNameList,
}) => {
  const [form] = Form.useForm();
  const { elements } = useSelector((state) => state.flow);

  const [getRef, setRef] = useDynamicRefs();

  const [formData, dispatch] = useReducer(reducer, {});

  const [defaultValues, setDefaultValues] = useState(initialValues);

  const [variables, setVariables] = useState({});

  const [selectedCondition, setCondition] = useState(1);

  const { t } = useTranslation();

  // Get all outputs from parent nodes and set it as `variables`
  useEffect(() => {
    setVariables({ currId: node.id, elements, currOutputs: formData.outputs });
  }, [node, elements, formData.outputs]);

  // Set default Values
  useEffect(() => {
    let sns = {};

    sns = { ...node?.data?.inputs };

    if (
      elements[node.id]?.selectNextStep &&
      !Object.keys(node?.data?.inputs).length
    ) {
      sns = branchHelper.extractSelectNextStep(
        elements[node.id].selectNextStep
      );
    }

    const obj = {
      ...initialValues,
      ...sns,
    };

    // make sure date formats are properly formatted

    const newObj = JSON.parse(JSON.stringify(obj));

    let timeOfDay = [...newObj.timeOfDay];

    timeOfDay = timeOfDay.map((v) => {
      if (v.date && !hasEnclosedCurlyBraces(v.date)) {
        v.date = moment(v.date).format("YYYY-MM-DD");
      }

      let time = [...v.time];

      time = time.map((t) => {
        if (t) {
          t = moment(t, "HH:mm");
        }

        return t;
      });

      v.time = time;

      return v;
    });

    let dayOfWeek = [...newObj.dayOfWeek];

    dayOfWeek = obj.dayOfWeek.map((v) => {
      if (v.date && !hasEnclosedCurlyBraces(v.date)) {
        v.date = moment(v.date).format("YYYY-MM-DD");
      }

      return v;
    });

    obj.timeOfDay = timeOfDay;
    obj.dayOfWeek = dayOfWeek;

    obj.nameId = obj.nameId || node.id || "";

    setCondition(obj.condition || 1);
    setDefaultValues({ ...obj });
  }, []);

  // Re-render fields if selectedNode is updated but has same type with previous selectedNode
  useEffect(() => {
    form.resetFields();

    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [defaultValues]);

  const onSubmitForm = () => {
    form
      .validateFields()
      .then((v) => {
        // Update store node data
        const { id } = node;
        const newData = { ...elements[id] };

        let formValues = { ...v };

        let selectNextStep = {};

        // const { nameId } = formValues;

        // const prefix = nameId.trim().replace(/ /g, "_");

        switch (parseInt(v.condition, 10)) {
          case 1: {
            // get only defined country
            const countries = v.countries.filter((c) => c && c.country);

            formValues = { ...formValues, countries };

            const { mobile } = formValues;

            // // If same condition dont create new selectNextStep
            // if (v?.condition === node?.data?.inputs?.condition) {
            //   selectNextStep = { ...(node?.data?.selectNextStep || {}) };
            //   break;
            // }

            selectNextStep = countries.reduce((a, b) => {
              if (b.country) {
                const v = hasEnclosedCurlyBraces(mobile)
                  ? removeCurlyBraces(mobile)
                  : `'${mobile}'`;
                a[
                  `country_code_${b.country}`
                ] = `{{isCountryCode(${v}, '${b.country}')}}`;
              }

              return a;
            }, {});

            break;
          }

          case 2: {
            // get only defined keywords
            const keywords = v.keywords.filter((k) => k && k.keyword);

            formValues = { ...formValues, keywords };

            const { stringValue, isCaseSensitive } = formValues;

            // // If same condition dont create new selectNextStep
            // if (v?.condition === node?.data?.inputs?.condition) {
            //   selectNextStep = { ...(node?.data?.selectNextStep || {}) };
            //   break;
            // }

            selectNextStep = keywords.reduce((a, b) => {
              if (b.keyword) {
                const sv = hasEnclosedCurlyBraces(stringValue)
                  ? removeCurlyBraces(stringValue)
                  : `'${stringValue}'`;
                a[`string_contains_${b.keyword}`] = `{{stringContains(${sv}, '${
                  b.keyword
                }', ${!isCaseSensitive})}}`;
              }

              return a;
            }, {});

            break;
          }

          case 3: {
            // get only defined values
            const timeOfDay = v.timeOfDay
              .filter((t) => t && t.date && Array.isArray(t.time) && t.timezone)
              .map((t) => {
                if (!hasEnclosedCurlyBraces(t.date)) {
                  t.date = moment(t.date).format("YYYY-MM-DD");
                }
                return t;
              });

            formValues = { ...formValues, timeOfDay };

            // // If same condition dont create new selectNextStep
            // if (v?.condition === node?.data?.inputs?.condition) {
            //   selectNextStep = { ...(node?.data?.selectNextStep || {}) };
            //   break;
            // }

            selectNextStep = timeOfDay.reduce((a, b, i) => {
              if (b.date && Array.isArray(b.time) && b.timezone) {
                const operator = b.timefallsOutside ? "!" : "";
                const dateVal = hasEnclosedCurlyBraces(b.date)
                  ? removeCurlyBraces(b.date)
                  : `'${b.date}'`;

                a[
                  `time_check${i}`
                ] = `{{${operator}isTimeOfDayBetween(${dateVal}, '${moment(
                  b.time[0]
                ).format("HH:mm")}', '${moment(b.time[1]).format("HH:mm")}', '${
                  b.timezone
                }')}}`;
              }

              return a;
            }, {});

            break;
          }

          case 4: {
            const dayOfWeek = v.dayOfWeek
              .filter((d) => d && d.date && d.day)
              .map((d) => {
                if (!hasEnclosedCurlyBraces(d.date)) {
                  d.date = moment(d.date).format("YYYY-MM-DD");
                }
                return d;
              });

            formValues = { ...formValues, dayOfWeek };

            // If same condition dont create new selectNextStep
            // if (v?.condition === node?.data?.inputs?.condition) {
            //   selectNextStep = { ...(node?.data?.selectNextStep || {}) };
            //   break;
            // }

            selectNextStep = dayOfWeek.reduce((a, b) => {
              if (b.date && b.day) {
                const val = hasEnclosedCurlyBraces(b.date)
                  ? removeCurlyBraces(b.date)
                  : `'${b.date}'`;
                a[
                  `day_check_${b.day.toLowerCase()}`
                ] = `{{isDayOfWeek(${val}, '${b.day}')}}`;
              }

              return a;
            }, {});

            break;
          }

          case 5: {
            // allow empty script
            const scripts = v.scripts;

            formValues = { ...formValues, scripts };

            // If same condition dont create new selectNextStep
            // if (v?.condition === node?.data?.inputs?.condition) {
            //   selectNextStep = { ...(node?.data?.selectNextStep || {}) };
            //   break;
            // }

            selectNextStep = scripts.reduce((a, b, i) => {
              a[`custom_script${i}`] =
                !b.script || b.script.toLowerCase() === "null"
                  ? null
                  : `{{${b.script}}}`;

              return a;
            }, {});

            break;
          }

          default:
        }

        formValues.conditionName = (
          branchConditions.find((b) => b.id === Number(v.condition)) || {}
        ).name;

        delete newData.nodeId;

        onUpdateNodeValue(
          {
            id,
            selectNextStep,
            inputs: { ...formValues },
          },
          node
        );

        // Update the master list of step names
        let nameList = [...stepNameList];
        const arrayIndex = nameList.indexOf(node?.data?.nameId || id);
        nameList.splice(arrayIndex, 1, formValues?.nameId);
        onUpdateStepNameList(nameList);
      })
      .catch((e) => {
        if (window.Bugsnag) {
          window.Bugsnag.notify(e);
        }
        notification.error({ message: "Unable to update form." });
      });
  };

  const dispatchFormValues = () => {
    const formValues = form.getFieldsValue(true);

    dispatch({
      type: ACTIONS.UPDATE_VALUE,
      payload: { ...formValues },
    });
  };

  const onSubmitFormFail = (values) => {
    // console.log("Failed to submit form");
  };

  const onConfirmDelete = () => {
    onDeleteNode(node.id || "", node);
  };

  const onDragStartTag = (e) => {
    e.dataTransfer.setData("variable", e.target.dataset.value);
  };

  const onDragOverInput = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDropInput = (e, fieldItem, fieldKey, fields) => {
    e.preventDefault();
    const variable = e.dataTransfer.getData("variable");
    const { value, selectionEnd, selectionStart } = e.target;
    let newValue =
      value.slice(0, selectionStart) + variable + value.slice(selectionEnd);

    // Update values
    if (fields) {
      const fv = form.getFieldsValue(false);

      if (fv[fields][fieldKey]) {
        if (fields === "scripts") {
          newValue = removeCurlyBraces(newValue);
        }
        Object.assign(fv[fields][fieldKey], {
          [fieldItem]: newValue,
        });
      } else {
        fv[fields].push({ [fieldItem]: newValue });
      }

      const val = fv[fields];

      form.setFieldsValue({ [fields]: val });
    } else {
      form.setFieldsValue({ [fieldItem]: newValue });
    }

    dispatchFormValues();
  };

  const onReset = (v) => {
    // const nameId = form.getFieldValue("nameId");

    form.setFieldsValue({ mobile: undefined });
    form.setFieldsValue({
      countries: [{ country: undefined }],
    });
    form.setFieldsValue({ stringValue: undefined });
    form.setFieldsValue({ isCaseSensitive: false });
    form.setFieldsValue({
      keywords: [{ keyword: undefined }],
    });
    form.setFieldsValue({
      timeOfDay: [
        {
          date: undefined,
          timezone: "Greenwich Standard Time",
          time: [undefined, undefined],
          timefallsOutside: false,
        },
      ],
    });

    form.setFieldsValue({
      dayOfWeek: [{ date: undefined, day: undefined }],
    });

    form.setFieldsValue({
      scripts: [{ script: undefined }],
    });

    dispatchFormValues();
  };

  const onValuesChange = (val) => {
    dispatchFormValues();
  };

  return (
    <>
      <header className="bg-gradient-to-tr from-blue-500 to-indigo-500 px-5 py-7 w-96">
        <small className="text-xs text-red-200 tracking-widest">
          {t("automation.nodes.branch")}
        </small>
        <h1 className="font-bold text-lg text-white tracking-wider pr-20 leading-snug">
          {t("automation.configure_your", {
            item: t("automation.nodes.branch").toLowerCase(),
          })}
        </h1>
      </header>
      <section className="w-96">
        <div className="bg-gray-100 p-5 pb-0">
          <h3 className="text-blue-500 text-xs mb-1 font-bold">
            {t("column_labels.variables")}
          </h3>
          <p className="text-sm text-gray-800">
            {t("automation.pick_and_drag")}{" "}
            <strong>
              {t("column_labels.from")} {t("column_labels.field")}
            </strong>
          </p>
        </div>
        <Variables
          className="bg-gray-100 p-4 sticky top-0 z-10"
          variables={variables}
          handleDragStart={onDragStartTag}
        />
        <Form
          form={form}
          layout="vertical"
          name="branch-form"
          autoComplete="off"
          onFinish={onSubmitForm}
          onFinishFailed={onSubmitFormFail}
          onValuesChange={onValuesChange}
          requiredMark={true}
          scrollToFirstError
          className="space-y-5 p-5"
          initialValues={{ ...defaultValues }}
        >
          <Form.Item
            name="nameId"
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
                {t("automation.step")} {t("column_labels.name").toLowerCase()}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: `${t("automation.step")} ${t("column_labels.name")}`,
                }),
              },
              {
                required: true,
                pattern: new RegExp(/^[a-z0-9].*[a-z0-9]$/g),
                message: t("automation.step_name_start_end"),
              },
              {
                validator(rule, value) {
                  let nameList = [...stepNameList];
                  const arrayIndex = nameList.indexOf(node?.data?.nodeId);

                  // Remove current node name in the array to exclude from exist check
                  nameList.splice(arrayIndex, 1);

                  if (nameList.includes(value)) {
                    return Promise.reject(
                      t("validations.already_exists", {
                        value: `${t("automation.step")} ${t(
                          "column_labels.name"
                        ).toLowerCase()}`,
                      })
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder={t("validations.valid", {
                value: `${t("automation.step")} ${t(
                  "column_labels.name"
                ).toLowerCase()}`,
              })}
              onChange={(e) => {
                const newValue = String(e.target.value)
                  .toLowerCase()
                  .replace(/ /g, "_");
                form.setFieldsValue({ nameId: newValue.trim() });
              }}
            />
          </Form.Item>
          {/* <Form.Item
            name="nameId"
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">Name or ID</h3>
            }
            rules={[{ required: true, message: "Name/Id is required!" }]}
          >
            <Input placeholder="Name or ID" />
          </Form.Item> */}
          <Form.Item
            name="condition"
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("validations.select", {
                  value: t("automation.condition").toLowerCase(),
                })}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("automation.condition"),
                }),
              },
            ]}
          >
            <Select
              placeholder={t("validations.select", {
                value: t("automation.condition").toLowerCase(),
              })}
              className="w-60 block"
              onChange={(v) => {
                setCondition(Number(v));
                onReset();
              }}
            >
              {branchConditions.map((v) => (
                <Option value={v.id} key={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {selectedCondition === 1 && (
            <CountryCodeForm
              setRef={setRef}
              getRef={getRef}
              onDragStartTag={onDragStartTag}
              onDragOverInput={onDragOverInput}
              onDropInput={onDropInput}
              formData={formData}
              form={form}
            />
          )}
          {selectedCondition === 2 && (
            <StringContainsForm
              setRef={setRef}
              getRef={getRef}
              onDragStartTag={onDragStartTag}
              onDragOverInput={onDragOverInput}
              onDropInput={onDropInput}
              formData={formData}
              form={form}
            />
          )}
          {selectedCondition === 3 && (
            <TimeOfDayBetweenForm
              setRef={setRef}
              getRef={getRef}
              formData={formData}
              form={form}
              timezones={timezones}
              onDragStartTag={onDragStartTag}
              onDragOverInput={onDragOverInput}
              onDropInput={onDropInput}
              onValuesChange={onValuesChange}
            />
          )}
          {selectedCondition === 4 && (
            <DayOfWeekForm
              setRef={setRef}
              getRef={getRef}
              formData={formData}
              form={form}
              onDragStartTag={onDragStartTag}
              onDragOverInput={onDragOverInput}
              onDropInput={onDropInput}
            />
          )}
          {selectedCondition === 5 && (
            <CustomScriptForm
              setRef={setRef}
              getRef={getRef}
              formData={formData}
              form={form}
              onDragStartTag={onDragStartTag}
              onDragOverInput={onDragOverInput}
              onDropInput={onDropInput}
            />
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={!form.isFieldsTouched(false)}
            >
              {t("actions.update")}
            </Button>
            <Popconfirm
              placement="top"
              title={t("confirmations.delete2", { item: t("automation.step") })}
              onConfirm={onConfirmDelete}
              okText={`${t("actions.delete")} ${t("automation.step")}`}
              cancelText={t("actions.cancel")}
            >
              <Button type="text" className="w-full mt-2" danger>
                {t("actions.delete")}
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </section>
    </>
  );
};

export default BranchForm;
