import React from "react";

export default function Note({ type, text, children, className }) {
  let icon = null;
  if (type === "tip") {
    icon = (
      <span className="rounded-full w-6 h-6 bg-blue-100 text-blue-600 flex items-center justify-center text-xs flex-shrink-0">
        <i className="material-icons text-sm">lightbulb_outline</i>
      </span>
    );
  } else {
    icon = (
      <span className="rounded-full w-6 h-6 bg-yellow-100 text-yellow-600 flex items-center justify-center text-xs flex-shrink-0">
        <i className="material-icons text-sm">priority_high</i>
      </span>
    );
  }
  return (
    <div
      role="none"
      className={
        "text-xs leading-loose text-gray-700 flex space-x-2 " + className
      }
    >
      {icon}
      <span>{children || text}</span>
    </div>
  );
}
