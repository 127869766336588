import React, { useState, useEffect, useRef } from "react";
import { Handle } from "react-flow-renderer";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment-duration-format";
import { Popconfirm } from "antd";
import { useTranslation, Trans } from "react-i18next";

import nodes from "@/json/nodes";

import WaitForm from "../forms/wait";
import Bubble from "@/components/bubble";
import StepList from "@/components/compatibleStepList";
import Tag from "@/components/tag";

const handlerTargetStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "-7px",
  bottom: "auto",
  transform: "translateX(-50%)",
};

const handlerSourceStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "auto",
  bottom: "-7px",
  transform: "translateX(-50%)",
};

const displayTimeoutFormat = "d [days] H [hours] m [minutes] s [seconds]";

const WaitNode = (node) => {
  const { elements, selectedNode } = useSelector((state) => state.flow);

  const formButtonRef = useRef(null);
  const [showFormBubble, setShowFormBubble] = useState(false);

  const { t } = useTranslation();

  const formComponent = (
    <Bubble
      visible={showFormBubble}
      handleClickOutside={() => setShowFormBubble(false)}
      wrapperRef={formButtonRef}
    >
      <div className="p-3">
        <header className="flex items-center justify-between">
          <h2 className="text-gray-600 text-base font-base">{}</h2>
          <Popconfirm
            placement="top"
            title={t("confirmations.delete2", { item: t("automation.step") })}
            onConfirm={() => {
              node.data.handleDeleteNode(node);
            }}
            okText={`${t("actions.delete")} ${t("automation.step")}`}
            cancelText={t("actions.cancel")}
          >
            <a
              href="/"
              onClick={(ev) => {
                ev.preventDefault();
                // node.data.handleDeleteNode(node);
              }}
              className="text-gray-400 text-xs hover:text-red-500"
            >
              {t("actions.delete")}
            </a>
          </Popconfirm>
        </header>
        {/* {(elements[node.id]?.inputs?.nameId || node?.data?.nameId) && (
          <div class="text-xs truncate border-b border-gray-200 px-4 py-2 flex">
            <p class="text-xs text-gray-500 py-1 mr-2">Step name</p>
            <p className="text-pink-600 bg-pink-100 py-1 px-2 text-xs rounded-full font-bold inline-flex items-center">
              {elements[node.id]?.inputs?.nameId || node?.data?.nameId}
            </p>
          </div>
        )} */}
        <div aria-hidden className="rounded bg-yellow-100 my-3 py-10">
          <div className="w-8 h-8 bg-none flex justify-center items-center p-1 rounded-full mx-auto relative">
            <i className="material-icons text-yellow-700 text-2xl z-20">
              hourglass_bottom
            </i>
            <span className="w-full h-full absolute inset-0 animate-ping bg-yellow-200 rounded-full z-10"></span>
          </div>
        </div>
        <WaitForm
          node={node}
          handleSubmit={(updatedNode) => {
            node.data.handleUpdateNodeValue(updatedNode);
            setShowFormBubble(false);
          }}
          handleCancel={(e) => {
            e.stopPropagation();
            setShowFormBubble(false);
          }}
          stepNameList={node.data.stepNameList}
          onUpdateStepNameList={node.data.onUpdateStepNameList}
        />
      </div>
    </Bubble>
  );

  const isValidConnection = (destinationNode) => {
    const currentNode = elements[node.id];
    const targetNode = elements[destinationNode];
    if (!targetNode || destinationNode === node.id) return;
    return nodes[currentNode.type].compatibleNode.includes(targetNode.type);
  };

  const showStepRef = useRef(null);
  const [showStepsBubble, setShowStepsBubble] = useState(false);

  const addChild = (type) => {
    node.data.handleCreateNewNode(type, {
      ...elements[node.id],
      id: node.id,
    });
    setShowStepsBubble(false);
  };

  const stepOptionsComponent = (
    <Bubble
      visible={showStepsBubble}
      style={{ maxHeight: "100px" }}
      className="overflow-y-auto"
      handleClickOutside={() => setShowStepsBubble(false)}
      wrapperRef={showStepRef}
    >
      <div className="py-4 px-2 w-60">
        <StepList
          visible={true}
          nodeType={elements[node.id]?.type}
          handleSelectStep={(step) => addChild(step)}
        />
      </div>
    </Bubble>
  );

  const isExistInSource = (source, sourceHandle) => {
    if (sourceHandle !== "default-handle") return false;
    return elements[source].target.includes(node.id);
  };

  // Set node as 'selected' if input is empty upon initialize so that this node will appear on the sidebar
  useEffect(() => {
    if (!Object.keys(elements[node.id]?.inputs || {}).length) {
      setShowFormBubble(true);
    }
  }, []);

  // // Format the node display name
  // const formatNodeName = useMemo(() => {
  //   const name = node.data.nodeId;
  //   const defaultName = "Wait";

  //   // If generated name (e.g. sms-1637730438 {timestamp}) then return defaultName (e.g. "Send SMS")
  //   if (name.match(/^[a-z]*-\d{10}$/g)) return defaultName;
  //   return name.toUpperCase().replaceAll("_", " ");
  // }, [node.data.nodeId]);

  return (
    <div className="bg-white shadow rounded w-72 relative">
      <header className="text-base px-4 py-3 flex items-center font-bold text-gray-700 border-b border-gray-200 relative">
        {!Object.keys(elements[node.id]?.inputs || {}).length ? (
          <Tag className="absolute -top-2 right-2 text-white bg-yellow-500">
            {t("app_labels.new").toUpperCase()}
          </Tag>
        ) : (
          ""
        )}
        <Handle
          type="target"
          position="top"
          style={handlerTargetStyle}
          isValidConnection={({ source, sourceHandle }) =>
            isValidConnection(source) && !isExistInSource(source, sourceHandle)
          }
        />
        <span
          role="presentation"
          onClick={() => {
            setShowFormBubble(true);
            node.data.handleHideSidebar();
          }}
          class="w-full flex"
        >
          <span className="-mt-6 inline-flex justify-center items-center w-12 h-12 p-1 rounded bg-gradient-to-tr from-blue-500 to-indigo-500 mr-2">
            <i className="material-icons text-white text-2xl">
              hourglass_bottom
            </i>
          </span>
          {elements[node.id]?.inputs?.nameId || node?.data?.nameId ? (
            <span className="ml-1 block truncate text-gray-600 bg-gray-100 py-1 px-2 text-sm rounded-full font-bolda text-center">
              {elements[node.id]?.inputs?.nameId || node?.data?.nameId}
            </span>
          ) : (
            <span class="text-sm">{t("automation.nodes.wait")}</span>
          )}
        </span>
      </header>
      <div ref={formButtonRef} className="px-4 py-3 flex flex-col relative">
        <figure
          role="presentation"
          onClick={() => {
            setShowFormBubble(true);
            node.data.handleHideSidebar();
          }}
        >
          <figcaption className="text-gray-500 text-xs mb-1">
            {t("column_labels.duration")}
          </figcaption>
          <p className="text-xl text-gray-800 font-bold">
            {node?.data?.duration && node?.data?.duration != "0.00:00:00"
              ? moment
                  .duration(node?.data?.duration || "0.00:00:00", "d.HH:mm:ss")
                  .format(displayTimeoutFormat, { trim: "all" })
              : "00:00:00"}
          </p>
        </figure>
        {formComponent}
      </div>
      <footer className="relative">
        <span
          role="button"
          tabIndex={0}
          ref={showStepRef}
          onClick={(e) => {
            e.preventDefault();
            setShowStepsBubble(!showStepsBubble);
            node.data.handleHideSidebar();
          }}
          className={`cursor-pointer border-t border-gray-200 flex items-center justify-center text-blue-500 p-2 font-medium rounded-b transition-colors duration-100 ease-in hover:bg-blue-500 hover:text-white relative ${
            showStepsBubble ? "bg-blue-500 text-white" : ""
          }`}
        >
          <i className="material-icons text-lg mr-1">add</i>
          {!(node?.data?.target).length
            ? t("automation.add_next_step")
            : t("automation.insert_next_step")}
        </span>
        <Handle
          id="a"
          isValidConnection={({ target }) => isValidConnection(target)}
          type="source"
          position="bottom"
          style={handlerSourceStyle}
        />
        {stepOptionsComponent}
      </footer>
    </div>
  );
};

export default WaitNode;
