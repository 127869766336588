export const DecodeJwt = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join("")
    );

    const decodedData = JSON.parse(jsonPayload);
    return decodedData;
  } catch (err) {
    console.error("Failed to validate region"); // eslint-disable-line
    return 0;
  }
};
