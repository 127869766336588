import React, { useState, useEffect, useReducer, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Form,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  TimePicker,
  Select,
} from "antd";
const { Option } = Select;
import { useTranslation, Trans } from "react-i18next";

// import { FlattenObject as flattenObject } from "@/utils/FlattenObject";
import Variables from "@/components/variables";

import channelOptions from "../../../json/channels.json";

const format = "HH:mm:ss";

const ACTIONS = {
  INITIALIZE_FORM: "initialize-form",
  UPDATE_VALUE: "update-value",
};

function reducer(formData, action) {
  const { item, value } = action.payload;
  switch (action.type) {
    case ACTIONS.INITIALIZE_FORM:
      return { ...action.payload };
    case ACTIONS.UPDATE_VALUE:
      return { ...formData, [item]: value };
    default:
      return formData;
  }
}

const WaitReplyForm = ({
  node,
  onUpdateNodeValue,
  sidebarScrollWrapper,
  onDeleteNode,
  stepNameList,
  onUpdateStepNameList,
}) => {
  const [form] = Form.useForm();
  const { elements } = useSelector((state) => state.flow);

  const fromInputRef = useRef(null);

  const [formData, dispatch] = useReducer(reducer, {});

  const { t } = useTranslation();

  // Set 'formData' to the values of 'form instance' on mount
  useEffect(() => {
    // console.log("elements", elements);
    dispatch({
      type: ACTIONS.INITIALIZE_FORM,
      payload: form.getFieldsValue(true),
    });

    // Set scrollTop to 0
    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Re-render fields if selectedNode is updated but has same type with previous selectedNode
  useEffect(() => {
    form.resetFields();

    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [node]);

  const onChangeFormValue = (item, value) => {
    dispatch({
      type: ACTIONS.UPDATE_VALUE,
      payload: { item, value },
    });
  };

  const onChangeTimeoutValue = (item, value) => {
    // console.log(item, moment(value).format(format));
  };

  const onSubmitForm = (values) => {
    form.validateFields().then((v) => {
      // Update store node data
      const { id } = node;
      const newData = { ...elements[id] };
      const { timeoutDay, ...values } = v;
      values.timeout = `${timeoutDay}.${moment(values.timeout).format(format)}`;
      delete newData.nodeId;

      let triggerOutputs = {
        // [`${values?.nameId}_step_reply`]: "{{step.reply}}",
      };

      const { source } = newData;

      if (source && source.length) {
        const t = { ...elements[source] };
        if (t.type === "ChatAppsMessage") {
          triggerOutputs = {
            ...triggerOutputs,
            [`${values?.nameId}_step_text`]:
              "{{step.reply.payload.content.text}}",
          };
        }

        if (t.type === "SMS") {
          triggerOutputs = {
            ...triggerOutputs,
            [`${values?.nameId}_step_body`]: "{{step.reply.payload.body}}",
          };
        }
      }

      let outputs = node?.data?.outputs || {};

      onUpdateNodeValue({
        id,
        inputs: { ...newData.inputs, ...values },
        outputs: {
          ...outputs,
          ...triggerOutputs,
        },
      });

      // Update the master list of step names
      let nameList = [...stepNameList];
      const arrayIndex = nameList.indexOf(node?.data?.nameId || id);
      nameList.splice(arrayIndex, 1, values?.nameId);
      onUpdateStepNameList(nameList);
    });
  };

  const onSubmitFormFail = (values) => {
    // console.log("Failed to submit form");
  };

  const onConfirmDelete = () => {
    onDeleteNode(node.id || "");
  };

  const onDragStartTag = (e) => {
    e.dataTransfer.setData("variable", e.target.dataset.value);
  };

  const onDragOverInput = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDropInput = (e, fieldItem) => {
    e.preventDefault();
    const variable = e.dataTransfer.getData("variable");
    const { value, selectionEnd, selectionStart } = e.target;
    const newValue =
      value.slice(0, selectionStart) + variable + value.slice(selectionEnd);

    // Update values
    form.setFieldsValue({ [fieldItem]: newValue });
    onChangeFormValue(fieldItem, newValue);
  };

  const [variables, setVariables] = useState({});

  useEffect(() => {
    setVariables({ currId: node.id, elements, currOutputs: formData.outputs });
  }, [node, elements, formData.outputs]);

  return (
    <>
      <header className="bg-gradient-to-tr from-blue-500 to-indigo-500 px-5 py-7 w-96">
        <small className="text-xs text-red-200 tracking-widest">
          {t("automation.nodes.wait_for_reply")}
        </small>
        <h1 className="font-bold text-lg text-white tracking-wider pr-20 leading-snug">
          {t("automation.modify_how_long")}
        </h1>
      </header>
      <section className="w-96">
        <div className="bg-gray-100 p-5 pb-0">
          <h3 className="text-blue-500 text-xs mb-1 font-bold">
            {t("column_labels.variables")}
          </h3>
          <p className="text-sm text-gray-800">
            {t("automation.pick_and_drag")}{" "}
            <strong>
              {t("column_labels.from")} {t("column_labels.field")}
            </strong>
          </p>
        </div>
        <Variables
          className="bg-gray-100 p-4 sticky top-0 z-10"
          variables={variables}
          handleDragStart={onDragStartTag}
        />
        <Form
          form={form}
          layout="vertical"
          name="sms-form"
          autoComplete="off"
          onFinish={onSubmitForm}
          onFinishFailed={onSubmitFormFail}
          requiredMark={true}
          scrollToFirstError
          className="space-y-5 p-5"
        >
          <Form.Item
            name="nameId"
            initialValue={
              node?.data?.nameId || formData?.nameId || node.id || ""
            }
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
                {t("automation.step")} {t("column_labels.name").toLowerCase()}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: `${t("automation.step")} ${t("column_labels.name")}`,
                }),
              },
              {
                required: true,
                pattern: new RegExp(/^[a-z0-9].*[a-z0-9]$/g),
                message: t("automation.step_name_start_end"),
              },
              {
                validator(rule, value, callback) {
                  let nameList = [...stepNameList];
                  // const arrayIndex = nameList.indexOf(node?.data?.nameId);

                  // Remove current node name in the array to exclude from exist check
                  nameList = nameList.filter((v) => v !== node?.data?.nodeId);

                  if (nameList.includes(value)) {
                    return Promise.reject(
                      t("validations.already_exists", {
                        value: `${t("automation.step")} ${t(
                          "column_labels.name"
                        ).toLowerCase()}`,
                      })
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder={t("automation.rename")}
              onChange={(e) => {
                const newValue = String(e.target.value)
                  .toLowerCase()
                  .replace(/ /g, "_");
                form.setFieldsValue({ nameId: newValue.trim() });
                onChangeFormValue("nameId", newValue.trim());
              }}
            />
          </Form.Item>
          <Form.Item
            name="from"
            initialValue={node?.data?.from || form?.from || ""}
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("column_labels.from")}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("column_labels.from"),
                }),
              },
            ]}
          >
            <Input
              ref={fromInputRef}
              placeholder={t("automation.enter_or_drag")}
              onChange={(e) => onChangeFormValue("from", e.target.value)}
              onDragEnter={() => fromInputRef.current.focus()}
              onDragOver={onDragOverInput}
              onDrop={(e) => onDropInput(e, "from")}
            />
          </Form.Item>
          <Form.Item
            name="channel"
            initialValue={node?.data?.channel || ""}
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("validations.select", {
                  value: t("column_labels.channel").split("|")[0].toLowerCase(),
                })}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("column_labels.channel").split("|")[0].toLowerCase(),
                }),
              },
            ]}
          >
            <Select
              name="channel"
              showSearch
              placeholder={t("validations.select", {
                value: t("column_labels.channel").split("|")[0].toLowerCase(),
              })}
              filterOptions={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => onChangeFormValue("channel", value)}
              className="w-60 block"
            >
              {Object.keys(channelOptions).map((channel) => (
                <Option value={channelOptions[channel].value} key={channel}>
                  {channelOptions[channel].name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <div>
                <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
                  {t("automation.timeout")}
                </h3>
                <p className="text-sm text-gray-400">
                  {t("automation.set_the_wait")}
                </p>
              </div>
            }
            className="flex flex-no-wrap space-between-2"
          >
            <Form.Item
              name="timeoutDay"
              initialValue={(node?.data?.timeout || "0.00:00:00").replace(
                /([0-9]*)\.[0-9]{2}:[0-9]{2}:[0-9]{2}/g,
                "$1"
              )}
              className="w-1/3 inline-block"
            >
              <Select
                onChange={(v) => {
                  form.setFieldsValue({ ["timeoutDay"]: v });
                  onChangeFormValue("timeoutDay", v);
                }}
              >
                {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
                  <Option key={`day-option-${i}`} value={String(i)}>
                    {i} {t("column_labels.days").split("|")[1]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="timeout"
              initialValue={moment(
                (node?.data?.timeout || "00:01:00").replace(
                  /[0-9]*\.([0-9]{2}:[0-9]{2}:[0-9]{2})/g,
                  "$1"
                ),
                format
              )}
              className="w-2/3 inline-block"
            >
              <TimePicker
                showTime
                onChange={(value) => {
                  form.setFieldsValue({ ["timeout"]: value });
                  onChangeFormValue("timeout", value);
                }}
                showNow={false}
                use12Hours={false}
                allowClear={false}
                hideDisabledOptions
              />
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={!form.isFieldsTouched(false)}
            >
              {t("actions.update")}
            </Button>
            <Popconfirm
              placement="top"
              title={t("confirmations.delete2", { item: t("automation.step") })}
              onConfirm={onConfirmDelete}
              okText={`${t("actions.delete")} ${t("automation.step")}`}
              cancelText={t("actions.cancel")}
            >
              <Button type="text" className="w-full mt-2" danger>
                {t("actions.delete")}
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </section>
    </>
  );
};

export default WaitReplyForm;
