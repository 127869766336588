import "./tag.scss";

const Tag = (props) => {
  return (
    <small
      {...props}
      className={`custom-tag tracking-wide text-xs font-medium px-2 py-1 rounded-full z-10 ${props.className}`}
    >
      {props.children}
    </small>
  );
};

export default Tag;
