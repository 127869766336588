export const download = (
  object = {},
  mimeType = "text/plain",
  filename = "data.txt"
) => {
  let body = document.body;
  const a = document.createElement("a");
  a.href = URL.createObjectURL(
    new Blob([JSON.stringify(object, null, 2)], {
      type: mimeType,
    })
  );
  a.setAttribute("download", filename);
  body.appendChild(a);
  a.click();
  body.removeChild(a);
};
