import React, { useState, useRef, useEffect } from "react";
import { CopyBlock, solarizedDark } from "react-code-blocks";
import { MinusCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Space,
  Button,
  Input,
  Tooltip,
  Tag,
  Select,
  message,
} from "antd";
import { useTranslation, Trans } from "react-i18next";
const { TextArea } = Input;
const { Option } = Select;
import Note from "@/components/note";

const HttpTriggerForm = ({
  node,
  form,
  formTemplate,
  onUpdateForm,
  subAccountId,
  subaccountOptions,
}) => {
  const variableInputRef = useRef(null);
  const urlInputRef = useRef(null);
  const isMounted = useRef(false);
  const { t } = useTranslation();

  const [focusedVariable, setFocusedVariable] = useState(0);

  useEffect(() => {
    isMounted.current = true;
    const user = JSON.parse(localStorage.getItem("CPV3_User") || {});

    const subAccountId = form.getFieldsValue(true).subAccountId || "";

    form.setFieldsValue({
      url: `https://automation.8x8.com/api/v1/accounts/${
        user?.AccountId || ":your_account_id"
      }/triggers/http_request?subAccountId=${subAccountId}`,
    });
    urlInputRef.current.focus();
  }, []);

  useEffect(() => {
    if (!variableInputRef.current || isMounted.current) {
      return;
    }

    variableInputRef.current.focus({ cursor: "all" });
  }, [formTemplate?.outputs?.length, focusedVariable]);

  const convertVariablesToJson = () => {
    const outputs = form.getFieldsValue(false)?.outputs || [];
    if (!outputs.length) return {};

    return outputs.reduce((acc, curr, i) => {
      const { key } = curr;
      if (!key) return acc;

      return {
        ...acc,
        [key]: `variable ${i + 1}`,
      };
    }, {});
  };

  return (
    <>
      <Form.Item
        name="subAccountId"
        initialValue={subAccountId}
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("validations.select", {
              value: `${t("app_labels.new").toLowerCase()} ${t(
                "fields.subaccount"
              ).toLowerCase()}`,
            })}
          </h3>
        }
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("fields.subaccount").toLowerCase(),
            }),
          },
        ]}
        className="px-5"
      >
        <Select
          name="triggersubaccount"
          showSearch
          placeholder={t("validations.select", {
            value: t("fields.subaccount").toLowerCase(),
          })}
          filterOptions={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(value) => onUpdateForm("subAccountId", value)}
          className="w-60 block"
        >
          {subaccountOptions.map((account) => (
            <Option value={account.SubAccountId} key={account.SubAccountUid}>
              {account.SubAccountId}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className="px-5"
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("automation.webhook_trigger")}
          </h3>
        }
      >
        <CopyBlock
          text={form.getFieldsValue(false)?.url || ""}
          language={"text"}
          showLineNumbers={false}
          theme={solarizedDark}
          wrapLines
          codeBlock
        />
        <Form.Item
          name="url"
          initialValue={node?.data?.url || ""}
          rules={[
            {
              required: true,
              message: t("validations.required", {
                value: t("column_labels.url"),
              }),
            },
            {
              type: "url",
              message: t("validations.invalid", {
                value: t("column_labels.url"),
              }),
            },
          ]}
          className="hidden"
        >
          <Input
            ref={urlInputRef}
            placeholder="https://www.your-webhook-url.com"
            onChange={(e) => onUpdateForm("url", e.target.value)}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item
        className="px-5"
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("automation.run_this")}
          </h3>
        }
      >
        <CopyBlock
          text={`curl --location -X POST '${
            form.getFieldsValue(false)?.url
          }' \\\n --header 'Content-Type: application/json' \\\n --header 'Authorization: Bearer YOUR_API_KEY' \\\n --data-raw '${JSON.stringify(
            convertVariablesToJson(),
            null,
            "\t"
          )}'`}
          language={"jsx"}
          showLineNumbers={true}
          theme={solarizedDark}
          wrapLines
          codeBlock
        />
        <Note type="tip" className="mt-5">
          {t("automation.replace_api_key.0")}{" "}
          <code className="p-1 rounded-sm text-green-600 bg-green-100 not-italic">
            YOUR_API_KEY
          </code>{" "}
          {t("automation.replace_api_key.1")}{" "}
          <code className="text-green-500">200</code>,{" "}
          {t("automation.replace_api_key.2")}.
        </Note>
      </Form.Item>
      <Form.Item
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
            {t("column_labels.data")}
          </h3>
        }
        className="bg-gray-50 p-5"
      >
        <Form.Item noStyle>
          <Form.List name="outputs">
            {(outputsData, { add, remove }) => (
              <div className="w-full">
                {outputsData.map((output, i) => (
                  <div
                    key={output.key}
                    className="w-full flex flex-nowrap space-x-2"
                  >
                    <Form.Item
                      name={[i, "key"]}
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: t("validations.required", {
                            value: t("column_labels.key"),
                          }),
                        },
                        {
                          validator(rule, value) {
                            const outputs = form
                              .getFieldsValue(true)
                              .outputs.map((i) => i.key);
                            const dupOutputs = outputs.filter(
                              (e, i, a) => a.indexOf(e) !== i
                            );
                            // If duplicate name
                            if (dupOutputs.includes(value)) {
                              return Promise.reject(
                                t("automation.duplicate_header", {
                                  item: t("column_labels.queries")
                                    .split("|")[0]
                                    .toLowerCase(),
                                })
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          onUpdateForm("outputs", [
                            ...form.getFieldsValue(true).outputs,
                            { key: e.target.value },
                          ])
                        }
                        placeholder="New variable name"
                        className="w-full text-xs px-0 py-2"
                        bordered={false}
                        style={{
                          borderBottom: "1px solid #D1D5DB",
                          borderRadius: 0,
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <i
                        role="button"
                        tabIndex={0}
                        className="cursor-pointer material-icons mr-1 text-base text-gray-300 hover:text-red-500"
                        onClick={() => {
                          // Remove item in form
                          remove(output.name);
                          const newOutputs = [
                            ...form.getFieldsValue(true).outputs,
                          ];
                          newOutputs.splice(output.key, 1);
                          onUpdateForm("outputs", newOutputs);
                        }}
                      >
                        delete_outline
                      </i>
                    </Form.Item>
                  </div>
                ))}
                <Form.Item noStyle>
                  <Button
                    type="dashed"
                    size="small"
                    className="flex items-center"
                    onClick={() => {
                      const newArrayValue = [
                        ...(form.getFieldsValue(false).outputs || []),
                      ];
                      newArrayValue.push({ key: "", value: "" });
                      onUpdateForm("outputs", newArrayValue);
                      form.setFieldsValue({
                        outputs: [...newArrayValue],
                      });
                    }}
                  >
                    <i className="material-icons text-base mr-1">add</i>
                    {t("app_labels.new")} {t("column_labels.data")}
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form.Item>
        <Note
          text="Custom data not used in your flow won’t be saved and displayed next time you load this workflow."
          className="mt-5"
        />
      </Form.Item>
    </>
  );
};

export default HttpTriggerForm;
