import { useState } from "react";
import { Tooltip } from "antd";

import { flattenObject } from "@/utils/common";
import { useTranslation, Trans } from "react-i18next";

const VIEW_LIMIT = 1;

const Variables = ({ variables, handleDragStart, className }) => {
  const [expand, setExpand] = useState(false);

  const { t } = useTranslation();

  const { currId, elements, currOutputs } = variables;
  let vars = {};

  if (elements) {
    Object.keys(elements).forEach((el) => {
      if (elements[el]?.outputs && Object.keys(elements[el].outputs).length) {
        vars[el] = { ...flattenObject({ ...elements[el].outputs }, ".") };
      }
    });
  }

  const getItem = (allVars, step, item) => {
    let label = "";
    let value = "";
    let title = allVars[step][item] || "";

    if (step !== "trigger") {
      label = `${item}`;
      value = `{{data.${item}}}`;
    } else {
      label = `${item}`;
      value = `${allVars[step][item]}`;
    }

    if (label.match(/^custom_/)) {
      label = label.replace("custom_", "");

      title = `{{data.${label}}}`;
      value = `{{data.${label}}}`;
    }

    Object.keys(elements).forEach((v) => {
      const re = `${v}_`;

      const rg = new RegExp(re, "g");

      label = label.replace(rg, "");
    });

    return { label, title, value };
  };

  const VariablesList = ({ allVars, step }) => {
    return Object.keys(allVars[step]).map((item, ci) => (
      <li key={`variable-tag-${item}`} className="block m-1">
        <Tooltip
          placement="top"
          title={getItem(allVars, step, item).title}
          overlayInnerStyle={{ textAlign: "center" }}
        >
          <div
            className={`text-green-700 bg-green-200 py-1 px-2 text-xs rounded-full font-bold tracking-wide`}
            data-value={getItem(allVars, step, item).value}
            style={{ cursor: "grab" }}
            draggable
            onDragStart={handleDragStart}
          >
            {getItem(allVars, step, item).label}
          </div>
        </Tooltip>
      </li>
    ));
  };

  return Object.keys(vars || {}).length ? (
    <ul
      className={`list-none overflow-auto ${className}`}
      style={{ maxHeight: "350px" }}
    >
      {Object.keys(vars).map((step, i) =>
        expand || i < VIEW_LIMIT ? (
          step && Object.keys(vars[step]).length ? (
            <li
              key={`variable-parent-tag-${step}`}
              className={`border-dotted border-2 p-1 rounded mt-3 "border-green-200"`}
            >
              <span className={`text-xs font-semibold ml-2 text-green-700`}>
                {step === "trigger" ? "TRIGGER" : step}
                <span class="text-gray-300">
                  &nbsp; {step === "trigger" ? "" : `(${elements[step]?.type})`}
                </span>
              </span>
              <ul className={`list-none flex flex-wrap mt-2`}>
                <VariablesList allVars={vars} step={step} />
              </ul>
            </li>
          ) : null
        ) : null
      )}
      {Object.keys(vars || {}).length > VIEW_LIMIT ? (
        <li className="block m-1">
          <button
            className="text-gray-800 py-1 px-2 text-xs rounded-full tracking-wide border border-dashed border-gray-400 leading-none focus:outline-none focus:text-indigo-700 focus:border-indigo-500"
            onClick={() => setExpand(!expand)}
          >
            {expand
              ? t("messaging.api_keys.index.5")
              : t("app_labels.show_plus_more", {
                  item: `+${
                    (Object.keys(vars || {}).length || VIEW_LIMIT) - VIEW_LIMIT
                  }`,
                })}
          </button>
        </li>
      ) : (
        ""
      )}
    </ul>
  ) : (
    <div
      className={`${className} relative top-auto flex items-center space-x-1 text-gray-500`}
    >
      <i className="material-icons text-base">info_outline</i>
      <span>{t("automation.no_variables")}</span>
    </div>
  );
};

export default Variables;
