import { useEffect } from "react";
import { Modal, Button, Table } from "antd";
import { useTranslation, Trans } from "react-i18next";

const TemplateModal = ({
  visible,
  setVisible,
  subaccount,
  loading,
  templates,
  enums,
  getTemplates,
  getEnums,
  handleSelectTemplate,
}) => {
  const { t } = useTranslation();
  // Fetch Templates and Enums
  useEffect(() => {
    if (!visible) return;

    const channelId = subaccount?.whatsAppChannelId;
    getTemplates({ channelId });
    getEnums({ channelId });
  }, [visible, subaccount]);

  const onClickRow = (record) => {
    handleSelectTemplate(record);
    setVisible(false);
  };

  const columns = [
    {
      title: t("column_labels.template_name"),
      dataIndex: "templateName",
      key: "templateName",
      ellipsis: true,
    },
    {
      title: t("column_labels.category"),
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: t("column_labels.language").split("|")[0],
      dataIndex: "languageName",
      key: "languageName",
    },
  ];

  return (
    <Modal
      title={t("automation.whats_app_template")}
      visible={visible}
      onCancel={() => setVisible(false)}
      width={1000}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          {t("actions.cancel")}
        </Button>,
      ]}
    >
      <Table
        columns={columns}
        dataSource={templates}
        loading={loading}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: () => onClickRow(record),
          };
        }}
      />
    </Modal>
  );
};

export default TemplateModal;
