import {
  Form,
  Button,
  Row,
  Col,
  TimePicker,
  Checkbox,
  Select,
  Input,
  Alert,
} from "antd";

import { MinusCircleOutlined } from "@ant-design/icons";
import { useTranslation, Trans } from "react-i18next";

const { Option } = Select;

const TimeOfDayBetween = (props) => {
  const isChecked = (index) => {
    const tod = props.form.getFieldValue("timeOfDay");

    return Boolean(tod[index]?.timefallsOutside);
  };

  const { t } = useTranslation();

  return (
    <>
      <Form.List name="timeOfDay">
        {(fields, { add, remove }) => {
          /**
           * `fields` internal fill with `name`, `key`, `fieldKey` props.
           * You can extends this into sub field to support multiple dynamic fields.
           */
          return (
            <div>
              {fields.map((field, index) => (
                <Row
                  key={field.key}
                  gutter={10}
                  className="bg-gray-50 px-5 pt-3 mt-5"
                >
                  <Col span={22} />
                  <Col span={2}>
                    {fields.length !== 1 ? (
                      <MinusCircleOutlined
                        className="text-gray-300 hover:text-red-500 text-base"
                        style={{ marginTop: "17px", color: "#ccc" }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <h3 class="text-gray-500 text-xs mb-1 font-bold">
                          {t("column_labels.date")}
                        </h3>
                      }
                      name={[field.name, "date"]}
                      fieldKey={[field.fieldKey, "date"]}
                      rules={[
                        {
                          required: true,
                          message: t("validations.required", {
                            value: t("column_labels.date"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        ref={props.setRef(`dateRefBet${field.fieldKey}`)}
                        placeholder={t("automation.enter_a_valid_or_drag", {
                          value: t("column_labels.date").toLowerCase(),
                        })}
                        onDragEnter={() =>
                          props
                            .getRef(`dateRefBet${field.fieldKey}`)
                            .current.focus()
                        }
                        onDragOver={props.onDragOverInput}
                        onDrop={(e) =>
                          props.onDropInput(
                            e,
                            "date",
                            field.fieldKey,
                            "timeOfDay"
                          )
                        }
                        className="w-full text-xs px-0 py-2"
                        bordered={false}
                        style={{
                          borderBottom: "1px solid #D1D5DB",
                          borderRadius: 0,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={0} />
                  <Col span={24}>
                    <Form.Item
                      label={
                        <h3 class="text-gray-500 text-xs mb-1 font-bold">
                          {t("automation.enter_a_valid_or_drag", {
                            value: t("fields.timezone"),
                          })}
                        </h3>
                      }
                      name={[field.name, "timezone"]}
                      fieldKey={[field.fieldKey, "timezone"]}
                      rules={[
                        {
                          required: true,
                          message: t("validations.required", {
                            value: t("fields.timezone"),
                          }),
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        showSearch
                        placeholder={t("validations.select", {
                          value: t("fields.timezone"),
                        })}
                        optionFilterProp="children"
                        onChange={() => {}}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        className="w-full text-xs px-0 py-2"
                        bordered={false}
                        style={{
                          borderBottom: "1px solid #D1D5DB",
                          borderRadius: 0,
                        }}
                      >
                        {props.timezones.map((v) => (
                          <Option value={v.id} key={v.id}>
                            {v.id}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={0} />
                  <Col span={24}>
                    <Form.Item
                      label={
                        <h3 class="text-gray-500 text-xs mb-1 font-bold">
                          {t("automation.time_between")}
                        </h3>
                      }
                      name={[field.name, "time"]}
                      fieldKey={[field.fieldKey, "time"]}
                      rules={[
                        {
                          required: true,
                          message: t("validations.required", {
                            value: t("column_labels.time"),
                          }),
                        },
                      ]}
                    >
                      <TimePicker.RangePicker
                        format="HH:mm"
                        size="small"
                        className="w-full text-xs px-0 py-2"
                        bordered={false}
                        style={{
                          borderBottom: "1px solid #D1D5DB",
                          borderRadius: 0,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={0} />
                  <Col span={24} className="mb-8">
                    <Form.Item
                      noStyle
                      className="mb-10 w-full"
                      name={[field.name, "timefallsOutside"]}
                      fieldKey={[field.fieldKey, "timefallsOutside"]}
                      valuePropName="checked"
                    >
                      <Checkbox
                        size="small"
                        onChange={(ev) => {
                          const fields = props.form.getFieldsValue(true);
                          const { timeOfDay } = fields;

                          Object.assign(timeOfDay[field.fieldKey], {
                            timefallsOutside: ev.target.checked,
                          });

                          props.form.setFieldsValue({ timeOfDay });
                          props.onValuesChange();
                        }}
                      >
                        {" "}
                        <span class="text-xs text-gray-500">
                          {t("automation.time_falls_outside")}
                        </span>
                        {isChecked(field.fieldKey) ? (
                          <Alert
                            style={{ fontSize: "10px" }}
                            className="text-xs mt-2"
                            message={t("automation.step_will_not_execute")}
                            type="warning"
                          />
                        ) : null}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6} />
                </Row>
              ))}
              <Form.Item>
                {fields.length < 5 ? (
                  <Button
                    type="dashed"
                    size="small"
                    className="flex items-center mt-5 text-xs"
                    onClick={() => {
                      add();
                    }}
                  >
                    <i className="material-icons text-base mr-1">add</i>
                    {t("automation.add_new_set")}
                  </Button>
                ) : null}
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default TimeOfDayBetween;
