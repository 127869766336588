import { customAlphabet } from "nanoid";

export const getRandomId = () => {
  const nanoid = customAlphabet("1234567890", 4);

  return nanoid();
};

export const removeCurlyBraces = (str) => {
  return str.trim().replace(/[{}]/g, "");
};

export const hasEnclosedCurlyBraces = (str) => {
  return str.trim().match(/{{(.*)}}/g);
};

export const getUserCountryCode = () => {
  let country = "SG";

  if (localStorage.getItem("CPV3_User")) {
    const CPV3User = localStorage.getItem("CPV3_User");

    country = CPV3User.TimeZoneCountry;
  }

  if (localStorage.getItem("user_country")) {
    const IPUser = localStorage.getItem("user_country");

    country = IPUser.split(";")[1];
  }

  return country;
};

export const flattenObject = (source, delimiter, filter) => {
  var result = {};
  (function flat(obj, stack) {
    Object.keys(obj).forEach(function (k) {
      var s = stack.concat([k]);
      var v = obj[k];
      if (filter && filter(k, v)) return;
      if (typeof v === "object") flat(v, s);
      else result[s.join(delimiter)] = v;
    });
  })(source, []);

  return result;
};

export const flattenObject2 = (obj, prefix = "") => {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + "." : "";
    if (typeof obj[k] === "object")
      Object.assign(acc, flattenObject2(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});
};
