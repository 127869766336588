import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import defaultNodes from "@/json/nodes";
import { useTranslation, Trans } from "react-i18next";

const StepMenu = ({ handleSelectStep, nodeType }) => {
  const onSelectStep = (step) => {
    if (!nodes[step].disabled) {
      handleSelectStep(step);
    }
  };

  const { t } = useTranslation();

  const labelts = {
    sms: t("sidebar_menu_parent_short.SMS"),
    "chat app": t("sidebar_menu_parent.Chat apps"),
    voice: t("sidebar_menu_parent.Voice"),
    http: t("automation.nodes.http_request"),
    "send to converse": t("automation.nodes.send_to_converse"),
    branch: t("automation.nodes.branch"),
    "wait for reply": t("automation.nodes.wait_for_reply"),
    wait: t("automation.nodes.wait"),
    "jump to": t("automation.nodes.jump_to"),
  };

  const {
    hasChatAppsAccess,
    hasSmsAccess,
    hasVoiceAccess,
    smsSubaccounts,
    caSubaccounts,
    voiceSubaccounts,
  } = useSelector((state) => state.user);
  const [nodes, setNodes] = useState({ ...defaultNodes });

  useEffect(() => {
    const newNode = { ...nodes };

    // Set SMS restriction
    newNode.SMS.disabled = !(hasSmsAccess && (smsSubaccounts || []).length);

    // Set CHATAPP restriction
    newNode.ChatAppsMessage.disabled = !(
      hasChatAppsAccess && (caSubaccounts || []).length
    );

    // Set VOICE restriction
    newNode.VoiceMessage.disabled = !(
      hasVoiceAccess && (voiceSubaccounts || []).length
    );

    setNodes(newNode);
  }, [
    setNodes,
    hasChatAppsAccess,
    hasSmsAccess,
    hasVoiceAccess,
    smsSubaccounts,
    caSubaccounts,
  ]);

  return (
    <ul className="relative space-y-1">
      {nodes[nodeType].compatibleNode
        .sort((n1, n2) => nodes[n1].disabled - nodes[n2].disabled)
        .map((step, i) =>
          step !== "trigger" ? (
            <li key={`step-${step}-${i}`} className="w-full">
              <button
                onClick={() => {
                  if (nodes[step].disabled) return;

                  onSelectStep(step);
                }}
                className={`flex items-center w-full font-medium p-2 rounded transition-colors duration-200 ${
                  nodes[step].disabled
                    ? "bg-gray-100 text-gray-500 shadow-inner"
                    : "hover:bg-indigo-50 text-gray-800"
                }`}
                disabled={nodes[step].disabled}
              >
                <span
                  aria-hidden
                  className={`w-7 h-7 rounded flex items-center justify-center shadow relative mr-2 ${
                    nodes[step].disabled ? "bg-gray-300" : "bg-blue-500"
                  }`}
                >
                  <i className="material-icons text-white text-lg">
                    {nodes[step].icon}
                  </i>
                </span>
                {labelts[nodes[step].label.toLowerCase()]}
              </button>
            </li>
          ) : (
            ""
          )
        )}
    </ul>
  );
};

export default StepMenu;
