import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import http from "@/utils/http-request";

const initialState = {
  smsSubaccounts: [],
  caSubaccounts: [],
  voiceSubaccounts: [],
  loading: false,
  voiceLoading: false,
  timezones: [],
  hasSmsAccess: false,
  hasChatAppsAccess: false,
  hasSmsEngageAccess: false,
  hasVoiceAccess: false,
};

export const getSmsSubaccounts = createAsyncThunk(
  "user/getSmsSubaccounts",
  async () => {
    const response = await http.v2.get("/mcs/sub-accounts/sms");
    return response.data;
  }
);

export const getCaSubaccounts = createAsyncThunk(
  "user/getCaSubaccounts",
  async () => {
    const response = await http.v2.get("/mcs/sub-accounts/chatapps");
    return response.data;
  }
);

export const getVoiceSubaccounts = createAsyncThunk(
  "user/getVoiceSubaccounts",
  async () => {
    const response = await http.v2.get("/voice/sub-accounts/details");
    return response.data;
  }
);

export const getTimezones = createAsyncThunk("user/getTimezones", async () => {
  const response = await http.v2.get("/automation/workflows/steps/timezones");
  return response.data;
});

export const getAccess = createAsyncThunk("user/getAccess", () => {
  try {
    const cpv3User = localStorage.getItem("CPV3_User") || {};
    const userData = JSON.parse(cpv3User);

    const token = localStorage.getItem("WWW-Authenticate");
    const user = { ...userData, Token: token };

    return user;
  } catch (err) {
    // console.err(err)
  }
});

const definitionsSlice = createSlice({
  name: "user",

  initialState,
  // reducers actions
  reducers: {},
  extraReducers: {
    [getSmsSubaccounts.pending]: (state, action) => {
      state.loading = true;
    },
    [getSmsSubaccounts.fulfilled]: (state, action) => {
      const data = action.payload;
      state.smsSubaccounts = data;
      state.loading = false;
    },
    [getSmsSubaccounts.rejected]: (state, action) => {
      state.loading = false;
    },

    [getTimezones.fulfilled]: (state, action) => {
      const data = action.payload;
      state.timezones = data.sort((a, b) => a.id.localeCompare(b.id));
    },

    [getAccess.fulfilled]: (state, action) => {
      const {
        Product_SMS: hasSMSAccess,
        Product_CA: hasCAAccess,
        Product_VO: hasVoiceAccess,
        SmsToSurveyEnabled: hasSurvey,
        HasSubAccountsWithSurveyForms: hasSurveyForms,
        BetaFeatures: betaFeatures,
      } = action.payload;

      state.hasSmsAccess = hasSMSAccess;
      state.hasChatAppsAccess = hasCAAccess;
      state.hasVoiceAccess =
        hasVoiceAccess && betaFeatures.includes("VO-Messaging");
      state.hasSmsEngageAccess = hasSMSAccess && hasSurvey && hasSurveyForms;
    },

    [getCaSubaccounts.pending]: (state, action) => {
      state.caLoading = true;
    },
    [getCaSubaccounts.fulfilled]: (state, action) => {
      const data = action.payload;
      state.caSubaccounts = data;
      state.caLoading = false;
    },
    [getCaSubaccounts.rejected]: (state, action) => {
      state.caLoading = false;
    },

    [getVoiceSubaccounts.pending]: (state, action) => {
      state.voiceLoading = true;
    },
    [getVoiceSubaccounts.fulfilled]: (state, action) => {
      const data = action.payload;
      state.voiceSubaccounts = data;
      state.voiceLoading = false;
    },
    [getVoiceSubaccounts.rejected]: (state, action) => {
      state.voiceLoading = false;
    },
  },
});

const { reducer } = definitionsSlice;

export default reducer;
