import { useEffect } from "react";
import { useTransition, animated } from "react-spring";

import "./bubble.css";

const Bubble = ({ visible, children, handleClickOutside, wrapperRef }) => {
  const transition = useTransition(visible, {
    from: {
      opacity: 0,
      x: -20,
      y: "-50%",
    },
    enter: {
      opacity: 1,
      x: 0,
      y: "-50%",
    },
    leave: (item) => {
      return {
        opacity: 0,
        x: -20,
        y: "-50%",
      };
    },
  });

  useEffect(() => {
    function onClickOutside(e) {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        handleClickOutside();
      }
    }

    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [wrapperRef]);

  const formComponent = transition((style, item) =>
    item ? (
      <animated.dialog
        style={{ ...style }}
        className="bubble -mt-10 bg-white rounded shadow-xl absolute top-1/2 transform block cursor-default left-full ml-5 nodrag"
      >
        {children}
      </animated.dialog>
    ) : (
      ""
    )
  );

  return <>{formComponent}</>;
};

export default Bubble;
