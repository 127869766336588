import React, { useState, useRef, useEffect } from "react";
import { Handle } from "react-flow-renderer";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment-duration-format";
import { useTranslation, Trans } from "react-i18next";

import nodes from "@/json/nodes";

import StepList from "@/components/compatibleStepList";
import Bubble from "@/components/bubble";
import Tag from "@/components/tag";

const handlerTargetStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "-7px",
  bottom: "auto",
  transform: "translateX(-50%)",
};

const handlerSourceStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "auto",
  bottom: "-7px",
  transform: "translateX(-50%)",
};

const requestMethods = {
  GET: {
    color: "text-indigo-500",
  },
  POST: {
    color: "text-green-400",
  },
  PUT: {
    color: "text-yellow-400",
  },
  PATCH: {
    color: "text-yellow-400",
  },
  DELETE: {
    color: "text-red-400",
  },
  OPTIONS: {
    color: "text-blue-500",
  },
  HEAD: {
    color: "text-blue-500",
  },
};

const displayTimeoutFormat = "m [minutes] s [seconds]";

const HttpRequestNode = (node) => {
  const { elements } = useSelector((state) => state.flow);
  const { t } = useTranslation();

  const isValidConnection = (destinationNode) => {
    const currentNode = elements[node.id];
    const targetNode = elements[destinationNode];
    if (!targetNode || destinationNode === node.id) return;
    return nodes[currentNode.type].compatibleNode.includes(targetNode.type);
  };

  const showStepRef = useRef(null);
  const [showStepsBubble, setShowStepsBubble] = useState(false);

  const addChild = (type) => {
    node.data.handleCreateNewNode(type, {
      ...elements[node.id],
      id: node.id,
    });
    setShowStepsBubble(false);
  };

  const stepOptionsComponent = (
    <Bubble
      visible={showStepsBubble}
      style={{ maxHeight: "100px" }}
      className="overflow-y-auto"
      handleClickOutside={() => setShowStepsBubble(false)}
      wrapperRef={showStepRef}
    >
      <div className="py-4 px-2 w-60">
        <StepList
          visible={true}
          nodeType={elements[node.id]?.type}
          handleSelectStep={(step) => addChild(step)}
        />
      </div>
    </Bubble>
  );

  const isExistInSource = (source, sourceHandle) => {
    if (sourceHandle !== "default-handle") return false;
    return elements[source].target.includes(node.id);
  };

  // Set node as 'selected' if input is empty upon initialize so that this node will appear on the sidebar
  useEffect(() => {
    if (!Object.keys(elements[node.id]?.inputs || {}).length) {
      node.data.handleShowNodeDetail(node);
    }
  }, []);

  // // Format the node display name
  // const formatNodeName = useMemo(() => {
  //   const name = node.data.nodeId;
  //   const defaultName = "HTTP Request";

  //   // If generated name (e.g. sms-1637730438 {timestamp}) then return defaultName (e.g. "Send SMS")
  //   if (name.match(/^[a-z]*-\d{10}$/g)) return defaultName;
  //   return name.toUpperCase().replaceAll("_", " ");
  // }, [node.data.nodeId]);

  return (
    <div className="bg-white shadow rounded w-72 relative custom_node">
      <header className="text-base px-4 py-2 flex items-center font-bold text-gray-700 border-b border-gray-200 relative">
        {!Object.keys(elements[node.id]?.inputs || {}).length ? (
          <Tag className="absolute -top-2 right-2 text-white bg-yellow-500">
            {t("app_labels.new").toUpperCase()}
          </Tag>
        ) : (
          ""
        )}
        <Handle
          type="target"
          position="top"
          style={handlerTargetStyle}
          isValidConnection={({ source, sourceHandle }) =>
            isValidConnection(source) && !isExistInSource(source, sourceHandle)
          }
        />
        <span
          role="presentation"
          onClick={() => node.data.handleShowNodeDetail(node)}
          class="w-full flex"
        >
          <span className="-mt-6 inline-flex justify-center items-center w-12 h-12 p-1 rounded bg-gradient-to-tr from-blue-500 to-indigo-500 mr-2">
            <i className="material-icons z-10 text-2xl text-white">language</i>
          </span>
          {elements[node.id]?.inputs?.nameId || node?.data?.nameId ? (
            <span className="ml-1 block truncate text-gray-600 bg-gray-100 py-1 px-2 text-sm rounded-full font-bold text-center">
              {elements[node.id]?.inputs?.nameId || node?.data?.nameId}
            </span>
          ) : (
            <span class="text-sm">{t("automation.nodes.http_request")}</span>
          )}
        </span>
      </header>
      <div
        className="px-4 py-2"
        role="presentation"
        onClick={() => node.data.handleShowNodeDetail(node)}
      >
        <figure className="flex flex-col">
          <figcaption className="text-gray-500 text-xs mb-1">
            {t("column_labels.url")}
          </figcaption>
          <p
            className={`text-base truncate font-medium ${
              node?.data?.url ? "text-gray-800" : "text-gray-400"
            }`}
          >
            <span
              className={`font-medium mr-1 ${
                requestMethods[node?.data?.method || "GET"]?.color
              }`}
            >
              {node?.data?.method || "GET"}
            </span>
            {node?.data?.url || "https://api.myproduct.com/v1"}
          </p>
        </figure>
        {node?.data?.timeoutSeconds ? (
          <span
            aria-hidden
            className="mt-2 text-yellow-600 bg-yellow-100 py-1 px-2 text-xs rounded-full font-bold tracking-wide inline-flex items-center"
          >
            <i className="material-icons text-base text-base mr-1 leading-none">
              timer
            </i>
            {moment
              .duration({ s: node?.data?.timeoutSeconds || 0 })
              .format(displayTimeoutFormat, { trim: "all" })}
          </span>
        ) : (
          ""
        )}
      </div>
      <footer className="relative">
        <span
          role="button"
          tabIndex={0}
          ref={showStepRef}
          onClick={(e) => {
            e.preventDefault();
            setShowStepsBubble(!showStepsBubble);
            node.data.handleHideSidebar();
          }}
          className={`cursor-pointer border-t border-gray-200 flex items-center justify-center text-blue-500 p-2 font-medium rounded-b transition-colors duration-100 ease-in hover:bg-blue-500 hover:text-white relative ${
            showStepsBubble ? "bg-blue-500 text-white" : ""
          }`}
        >
          <i className="material-icons text-lg mr-1">add</i>
          {!(node?.data?.target).length
            ? t("automation.add_next_step")
            : t("automation.insert_next_step")}
        </span>
        <Handle
          id="a"
          isValidConnection={({ target }) => isValidConnection(target)}
          type="source"
          position="bottom"
          style={handlerSourceStyle}
        />
        {stepOptionsComponent}
      </footer>
    </div>
  );
};

export default HttpRequestNode;
