import React from "react";
import { Select } from "antd";
import { useTranslation, Trans } from "react-i18next";

const { Option } = Select;

export default function Pagination(props) {
  const {
    filters,
    filteredDefinitions,
    definitions,
    handlePageSize,
    handlePage,
    loading,
  } = props;

  const { currentPage, pageSize, pageSizes } = filters;

  const pageCount = Math.ceil(definitions.length / pageSize);

  const { t } = useTranslation();

  const pageOptions = pageSizes.map((v) => (
    <Option value={v} key={v}>
      <span class="capitalize">{v}</span>
    </Option>
  ));

  const pages = [...Array(pageCount)].map((v, i) => (
    <Option value={i + 1} key={i}>
      <span class="capitalize">{i + 1}</span>
    </Option>
  ));

  const handleNextPage = () => {
    if (loading || currentPage === pageCount) {
      return;
    }

    handlePage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (loading || currentPage === 1) {
      return;
    }
    handlePage(currentPage - 1);
  };

  return (
    <div class="bg-white flex text-xs leading-none">
      <div class="flex flex-1 justify-start items-center">
        <p class="text-base mr-2">{t("app_labels.rows_per_page")}:</p>
        <Select
          style={{ width: "70px" }}
          placeholder=""
          disabled={loading}
          value={pageSize}
          className="text-xl inline-block"
          onChange={(v) => {
            handlePageSize(v);
          }}
        >
          {pageOptions}
        </Select>
      </div>
      <div class="flex flex-1 justify-end items-center">
        <span
          role="button"
          tabindex={0}
          class={
            loading || currentPage === 1
              ? "cursor-not-allowed text-grey disabled p-3 inline-block leading-none cursor-pointer text-base mr-3"
              : "p-3 inline-block leading-none cursor-pointer text-base mr-3 hover:text-blue"
          }
          onClick={handlePrevPage}
        >
          <i class="icon-novo-arrow-left-long mr-2 text-xl align-middle" />{" "}
          <span class="capitalize">{t("actions.prev")}</span>
        </span>
        <Select
          placeholder=""
          disabled={loading}
          style={{ width: "70px" }}
          value={currentPage}
          className="text-xl inline-block"
          onChange={(v) => {
            handlePage(v);
          }}
        >
          {pages}
        </Select>
        <p class="text-base ml-2">
          {t("app_labels.of_item_pages", { item: pageCount })}
        </p>
        <span
          role="button"
          tabindex={0}
          class={
            loading || currentPage === pageCount
              ? "cursor-not-allowed text-grey disabled p-3 inline-block leading-none cursor-pointer text-base capitalize ml-3"
              : "p-3 inline-block leading-none cursor-pointer text-base capitalize ml-3 hover:text-blue"
          }
          onClick={handleNextPage}
        >
          <span class="capitalize">{t("actions.next")}</span>{" "}
          <i class="ml-2 icon-novo-arrow-right-long text-xl align-middle" />
        </span>
      </div>
    </div>
  );
}
