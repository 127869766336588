import { Form, Input, Button, Select, Row, Col } from "antd";

import { MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const { TextArea } = Input;

import { useTranslation, Trans } from "react-i18next";

const CustomScript = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h3 class="text-sm mb-2">{t("automation.custom_scripts")}</h3>
        <p class="text-xs mb-3">
          {t("automation.automation_service.0")}{" "}
          <b>(data.member.data["test"]["key"])</b>,{" "}
          {t("automation.automation_service.1")} <b>(x===y ? 1 : 0)</b>
        </p>

        <h4 class="text-xs mb-2">{t("automation.restrictions")}</h4>
        <ul class="list-disc text-xs italic text-gray-500 px-2">
          <li>{t("automation.max_length")}</li>
          <li>{t("automation.assignments")}</li>
          <li>{t("automation.max_no")}</li>
          <li>{t("automation.recursive_functions")}</li>
          <li>{t("automation.scripts_that")}</li>
        </ul>
      </div>
      <Form.List name="scripts">
        {(fields, { add, remove }) => {
          /**
           * `fields` internal fill with `name`, `key`, `fieldKey` props.
           * You can extends this into sub field to support multiple dynamic fields.
           */
          return (
            <div>
              {fields.map((field, index) => (
                <Row
                  key={field.key}
                  gutter={10}
                  className="bg-gray-50 px-5 pt-3"
                >
                  <Col span={22}>
                    <Form.Item
                      label={
                        <h3 class="text-gray-500 text-xs mb-1 font-bold">
                          Custom script {index + 1}
                        </h3>
                      }
                      name={[field.name, "script"]}
                      fieldKey={[field.fieldKey, "script"]}
                    >
                      <TextArea
                        placeholder={t("validations.valid", {
                          value: t("automation.custom_scripts").toLowerCase(),
                        })}
                        ref={props.setRef(`scriptRef${field.fieldKey}`)}
                        onDragEnter={() =>
                          props
                            .getRef(`scriptRef${field.fieldKey}`)
                            .current.focus()
                        }
                        onDragOver={props.onDragOverInput}
                        onDrop={(e) =>
                          props.onDropInput(
                            e,
                            "script",
                            field.fieldKey,
                            "scripts"
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    {fields.length !== 1 ? (
                      <MinusCircleOutlined
                        className="text-gray-300 hover:text-red-500 text-base"
                        style={{ marginTop: "45px", color: "#ccc" }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
              ))}
              <Form.Item noStyle>
                {fields.length < 5 ? (
                  <Button
                    type="dashed"
                    size="small"
                    className="flex items-center mt-5 text-xs"
                    onClick={() => {
                      add();
                    }}
                  >
                    <i className="material-icons text-base mr-1">add</i>
                    {t("automation.add_another_custom_script")}
                  </Button>
                ) : null}
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default CustomScript;
