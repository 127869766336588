import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import Moment from "moment";

import http from "@/utils/http-request";

const initialState = {
  filters: {
    search: "",
    datefrom: Moment().subtract(6, "month").startOf("day"),
    dateto: Moment().endOf("day"),
    trigger: "All",
    status: "All",
    currentPage: 1,
    pageSize: 10,
    pageSizes: [10, 20, 30, 40, 50],
  },
  loading: false,
  uploading: false,
  definitions: [],
  filteredDefinitions: [],
  allDefinitions: [],
  defaultFilters: {},
};

export const get = createAsyncThunk(
  "workflowDefinitions/get",
  async (payload, { dispatch }) => {
    const response = await http.v2.get("/automation/workflows/definitions");
    return response.data;
  }
);

export const update = createAsyncThunk(
  "workflowDefinitions/update",
  async (payload, { dispatch, rejectWithValue }) => {
    const { subAccountId, trigger, definition, status } = payload;
    const { id } = definition;

    try {
      await http.v2.put(`/automation/workflows/definitions/${id}`, {
        subAccountId,
        status,
        trigger,
        definition,
      });

      return { id, status };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const remove = createAsyncThunk(
  "workflowDefinitions/delete",
  async (payload, { dispatch, rejectWithValue }) => {
    const { id, version } = payload;
    try {
      const response = await http.v2.delete(
        `/automation/workflows/definitions/${id}`,
        {
          version,
        }
      );
      return id;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const create = createAsyncThunk(
  "workflowDefinitions/create",
  async (payload, { dispatch, rejectWithValue }) => {
    const { definition } = payload;
    const { name } = definition;

    try {
      const response = await http.v2.post(
        "/automation/workflows/definitions",
        payload
      );

      return { id: response?.data?.definition?.id || "", name };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const find = createAsyncThunk(
  "workflowDefinitions/find",
  async (payload, { dispatch, rejectWithValue }) => {
    const { id = "" } = payload;

    try {
      const response = await http.v2.get(
        `/automation/workflows/definitions/${id}`
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const definitionsSlice = createSlice({
  name: "workflowDefinitions",

  initialState,
  // reducers actions
  reducers: {
    executeFilter(state, action) {
      let defs = [];
      const allDefs = state.allDefinitions.slice(0);
      const {
        datefrom,
        dateto,
        trigger,
        status,
        search,
        pageSize,
        currentPage,
      } = state.filters;

      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize;

      defs = allDefs.filter((v) => {
        return (
          v.createdAt &&
          Moment(v.createdAt).isBetween(
            Moment(datefrom).startOf("day"),
            Moment(dateto).endOf("day")
          )
        );
      });

      if (search !== "") {
        defsWithDateRange = defsWithDateRange.filter((v) => {
          return (
            v && v.definition.name.toLowerCase().includes(search.toLowerCase())
          );
        });
      }

      let defsWithDateRange = defs.slice(0);

      if (status !== "All") {
        defsWithDateRange = defsWithDateRange.filter((v) => {
          return v.status === (status === "A" ? "enabled" : "disabled");
        });
      }

      if (trigger !== "All") {
        defsWithDateRange = defsWithDateRange.filter((v) => {
          return v.trigger === trigger;
        });
      }

      state.definitions = defsWithDateRange.sort(
        (def1, def2) =>
          new Date(def2.createdAt || "") - new Date(def1.createdAt || "")
      );

      state.filteredDefinitions = state.definitions.slice(start, end);
    },
    setFilter(state, action) {
      const {
        search,
        datefrom,
        dateto,
        trigger,
        status,
        currentPage,
        pageSize,
      } = action.payload;

      state.filters.search = search || "";

      if (datefrom && dateto) {
        state.filters.datefrom = datefrom;
        state.filters.dateto = dateto;
        state.filters.currentPage = 1;
      }

      if (trigger) {
        state.filters.trigger = trigger;
        state.filters.currentPage = 1;
      }

      if (status) {
        state.filters.status = status;
        state.filters.currentPage = 1;
      }

      if (currentPage) {
        state.filters.currentPage = currentPage;
      }

      if (pageSize) {
        state.filters.pageSize = pageSize;
      }
    },
  },
  extraReducers: {
    [get.pending]: (state, action) => {
      state.loading = true;
    },
    [get.fulfilled]: (state, action) => {
      const data = action.payload;

      state.allDefinitions = data;

      state.loading = false;
    },
    [get.rejected]: (state, action) => {
      state.loading = false;
    },

    [update.pending]: (state, action) => {
      state.loading = true;
    },
    [update.fulfilled]: (state, action) => {
      const { id, status } = action.payload;

      state.loading = false;
    },
    [update.rejected]: (state, action) => {
      state.loading = false;
    },

    [remove.pending]: (state, action) => {
      state.loading = true;
    },
    [remove.fulfilled]: (state, action) => {
      const { id, version } = action.payload;

      state.loading = false;
    },
    [remove.rejected]: (state, action) => {
      state.loading = false;
    },

    [create.pending]: (state, action) => {
      state.loading = true;
    },
    [create.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [create.rejected]: (state, action) => {
      state.loading = false;
    },

    [find.pending]: (state, action) => {
      state.loading = true;
    },
    [find.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [find.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { actions, reducer } = definitionsSlice;

export const { setFilter, executeFilter, setDefinitions } = actions;

export default reducer;
