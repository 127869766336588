import React, { useRef } from "react";
import { Form, Input } from "antd";

import { useTranslation, Trans } from "react-i18next";

export default function WeChat({
  onChangeFormValue,
  onDragOverInput,
  onDropInput,
  node,
  form,
}) {
  const inputRef = useRef(null);
  const { t } = useTranslation();

  return (
    <Form.Item
      name="weChatUserId"
      initialValue={node?.data?.user?.weChatUserId || form?.from || ""}
      label={
        <h3 class="text-gray-500 text-xs mb-1 font-bold">
          {t("column_labels.user_id")}
        </h3>
      }
      rules={[
        {
          required: true,
          message: t("validations.required", {
            value: t("column_labels.user_id"),
          }),
        },
      ]}
      className="p-5"
    >
      <Input
        ref={inputRef}
        placeholder={t("automation.enter_or_drag")}
        onChange={(e) => onChangeFormValue("weChatUserId", e.target.value)}
        onDragEnter={() => inputRef.current.focus()}
        onDragOver={onDragOverInput}
        onDrop={(e) => onDropInput(e, "weChatUserId")}
      />
    </Form.Item>
  );
}
