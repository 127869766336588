/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from "react";
import { Handle } from "react-flow-renderer";
import { useSelector } from "react-redux";
import "moment-duration-format";
import { useTranslation, Trans } from "react-i18next";

import nodes from "@/json/nodes";

import Tag from "@/components/tag";

const handlerTargetStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "-7px",
  bottom: "auto",
  transform: "translateX(-50%)",
};

const handlerSourceStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "auto",
  bottom: "-7px",
  transform: "translateX(-50%)",
};

const BranchNode = (node) => {
  const { elements } = useSelector((state) => state.flow);
  const { t } = useTranslation();

  const isValidConnection = (destinationNode) => {
    const currentNode = elements[node.id];
    const targetNode = elements[destinationNode];
    if (!targetNode || destinationNode === node.id) return;
    return nodes[currentNode.type].compatibleNode.includes(targetNode.type);
  };

  const isExistInSource = (source, sourceHandle) => {
    if (sourceHandle !== "default-handle") return false;
    return elements[source].target.includes(node.id);
  };

  // Set node as 'selected' if input is empty upon initialize so that this node will appear on the sidebar
  useEffect(() => {
    if (!Object.keys(elements[node.id]?.selectNextStep || {}).length) {
      node.data.handleShowNodeDetail(node);
    }
  }, [elements]);

  return (
    <div className="bg-white shadow rounded w-72 relative custom_node">
      <header className="text-base px-4 py-2 flex items-center font-bold text-gray-700 border-b border-gray-200 relative">
        {!Object.keys(elements[node.id]?.selectNextStep || {}).length ? (
          <Tag className="absolute -top-2 right-2 text-white bg-yellow-500">
            {t("app_labels.new").toUpperCase()}
          </Tag>
        ) : (
          ""
        )}
        <Handle
          type="target"
          position="top"
          style={handlerTargetStyle}
          isValidConnection={({ source, sourceHandle }) =>
            isValidConnection(source) && !isExistInSource(source, sourceHandle)
          }
        />
        <span
          role="presentation"
          onClick={() => node.data.handleShowNodeDetail(node)}
          class="w-full flex"
        >
          <span className="-mt-6 inline-flex justify-center items-center w-12 h-12 p-1 rounded bg-gradient-to-tr from-blue-500 to-indigo-500 mr-2">
            <i className="material-icons text-white text-2xl">alt_route</i>
          </span>
          {elements[node.id]?.inputs?.nameId || node?.data?.nameId ? (
            <span className="ml-1 block truncate text-gray-600 bg-gray-100 py-1 px-2 text-sm rounded-full font-bold text-center">
              {elements[node.id]?.inputs?.nameId || node?.data?.nameId}
            </span>
          ) : (
            <span class="text-sm">{t("automation.nodes.branch")}</span>
          )}
        </span>
      </header>
      <figure
        className="px-4 py-2 flex flex-col"
        role="presentation"
        onClick={() => node.data.handleShowNodeDetail(node)}
      >
        <figcaption className="text-gray-500 text-sm mb-2 text-center font-medium">
          {!Object.keys(node.data?.selectNextStep || {}).length
            ? t("automation.click_here_branch_create")
            : node.data?.inputs?.conditionName}
        </figcaption>

        <figcaption className="text-gray-500 text-xs mb-1 text-center">
          {node.data?.inputs?.condition && node.data?.inputs?.condition === 1 && (
            <div>
              <p class="mb-2">
                {t("column_labels.destinations").split("|")[0]}
              </p>
              <span class="truncate text-blue-600 bg-blue-100 py-1 px-2 text-xs rounded-full font-bold ml-1">
                {node.data?.inputs?.mobile}
              </span>
            </div>
          )}

          {node.data?.inputs?.condition && node.data?.inputs?.condition === 2 && (
            <div class="truncate">
              <p class="mb-2">{t("automation.value_to_check")}</p>
              <span class="truncate text-blue-600 bg-blue-100 py-1 px-2 text-xs rounded-full font-bold ml-1">
                {node.data?.inputs?.stringValue}
              </span>
            </div>
          )}
        </figcaption>
      </figure>
      {/* <footer className="relative"> */}
      {/* <div className="border-t border-gray-200 font-medium text-blue-500 text-sm text-center px-4 py-3 ">
          {
            nodes[elements[node?.data?.target[0]]?.type || "trigger"]
              ?.handlerLabel
          }
        </div> */}
      <Handle
        isValidConnection={({ target }) => isValidConnection(target)}
        type="source"
        position="bottom"
        style={handlerSourceStyle}
      />
      {/* </footer> */}
    </div>
  );
};

export default BranchNode;
