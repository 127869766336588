import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import Moment from "moment";
import DOMPurify from "dompurify";
import { useTranslation, Trans } from "react-i18next";

import {
  Space,
  Input,
  List,
  Modal,
  Switch,
  Tooltip,
  Button,
  Dropdown,
  Table,
  Menu,
} from "antd";
const { Search } = Input;
import { LoadingOutlined } from "@ant-design/icons";

export default function Definitions(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    filteredDefinitions,
    loading,
    update,
    remove,
    redirect,
    setFilter,
    download,
    filters,
  } = props;

  const highlightSearch = (str, search) => {
    const name = str;

    if (search && str.toLowerCase().includes(search.toLowerCase())) {
      return DOMPurify.sanitize(
        name.replace(
          new RegExp(search, "gi"),
          (match) => `<mark>${match}</mark>`
        )
      );
    }

    return name;
  };

  const triggers = {
    inbound_sms: {
      label: t("sidebar_menu_parent.SMS"),
      icon: "icon-novo-sms",
    },
    http_request: {
      label: t("sidebar_menu.6"),
      icon: "icon-novo-api",
    },
    inbound_chat_apps: {
      label: t("sidebar_menu_parent.Chat apps"),
      icon: "icon-novo-message",
    },
  };

  const handleActionMenuClick = (ev, record) => {
    if (ev.key === "1") {
      Modal.confirm({
        title: t("automation.delete_automation"),
        content: (
          <div className="text-sm">
            {t("confirmations.delete")}{" "}
            <i className="font-medium">{record.definition.name}</i>?
          </div>
        ),
        okText: t("actions.yes"),
        cancelText: t("actions.cancel"),
        onOk: () => {
          return Promise.resolve().then(() => {
            remove(record);
          });
        },
      });
    }
  };

  const actionsMenu = (record) => (
    <Menu
      onClick={(ev) => {
        handleActionMenuClick(ev, record);
      }}
    >
      <Menu.Item key="1">
        <span role="button" tabIndex={0}>
          <i class="icon-novo-trash mr-1" /> {t("actions.delete")}
        </span>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: t("column_labels.name"),
      dataIndex: "definition.name",
      render: (text, record) => (
        <span
          class="truncate"
          dangerouslySetInnerHTML={{
            __html: highlightSearch(record.definition.name, filters.search),
          }}
        />
      ),
    },
    {
      title: (
        <span class="capitalize">
          {t("automation.trigger").split("|")[0].toLowerCase()}
        </span>
      ),
      width: 180,
      dataIndex: "trigger",
      render: (text, record) => (
        <div>
          <i
            class={`bg-grey-lightest rounded-full p-1 ${
              triggers[record?.trigger].icon
            }`}
          />

          <span class="ml-2">{triggers[record?.trigger].label}</span>
        </div>
      ),
    },
    {
      title: t("column_labels.status"),
      dataIndex: "status",
      render: (text, record) => (
        <div>
          {record?.status === "enabled" ? (
            <i class="bg-grey-lightest rounded-full p-1 icon-novo-check" />
          ) : (
            "-"
          )}
        </div>
      ),
    },

    {
      title: <span class="capitalize">{t("automation.def_id")}</span>,
      dataIndex: "definition.id",
      render: (text, record) => (
        <div>
          <Tooltip placement="top" title={record?.definition?.id}>
            <code className="w-full text-xs text-green-500 truncate overflow-hidden block font-normal">
              {record?.definition?.id}
            </code>
          </Tooltip>
        </div>
      ),
    },

    {
      title: <span class="capitalize">{t("column_labels.version")}</span>,
      render: (text, record) => (
        <span class="bg-grey-lighter rounded-full px-2 py-1 items-center justify-center text-xs">
          V{record?.definition?.version}{" "}
        </span>
      ),
    },

    {
      title: t("column_labels.created_on"),
      width: 150,
      dataIndex: "createdAt",
      render: (text, record) => (
        <time
          datetime={Moment(record?.createdAt).format("D MMM, hh:mm A")}
          className="text-xs font-normal"
        >
          {Moment(record?.createdAt).format("MMM D @ hh:mm A")} UTC
        </time>
      ),
    },

    {
      title: "",
      width: 200,
      render: (text, record) => (
        <div style={{ width: "180px" }}>
          <div class={`action-${record?.definition?.id} hidden`}>
            <div class="justify-between flex">
              <div>
                <Tooltip
                  placement="top"
                  title={`${t("actions.edit")} ${t("automation.workflow")}`}
                >
                  <span>
                    <i
                      class="icon-novo-pencil cursor-pointer text-xl"
                      role="button"
                      tabindex={0}
                      onClick={() => {
                        redirect(record);
                      }}
                    />
                  </span>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  placement="top"
                  title={`${
                    record.status === "disabled"
                      ? t("actions.enable")
                      : t("actions.disable")
                  } ${t("automation.workflow")}`}
                >
                  <span className="relative">
                    <i
                      class={`${
                        record.status === "enabled"
                          ? "icon-novo-not-allowed"
                          : "icon-novo-check"
                      } cursor-pointer text-xl inline-block relative`}
                      role="button"
                      tabindex={0}
                      onClick={() => {
                        const newRecord = { ...record };
                        if (record.status === "disabled") {
                          newRecord.status = "enabled";
                        } else {
                          newRecord.status = "disabled";
                        }

                        update(newRecord);
                      }}
                    />
                  </span>
                </Tooltip>
              </div>
              <div>
                <Tooltip placement="top" title={t("actions.download")}>
                  <span className="relative">
                    <i
                      class="icon-novo-download cursor-pointer text-xl inline-block relative"
                      role="button"
                      tabindex={0}
                      onClick={() => {
                        download(record);
                      }}
                    />
                  </span>
                </Tooltip>
              </div>
              <div>
                <Dropdown overlay={actionsMenu(record)} trigger="click">
                  <div>
                    <i
                      class="icon-novo-dots-vertical cursor-pointer text-2xl"
                      role="button"
                      tabindex={0}
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const locale = {
    emptyText: !loading ? (
      <div class="flex items-center justify-center flex-col p-10">
        <div class="mt-10 mb-2">
          <svg
            width="150"
            height="164"
            viewBox="0 0 150 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3193_70326)">
              <path
                d="M58.0251 163.798C90.0714 163.798 116.05 159.014 116.05 153.113C116.05 147.213 90.0714 142.429 58.0251 142.429C25.9787 142.429 0 147.213 0 153.113C0 159.014 25.9787 163.798 58.0251 163.798Z"
                fill="#3F3D56"
              />
              <path
                d="M93.2915 118.414C124.611 118.414 150 91.9517 150 59.3084C150 26.6651 124.611 0.202515 93.2915 0.202515C61.9723 0.202515 36.583 26.6651 36.583 59.3084C36.583 91.9517 61.9723 118.414 93.2915 118.414Z"
                fill="#3F3D56"
              />
              <path
                opacity="0.05"
                d="M93.2915 108.102C119.146 108.102 140.106 86.2565 140.106 59.3084C140.106 32.3603 119.146 10.5146 93.2915 10.5146C67.4364 10.5146 46.4768 32.3603 46.4768 59.3084C46.4768 86.2565 67.4364 108.102 93.2915 108.102Z"
                fill="black"
              />
              <path
                opacity="0.05"
                d="M93.2916 99.2992C114.482 99.2992 131.66 81.3947 131.66 59.3084C131.66 37.2221 114.482 19.3176 93.2916 19.3176C72.1011 19.3176 54.9229 37.2221 54.9229 59.3084C54.9229 81.3947 72.1011 99.2992 93.2916 99.2992Z"
                fill="black"
              />
              <path
                opacity="0.05"
                d="M93.2916 87.9811C108.485 87.9811 120.801 75.1439 120.801 59.3084C120.801 43.473 108.485 30.6358 93.2916 30.6358C78.0985 30.6358 65.782 43.473 65.782 59.3084C65.782 75.1439 78.0985 87.9811 93.2916 87.9811Z"
                fill="black"
              />
              <path
                d="M37.0854 64.598C37.0854 64.598 32.627 77.6782 34.6085 82.3251C36.59 86.972 39.7274 91.4468 39.7274 91.4468C39.7274 91.4468 38.5715 65.4586 37.0854 64.598Z"
                fill="#D0CDE1"
              />
              <path
                opacity="0.1"
                d="M37.0854 64.598C37.0854 64.598 32.627 77.6782 34.6085 82.3251C36.59 86.972 39.7274 91.4468 39.7274 91.4468C39.7274 91.4468 38.5715 65.4586 37.0854 64.598Z"
                fill="black"
              />
              <path
                d="M40.2227 94.889C40.2227 94.889 39.8925 98.159 39.7273 98.3311C39.5622 98.5032 39.8925 98.8474 39.7273 99.3638C39.5622 99.8801 39.3971 100.569 39.7273 100.741C40.0576 100.913 37.9109 116.058 37.9109 116.058C37.9109 116.058 32.6269 123.287 34.7735 134.646L35.434 146.177C35.434 146.177 40.553 146.521 40.553 144.628C40.553 144.628 40.2227 142.391 40.2227 141.358C40.2227 140.325 41.0483 140.325 40.553 139.809C40.0576 139.293 40.0576 138.949 40.0576 138.949C40.0576 138.949 40.8832 138.26 40.7181 138.088C40.553 137.916 42.2042 125.696 42.2042 125.696C42.2042 125.696 44.0206 123.803 44.0206 122.77V121.738C44.0206 121.738 44.8463 119.5 44.8463 119.328C44.8463 119.156 49.3047 108.658 49.3047 108.658L51.1211 116.23L53.1026 127.073C53.1026 127.073 54.0934 136.883 56.0749 140.67C56.0749 140.67 59.5425 153.061 59.5425 152.717C59.5425 152.373 65.322 151.512 65.1568 149.963C64.9917 148.414 61.6892 126.729 61.6892 126.729L62.5148 94.5448L40.2227 94.889Z"
                fill="#2F2E41"
              />
              <path
                d="M35.7642 145.317C35.7642 145.317 31.3058 154.438 34.2781 154.782C37.2504 155.127 38.4062 155.127 39.7273 153.75C40.4495 152.997 41.9119 151.987 43.0894 151.226C43.7871 150.783 44.3508 150.143 44.7157 149.381C45.0806 148.619 45.232 147.765 45.1526 146.917C45.0655 146.075 44.7636 145.381 44.0205 145.317C42.039 145.144 39.7273 143.251 39.7273 143.251L35.7642 145.317Z"
                fill="#2F2E41"
              />
              <path
                d="M60.3682 152.029C60.3682 152.029 55.9098 161.15 58.8821 161.495C61.8544 161.839 63.0102 161.839 64.3313 160.462C65.0535 159.709 66.516 158.699 67.6935 157.938C68.3912 157.495 68.9548 156.855 69.3197 156.093C69.6846 155.331 69.836 154.477 69.7566 153.629C69.6695 152.787 69.3676 152.093 68.6245 152.029C66.643 151.857 64.3313 149.963 64.3313 149.963L60.3682 152.029Z"
                fill="#2F2E41"
              />
              <path
                d="M55.6561 49.6706C59.4897 49.6706 62.5975 46.4315 62.5975 42.4358C62.5975 38.4402 59.4897 35.201 55.6561 35.201C51.8226 35.201 48.7148 38.4402 48.7148 42.4358C48.7148 46.4315 51.8226 49.6706 55.6561 49.6706Z"
                fill="#FFB8B8"
              />
              <path
                d="M51.1939 44.8129C51.1939 44.8129 46.2359 54.3215 45.8392 54.3215C45.4426 54.3215 54.7638 57.4221 54.7638 57.4221C54.7638 57.4221 57.3419 48.327 57.7386 47.5001L51.1939 44.8129Z"
                fill="#FFB8B8"
              />
              <path
                d="M58.7994 55.2181C58.7994 55.2181 48.8918 49.5386 47.901 49.7107C46.9103 49.8828 36.3422 59.5208 36.5073 63.4793C36.6724 67.4378 37.9934 73.9779 37.9934 73.9779C37.9934 73.9779 38.4888 92.2213 39.4796 92.3934C40.4703 92.5655 39.3144 95.6635 39.6447 95.6635C39.9749 95.6635 62.7624 95.6635 62.9276 95.1472C63.0927 94.6308 58.7994 55.2181 58.7994 55.2181Z"
                fill="#D0CDE1"
              />
              <path
                d="M64.3313 96.0937C64.3313 96.0937 67.4688 106.076 64.8267 105.732C62.1847 105.388 61.0288 97.1264 61.0288 97.1264L64.3313 96.0937Z"
                fill="#FFB8B8"
              />
              <path
                d="M55.9097 54.6158C55.9097 54.6158 49.8 55.9926 50.7908 64.598C51.7816 73.2034 53.598 81.8088 53.598 81.8088L59.7076 95.7495L60.3681 98.3311L64.8266 97.1264L61.524 77.1619C61.524 77.1619 60.3681 55.8205 58.882 55.1321C57.9449 54.7157 56.9258 54.5387 55.9097 54.6158Z"
                fill="#D0CDE1"
              />
              <path
                opacity="0.1"
                d="M52.1943 81.5507L59.7903 95.6634L53.3906 80.7924L52.1943 81.5507Z"
                fill="black"
              />
              <path
                d="M62.5666 40.3055L62.5895 39.7527L63.6447 40.0264C63.6334 39.8489 63.5853 39.6761 63.5036 39.5199C63.422 39.3637 63.3088 39.2278 63.1719 39.1216L64.2959 39.0561C63.3527 37.6592 62.1477 36.476 60.7536 35.578C59.3595 34.68 57.8052 34.0859 56.1847 33.8314C53.7535 33.4641 51.0464 33.9956 49.3793 35.8762C48.5706 36.7884 48.0625 37.9484 47.7011 39.1318C47.0354 41.3114 46.8998 43.9097 48.2879 45.6814C49.6986 47.482 52.1628 47.8347 54.383 48.0576C55.1642 48.136 55.9829 48.209 56.7068 47.893C56.7873 47.0276 56.681 46.1544 56.3957 45.3373C56.2768 45.0844 56.2201 44.8047 56.2304 44.5233C56.3291 43.8348 57.2101 43.6614 57.8719 43.7544C58.5338 43.8474 59.3296 43.9897 59.7645 43.4615C60.064 43.0976 60.0464 42.5677 60.0861 42.09C60.1941 40.7896 62.5552 40.5781 62.5666 40.3055Z"
                fill="#2F2E41"
              />
              <path
                d="M105.141 154.584C109.608 154.584 113.229 150.81 113.229 146.154C113.229 141.498 109.608 137.724 105.141 137.724C100.674 137.724 97.0532 141.498 97.0532 146.154C97.0532 150.81 100.674 154.584 105.141 154.584Z"
                fill="#CB2233"
              />
              <path
                d="M10.1566 151.643C14.6234 151.643 18.2444 147.869 18.2444 143.213C18.2444 138.558 14.6234 134.784 10.1566 134.784C5.68987 134.784 2.06885 138.558 2.06885 143.213C2.06885 147.869 5.68987 151.643 10.1566 151.643Z"
                fill="#CB2233"
              />
              <path
                d="M10.1566 138.116C13.3769 138.116 15.9874 135.395 15.9874 132.039C15.9874 128.683 13.3769 125.962 10.1566 125.962C6.93643 125.962 4.32593 128.683 4.32593 132.039C4.32593 135.395 6.93643 138.116 10.1566 138.116Z"
                fill="#CB2233"
              />
              <path
                d="M10.1567 126.942C12.442 126.942 14.2947 125.011 14.2947 122.629C14.2947 120.247 12.442 118.316 10.1567 118.316C7.87141 118.316 6.0188 120.247 6.0188 122.629C6.0188 125.011 7.87141 126.942 10.1567 126.942Z"
                fill="#CB2233"
              />
            </g>
            <defs>
              <clipPath id="clip0_3193_70326">
                <rect
                  width="150"
                  height="163.595"
                  fill="white"
                  transform="translate(0 0.202515)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <h4 class="mb-1">{t("app_labels.no_data")}</h4>
        <span
          role="button"
          tabindex={0}
          class="block text-blue hover:text-blue-lightest"
          onClick={() => {
            history.push("/builder?action=create");
          }}
        >
          {t("app_labels.create_new_item", { item: t("automation.workflow") })}
        </span>
      </div>
    ) : null,
  };

  const list = (
    <Table
      locale={locale}
      dataSource={filteredDefinitions}
      size="small"
      rowKey={(record) => record?.definiiton?.id}
      columns={columns}
      pagination={false}
      loading={{
        indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
        spinning: loading,
      }}
      onRow={(record, rowIndex) => {
        return {
          onMouseOver: (event) => {
            document
              .querySelector(`.action-${record?.definition.id}`)
              .classList.remove("hidden");
            document
              .querySelector(`.action-${record?.definition.id}`)
              .classList.add("block");
          }, // mouse enter row
          onMouseLeave: (event) => {
            document
              .querySelector(`.action-${record?.definition.id}`)
              .classList.remove("block");
            document
              .querySelector(`.action-${record?.definition.id}`)
              .classList.add("hidden");
          }, // mouse leave row
        };
      }}
    />
  );

  return (
    <div className="relative">
      <div className="w-full">{list}</div>
    </div>
  );
}
