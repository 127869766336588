import { Form, Input, Button, Select, Row, Col, Switch } from "antd";

import { MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

import { useTranslation, Trans } from "react-i18next";

const StringContains = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name="stringValue"
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("automation.value_to_check")}
          </h3>
        }
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.value"),
            }),
          },
        ]}
      >
        <Input
          ref={props.setRef("str")}
          placeholder={t("automation.enter_a_valid_or_drag", {
            value: t("column_labels.value").toLowerCase(),
          })}
          onDragEnter={() => props.getRef("str").current.focus()}
          onDragOver={props.onDragOverInput}
          onDrop={(e) => props.onDropInput(e, "stringValue")}
        />
      </Form.Item>
      <div class="text-right">
        <Form.Item
          name="isCaseSensitive"
          valuePropName="checked"
          className="text-right"
          noStyle
        >
          <Switch size="small" />
        </Form.Item>
        <span class="ml-2">{t("automation.case_sensitive")}</span>
      </div>
      <Form.List name="keywords">
        {(fields, { add, remove }) => {
          /**
           * `fields` internal fill with `name`, `key`, `fieldKey` props.
           * You can extends this into sub field to support multiple dynamic fields.
           */
          return (
            <div>
              <h3 class="text-gray-500 text-xs mb-2 font-bold">
                {t("automation.to_compare", {
                  item: t("column_labels.keywords").toLowerCase(),
                })}
              </h3>
              {fields.map((field, index) => (
                <Row
                  key={field.key}
                  gutter={10}
                  className="bg-gray-50 px-5 pt-3"
                >
                  <Col span={22}>
                    <Form.Item
                      name={[field.name, "keyword"]}
                      fieldKey={[field.fieldKey, "keyword"]}
                      rules={[
                        {
                          required: true,
                          message: t("validations.required", {
                            value: t("column_labels.keywords"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("validations.enter_value")}
                        className="w-full text-xs px-0 py-2"
                        bordered={false}
                        style={{
                          borderBottom: "1px solid #D1D5DB",
                          borderRadius: 0,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    {fields.length !== 1 ? (
                      <MinusCircleOutlined
                        className="text-gray-300 hover:text-red-500 text-base"
                        style={{ marginTop: "17px", color: "#ccc" }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
              ))}
              <Form.Item noStyle>
                {fields.length < 5 ? (
                  <Button
                    type="dashed"
                    size="small"
                    className="flex items-center mt-5 text-xs"
                    onClick={() => {
                      add();
                    }}
                  >
                    <i className="material-icons text-base mr-1">add</i>
                    {t("automation.add_new_keyword")}
                  </Button>
                ) : null}
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default StringContains;
