import React from "react";
import { Modal, Button } from "antd";
import { Link } from "react-router-dom";

import triggers from "@/json/triggers.json";
import { getTemplates } from "@/utils/get-templates";
import { useTranslation, Trans } from "react-i18next";

import handlePackageLimit from "@/utils/handlePackageLimit";

const templates = getTemplates();

const PreWorkflowForm = ({ visible, handleForward, handleCancel }) => {
  const { t } = useTranslation();

  const hasInboundEntitlement = handlePackageLimit(
    "feature-message-type-inbound"
  );

  return (
    <Modal
      visible={visible}
      title={t("automation.create_workflow")}
      keyboard={false}
      closable={false}
      bodyStyle={{ padding: 0 }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t("actions.back")}
        </Button>,
      ]}
    >
      <div class="overflow-y-auto" style={{ height: "400px" }}>
        <ul class="grid grid-cols-3 gap-4 overflow-y-auto p-5">
          <li>
            <a
              href="/"
              class="transform hover:-translate-y-1 transition-all ease-in duration-75 cursor-pointer block bg-white shadow border border-gray-100 rounded text-black overflow-hidden flex flex-col items-center justify-center relative cursor-pointer block"
              style={{ height: "150px" }}
              onClick={(ev) => {
                ev.preventDefault();
                handleForward();
              }}
            >
              <span class="material-icons-outlined font-semibold text-4xl">
                add
              </span>
              <h4 class="mt-2 text-xs">{t("actions.add")}</h4>
            </a>
          </li>
          {(templates || []).map((v, i) => {
            if (
              !hasInboundEntitlement &&
              !v?.definition?.name.match(/Opt-Out/i)
            )
              return null;
            return (
              <li key={`template-${i}`}>
                <Link
                  class="transform hover:-translate-y-1 transition-all ease-in duration-75 cursor-pointer block bg-white shadow border border-gray-100 rounded text-black overflow-hidden flex items-center justify-center relative"
                  style={{ height: "150px" }}
                  to={{
                    pathname: "/builder",
                    search: `?template_id=${v.templateId}`,
                    reloadDocument: true,
                  }}
                >
                  <i
                    className="material-icons-outlined text-gray-100 absolute select-none"
                    style={{
                      bottom: "49%",
                      right: "-32%",
                      fontSize: "100px",
                      zIndex: 0,
                    }}
                  >
                    {triggers[v.trigger].icon}
                  </i>
                  <div className="z-10 relative flex items-center justify-center flex-col">
                    <i className="material-icons-outlined text-3xl text-8x8-red">
                      {triggers[v.trigger].icon}
                    </i>
                    <h4
                      class="text-xs mt-2 uppercase text-gray-900 text-center break-words"
                      style={{ maxWidth: "100px" }}
                    >
                      {v?.definition?.name}
                    </h4>
                    <p class="text-xs mt-2 text-gray-500 text-center">
                      {t(triggers[v.trigger].labelts)}
                    </p>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </Modal>
  );
};

export default PreWorkflowForm;
