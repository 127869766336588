/* eslint-disable no-undef */
import React, { useState, useEffect, useReducer, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Input, Select, Tooltip, Popconfirm, Modal } from "antd";
const { Option } = Select;
const { TextArea } = Input;
import { useTranslation, Trans } from "react-i18next";
// const { confirm } = Modal;

// import { FlattenObject as flattenObject } from "@/utils/FlattenObject";
import { getChannelIconByCode } from "@/utils/channel-icons";
import { DecodeJwt as decodeJwt } from "@/utils/jwt";

import ViberForm from "./viber";
import WhatsAppForm from "./whatsApp";
import CommonSnsForm from "./commonSns";
import Variables from "@/components/variables";
import channels from "@/json/channels";

import { get as getTemplates, getEnums } from "@/store/reducers/templates";
import { getUploadUrl, request } from "@/store/reducers/commons";
import { getHttpConfig } from "../../../../utils/http-config";

const snsObject = {
  WC: { destination: "weChatUserId" },
  FB: { destination: "facebookUserId" },
  ZL: { destination: "zaloUserId" },
  KK: { destination: "kakaoId" },
};

const ACTIONS = {
  INITIALIZE_FORM: "initialize-form",
  UPDATE_VALUE: "update-value",
};

function reducer(formData, action) {
  const { item, value } = action.payload;
  switch (action.type) {
    case ACTIONS.INITIALIZE_FORM:
      return { ...action.payload };
    case ACTIONS.UPDATE_VALUE:
      return { ...formData, [item]: value };
    default:
      return formData;
  }
}

const ChatAppForm = ({
  node,
  subaccountOptions,
  getSubaccounts,
  onUpdateNodeValue,
  sidebarScrollWrapper,
  onDeleteNode,
  loading,
  stepNameList,
  onUpdateStepNameList,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { elements } = useSelector((state) => state.flow);
  const { t } = useTranslation();

  const outputDataInfo = {
    requestId: t("automation.output_info.requestId"),
    umid: t("automation.output_info.umid"),
    status: t("automation.output_info.status"),
    description: t("automation.output_info.description"),
  };

  const sourceInputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const textInputRef = useRef(null);

  const [toggleExpandOutput, setToggleExpandOutput] = useState(false);
  const [formData, dispatchForm] = useReducer(reducer, {});

  // Set 'formData' to the values of 'form instance' on mount
  useEffect(() => {
    getSubaccounts();

    dispatchForm({
      type: ACTIONS.INITIALIZE_FORM,
      payload: { ...form.getFieldsValue(false) },
    });

    // // Populate map node with default form items
    // if (!Object.keys(elements[node.id]?.inputs || {}).length) {
    //   const inputs = form.getFieldsValue();
    //   onUpdateNodeValue({ id: node.id, inputs }, true);
    // }

    // Set scrollTop to 0
    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Re-render fields if selectedNode is updated but has same type with previous selectedNode
  useEffect(() => {
    form.resetFields();

    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [node]);

  const onChangeFormValue = (item, value) => {
    dispatchForm({
      type: ACTIONS.UPDATE_VALUE,
      payload: { item, value },
    });
  };

  const onSubmitForm = (values) => {
    form.validateFields().then((values) => {
      // Update store node data
      const { id } = node;
      const newData = { ...elements[id] };
      // Get `user object` based on SNS type (msisdn, weChatUser,Id, facebookUserId, zaloUserId, kakaoId)
      // Default is `msisdn` if sns is not listed in `snsObject`
      const snsObjectByCode =
        snsObject[subaccountChannel?.ChannelType]?.destination || "msisdn";

      const {
        text,
        url,
        parameters,
        templateName,
        language,
        buttons,
        buttonText,
        buttonUrl,
        ...newValues
      } = values;

      // Remove Destination or MSISDN from newValues
      delete newValues[snsObjectByCode];

      if (values.type !== "template") {
        // Add text
        if (text) {
          newValues["content"] = { ...newValues["content"], text };
        }

        // Add image url
        if (url) {
          newValues["content"] = { ...newValues["content"], url };
        }

        // Viber rich media button
        if (buttonText && buttonUrl) {
          const button = {
            caption: buttonText,
            action: buttonUrl,
          };

          newValues["content"] = { ...newValues["content"], button };
        }
      } else {
        // Body component
        let components = [];

        const body = {
          type: "body",
          parameters: [],
        };

        body.parameters = (parameters || []).reduce((acc, curr) => {
          if (curr) {
            const newParameter = {
              type: "text",
              text: curr,
            };
            acc.push(newParameter);

            return acc;
          }
        }, []);

        if (body.parameters.length) {
          components.push({ ...body });
        }

        // Button component
        const buttonComponent = (selectedTemplate?.components || []).find(
          (component) => component.type === "BUTTONS"
        );

        let newButtons = [];

        if (buttonComponent?.buttons) {
          newButtons = buttonComponent.buttons.map((button, i) => {
            let subType = "";

            switch (button?.type) {
              case "URL":
                subType = "Url";
                break;
              case "QUICK_REPLY":
                subType = "QuickReply";
                break;
              case "PHONE_NUMBER":
                subType = "PhoneNumber";
                break;
              default:
                break;
            }

            // Get dynamic button value first and use default button text as fallback if button is not editable
            const currentButtonText = (buttons || []).length
              ? buttons[i]
              : button?.text;

            return {
              type: "button",
              parameters: [
                {
                  type: "payload",
                  payload: `${currentButtonText}-Button-Payload`,
                  text: currentButtonText || "",
                },
              ],
              index: i,
              subType,
            };
          });
        }

        components = [...components, ...newButtons];

        const template = {
          name: templateName,
          components,
          language,
        };

        // console.log("template", template);
        newValues["content"] = { template, text };
      }

      // Remove text from payload
      if (text && templateName) {
        delete newValues.content.text;
      }

      newValues["user"] = { [snsObjectByCode]: values[snsObjectByCode] };

      let outputs = node?.data?.outputs || {};

      // outputs = {
      //   // ...outputs,
      //   // // [`${values?.nameId}_content_text`]: "{{data.payload.content.text}}",
      //   // [`${values?.nameId}_user_channelUserId`]:
      //   //   newValues[snsObjectByCode] || "{{data.payload.user.channelUserId}}",
      //   // [`${values?.nameId}_step_requestId`]: "{{step.requestId}}",
      //   // [`${values?.nameId}_step_umid`]: "{{step.umid}}",
      //   // [`${values?.nameId}_step_status`]: "{{step.status}}",
      //   // [`${values?.nameId}_step_description`]: "{{step.description}}",
      // };

      onUpdateNodeValue({
        id,
        inputs: { ...newData.inputs, ...newValues },
        outputs,
      });

      // Update the master list of step names
      let nameList = [...stepNameList];
      const arrayIndex = nameList.indexOf(node?.data?.nameId || id);
      nameList.splice(arrayIndex, 1, values?.nameId);
      onUpdateStepNameList(nameList);
    });
  };

  const onSubmitFormFail = (values) => {
    // console.log(values);
  };

  const onConfirmDelete = () => {
    onDeleteNode(node.id || "");
  };

  const onDragStartTag = (e) => {
    e.dataTransfer.setData("variable", e.target.dataset.value);
  };

  const onDragOverInput = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDropInput = (e, fieldItem) => {
    e.preventDefault();
    const variable = e.dataTransfer.getData("variable");
    const { value, selectionEnd, selectionStart } = e.target;
    const newValue =
      value.slice(0, selectionStart) + variable + value.slice(selectionEnd);

    // Update values
    form.setFieldsValue({ [fieldItem]: newValue });
    onChangeFormValue(fieldItem, newValue);
  };

  const [variables, setVariables] = useState({});

  // Get all outputs from parent nodes and set it as `variables`
  useEffect(() => {
    setVariables({ currId: node.id, elements, currOutputs: formData.outputs });
  }, [node, elements, formData.outputs]);

  const [subaccountChannel, setSubaccountChannel] = useState([]);
  const [selectedSubaccountId, setSelectedSubaccountId] = useState();
  const [selectedSubaccount, setSelectedSubaccount] = useState();

  const { templates, enums, templatesLoading, enumsLoading } = useSelector(
    (state) => state.templates
  );

  // Get channel from subaccount fallback config
  useEffect(() => {
    // Skip if subaccount options array is empty
    if (!subaccountOptions || !(subaccountOptions || []).length) return;

    // Set target subaccountId  to node's subaccount if selectedSubaccountId is undefined
    let newSubAccountId = node?.data?.subAccountId;
    if (selectedSubaccountId) {
      newSubAccountId = form.getFieldsValue(false)?.subAccountId;
    }

    if (!newSubAccountId) {
      newSubAccountId = elements["trigger"]?.data.subAccountId || "";
    }

    // Find the subaccount in the subaccount list by id
    const subAcct = subaccountOptions.find(
      (subaccount) => subaccount?.SubAccountId === newSubAccountId
    );
    setSelectedSubaccount(subAcct);

    // Extract channel using the first item in fallback config array
    const channel =
      subAcct && (subAcct?.FallbackConfig || []).length
        ? subAcct?.FallbackConfig[0]
        : {};
    setSubaccountChannel(channel);

    // Set the form subaccountId field with the new value
    form.setFieldsValue({
      subAccountId: channel?.SubAccountId,
    });

    // Set the form buttons field with the new value if RichMedia
    const content = node?.data?.content;
    if (
      Object.keys(content?.button || {}).length &&
      content?.text &&
      content?.url
    ) {
      const { caption: text, action: url } = content?.button;

      form.setFieldsValue({
        buttonUrl: url || "",
        buttonText: text || "",
      });
    }
  }, [node, subaccountOptions, selectedSubaccountId]);

  // Initialize formData once selectedSubaccount has a new value
  useEffect(() => {
    dispatchForm({
      type: ACTIONS.INITIALIZE_FORM,
      payload: { ...form.getFieldsValue(false) },
    });
  }, [selectedSubaccount]);

  const onUploadFile = async (file) => {
    const { name, type } = file;

    try {
      const { payload } = await dispatch(getUploadUrl({ file }));
      const { uploadUrl, dbUrl, mimeType } = payload;

      const storageOrigin = uploadUrl ? uploadUrl.split("/")[2] : ""; // If from S3 or Google Cloud Storage
      const headers =
        storageOrigin === "storage.googleapis.com"
          ? {
              "Content-Type": "application/octet-stream",
            }
          : {
              "Content-Type": mimeType,
              "Content-Disposition": `attachment;filename="${name}"`,
            };

      const uploadReq = {
        method: "PUT",
        url: uploadUrl,
        data: file,
        headers,
      };

      await dispatch(request(uploadReq));

      // Decode jwt and determine region based on RegionId
      const token = localStorage.getItem("WWW-Authenticate");
      const decodedData = decodeJwt(token);
      const httpConfig = getHttpConfig();
      const { apiConfig } = httpConfig;
      let regionId = "";
      let rootUrl = "";

      if (decodedData && Object.keys(decodedData).includes("RegionId")) {
        const { RegionId: region } = decodedData;
        regionId = region;
      }

      if (process.env.TARGET === "development") {
        rootUrl = apiConfig['v2'][regionId]
      } else {
        const envVar = apiConfig['v2'][regionId];
        rootUrl = regionId ? envVar : window.location.origin;
      }

      const fileUrl = `${rootUrl}${dbUrl}?regionId=${regionId}`;

      return fileUrl;
    } catch (err) {
      throw new Error(err);
    }
  };

  //Set seleted template
  const [selectedTemplate, setSelectedTemplate] = useState();

  return (
    <>
      <header className="bg-gradient-to-tr from-blue-500 to-indigo-500 px-5 pt-7 pb-14 w-96">
        <small className="text-xs text-red-200 tracking-widest">
          {t("automation.chat_apps_configuration")}
        </small>
        <h1 className="font-bold text-lg text-white tracking-wider pr-20 leading-snug">
          {t("automation.send_messages_to")}.
        </h1>
      </header>
      <section className="bg-gray-100 p-5 w-96">
        <div
          className="bg-white flex flex-col rounded shadow -mt-14"
          style={{ height: toggleExpandOutput ? "auto" : "150px" }}
        >
          <div className="overflow-hidden py-3 px-5 flex flex-col">
            <h2 className="font-bold text-sm text-gray-900 flex items-center">
              <span className="text-white bg-8x8-red-500 w-8 h-8 flex justify-center items-center rounded mr-2 shadow">
                <i className="material-icons text-xl text-black">data_object</i>
              </span>
              {t("automation.expected_output")}
            </h2>
            <ul className="list-none mt-3 space-y-2">
              {Object.keys(outputDataInfo).map((info) => (
                <li
                  className="leading-loose text-sm text-gray-800"
                  key={`info-${info}`}
                >
                  <code className="font-mono text-xs p-1 mr-1 bg-yellow-100 text-yellow-800 rounded-sm inline-block">
                    {info}
                  </code>{" "}
                  - {outputDataInfo[info]}
                </li>
              ))}
            </ul>
            <a
              className="mt-5 text-xs text-blue-500 inline-block ml-auto"
              target="_blank"
              href="https://developer.8x8.com/connect/reference/getting-started-with-chatapps-api"
            >
              {t("automation.chat_apps_documentation")}
            </a>
          </div>
          <div className="-mb-3 flex justify-center relative">
            <div className="-mt-3 p-2 w-full bg-opacity-50 absolute bottom-6 rounded-b bg-gradient-to-t from-white via-white to-transparent" />
            <Button
              type="dashed"
              size="small"
              shape="round"
              className="text-xs"
              onClick={() => setToggleExpandOutput(!toggleExpandOutput)}
            >
              {toggleExpandOutput
                ? t("app_labels.hide")
                : t("app_labels.show_more")}
            </Button>
          </div>
        </div>
      </section>
      <section className="w-96">
        <div className="bg-gray-100 p-5 pb-0">
          <h3 className="text-blue-500 text-xs mb-1 font-bold">
            {t("column_labels.variables")}
          </h3>
          <p className="text-sm text-gray-800">
            {t("automation.pick_and_drag")}{" "}
            <strong>{t("fields.sender_id")}</strong>,{" "}
            <strong>{t("column_labels.destinations").split("|")[0]}</strong>,{" "}
            {t("app_labels.or")} <strong>{t("column_labels.message")}</strong>
          </p>
        </div>
        <Variables
          className="bg-gray-100 p-4 sticky top-0 z-10"
          variables={variables}
          handleDragStart={onDragStartTag}
        />
        <Form
          form={form}
          layout="vertical"
          name="sms-form"
          autoComplete="off"
          onFinish={onSubmitForm}
          onFinishFailed={onSubmitFormFail}
          requiredMark={true}
          scrollToFirstError
          className="space-y-6 py-5"
        >
          <Form.Item
            name="nameId"
            className="px-5"
            initialValue={
              node?.data?.nameId || formData?.nameId || node.id || ""
            }
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
                {t("automation.step")} {t("column_labels.name").toLowerCase()}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: `${t("automation.step")} ${t("column_labels.name")}`,
                }),
              },
              {
                required: true,
                pattern: new RegExp(/^[a-z0-9].*[a-z0-9]$/g),
                message: t("automation.step_name_start_end"),
              },
              {
                validator(rule, value, callback) {
                  let nameList = [...stepNameList];
                  // const arrayIndex = nameList.indexOf(node?.data?.nameId);

                  // Remove current node name in the array to exclude from exist check
                  // Remove current node name in the array to exclude from exist check
                  nameList = nameList.filter((v) => v !== node?.data?.nodeId);

                  if (nameList.includes(value)) {
                    return Promise.reject(
                      t("validations.already_exists", {
                        value: `${t("automation.step")} ${t(
                          "column_labels.name"
                        ).toLowerCase()}`,
                      })
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder={t("validations.valid", {
                value: `${t("automation.step")} ${t(
                  "column_labels.name"
                ).toLowerCase()}`,
              })}
              onChange={(e) => {
                const newValue = String(e.target.value)
                  .toLowerCase()
                  .replace(/ /g, "_");
                form.setFieldsValue({ nameId: newValue.trim() });
                onChangeFormValue("nameId", newValue.trim());
              }}
            />
          </Form.Item>
          <Form.Item
            name="subAccountId"
            className="px-5"
            initialValue={
              node?.data?.subAccountId ||
              formData?.subAccountId ||
              elements["trigger"]?.data.subAccountId ||
              ""
            }
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("validations.select", {
                  value: `${t("app_labels.new").toLowerCase()} ${t(
                    "fields.subaccount"
                  ).toLowerCase()}`,
                })}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("fields.subaccount"),
                }),
              },
            ]}
          >
            <Select
              disabled={loading}
              loading={loading}
              name="smsFormSubaccount"
              showSearch
              placeholder={t("validations.select", {
                value: t("fields.subaccount").toLowerCase(),
              })}
              filterOptions={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                setSelectedSubaccountId(value);

                // Set to text type if subaccount is updated
                form.setFieldsValue({ type: "text" });
                onChangeFormValue("type", "text");
              }}
              className="w-60 block"
            >
              {subaccountOptions.map((account) => (
                <Option
                  value={account?.SubAccountId}
                  key={account.SubAccountUid}
                >
                  <div className="flex">
                    {account?.SubAccountId}
                    {account?.FallbackConfig ? (
                      <img
                        className="ml-1"
                        style={{ width: "15px" }}
                        src={getChannelIconByCode(
                          channels[account?.FallbackConfig[0]?.ChannelType]
                            ?.code
                        )}
                        alt={
                          channels[account?.FallbackConfig[0]?.ChannelType]
                            ?.name
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle>
            {Object.keys(subaccountChannel || {}).length ? (
              <div className="px-5 flex space-x-2 items-center">
                <img
                  src={getChannelIconByCode(
                    channels[subaccountChannel?.ChannelType]?.code
                  )}
                  alt={channels[subaccountChannel?.ChannelType]?.name}
                />
                <h3 className="text-base text-gray-900 text-center">
                  {t("mcs.campaigns.fallback_details.0")}{" "}
                  {channels[subaccountChannel?.ChannelType]?.name}
                </h3>
              </div>
            ) : (
              ""
            )}
          </Form.Item>
          {subaccountChannel?.ChannelType === "WA" ? (
            <WhatsAppForm
              node={node}
              chatUserObject={snsObject[subaccountChannel?.ChannelType]}
              form={form}
              formData={formData}
              onChangeFormValue={onChangeFormValue}
              onDragOverInput={onDragOverInput}
              onDropInput={onDropInput}
              subaccount={selectedSubaccount}
              loadingTemplates={templatesLoading}
              loadingEnums={enumsLoading}
              templates={templates}
              getTemplates={(payload) => dispatch(getTemplates(payload))}
              enums={enums}
              getEnums={(payload) => dispatch(getEnums(payload))}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
            />
          ) : (
            ""
          )}
          {subaccountChannel?.ChannelType === "VB" ? (
            <ViberForm
              node={node}
              chatUserObject={snsObject[subaccountChannel?.ChannelType]}
              form={form}
              formData={formData}
              onChangeFormValue={onChangeFormValue}
              onDragOverInput={onDragOverInput}
              onDropInput={onDropInput}
              onUploadFile={onUploadFile}
              channelType={subaccountChannel?.ChannelType}
            />
          ) : (
            ""
          )}
          {subaccountChannel?.ChannelType !== "WA" &&
          subaccountChannel?.ChannelType !== "VB" ? (
            <CommonSnsForm
              node={node}
              channelType={subaccountChannel?.ChannelType}
              chatUserObject={snsObject[subaccountChannel?.ChannelType]}
              form={form}
              formData={formData}
              onChangeFormValue={onChangeFormValue}
              onDragOverInput={onDragOverInput}
              onDropInput={onDropInput}
              onUploadFile={onUploadFile}
            />
          ) : (
            ""
          )}
          {/* <Form.Item
            name="text"
            initialValue={node?.data?.text || form?.text || ""}
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">Message</h3>
            }
            rules={[{ required: true, message: "Message is required!" }]}
          >
            <TextArea
              ref={textInputRef}
              rows={8}
              placeholder="Enter or drag a variable from above"
              onChange={(e) => onChangeFormValue("text", e.target.value)}
              onDragEnter={() => textInputRef.current.focus()}
              onDragOver={onDragOverInput}
              onDrop={(e) => onDropInput(e, "text")}
            />
          </Form.Item> */}
          <Form.Item className="px-5">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={!form.isFieldsTouched(false)}
            >
              {t("actions.update")}
            </Button>
            <Popconfirm
              placement="top"
              title={t("confirmations.delete2", { item: t("automation.step") })}
              onConfirm={onConfirmDelete}
              okText={`${t("actions.delete")} ${t("automation.step")}`}
              cancelText={t("actions.cancel")}
            >
              <Button type="text" className="w-full mt-2" danger>
                {t("actions.delete")}
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
        {/* <code className="whitespace-pre-wrap">
          {JSON.stringify(form.getFieldsValue(false), null, "\t")}
        </code> */}
        {/* <code className="whitespace-pre-wrap">
          FORM DATA:
          {JSON.stringify(formData, null, "\t")}
        </code> */}
      </section>
    </>
  );
};

export default ChatAppForm;
