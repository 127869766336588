/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from "react";
import { Handle } from "react-flow-renderer";
import { useSelector } from "react-redux";
import "moment-duration-format";
import { useTranslation, Trans } from "react-i18next";

import nodes from "@/json/nodes";

import StepList from "@/components/compatibleStepList";
import Bubble from "@/components/bubble";
import countries from "@/json/countries";

const handlerTargetStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "-7px",
  bottom: "auto",
  transform: "translateX(-50%)",
};

const handlerSourceStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "auto",
  bottom: "-7px",
  transform: "translateX(-50%)",
};

const BranchNode = (node) => {
  const { elements } = useSelector((state) => state.flow);

  const showStepRef = useRef(null);
  const [showStepsBubble, setShowStepsBubble] = useState(false);

  const { t } = useTranslation();

  const addChild = (type) => {
    node.data.handleCreateNewNode(type, {
      ...elements[node.id],
      id: node.id,
    });
    setShowStepsBubble(false);
  };

  const stepOptionsComponent = (
    <Bubble
      visible={showStepsBubble}
      style={{ maxHeight: "100px" }}
      className="overflow-y-auto"
      handleClickOutside={() => setShowStepsBubble(false)}
      wrapperRef={showStepRef}
    >
      <div className="py-4 px-2 w-60">
        <StepList
          visible={true}
          nodeType={elements[node.id]?.type}
          handleSelectStep={(step) => addChild(step)}
        />
      </div>
    </Bubble>
  );

  const isValidConnection = (destinationNode) => {
    const currentNode = elements[node.id];
    const targetNode = elements[destinationNode];
    if (!targetNode || destinationNode === node.id) return;
    return nodes[currentNode.type].compatibleNode.includes(targetNode.type);
  };

  const isExistInSource = (source, sourceHandle) => {
    if (sourceHandle !== "default-handle") return false;
    return elements[source].target.includes(node.id);
  };

  // // Set node as 'selected' if input is empty upon initialize so that this node will appear on the sidebar
  // useEffect(() => {
  //   if (!Object.keys(elements[node.id]?.selectNextStep || {}).length) {
  //     node.data.handleShowNodeDetail(node);
  //   }
  // }, []);

  return (
    <div className="bg-white shadow rounded w-72 relative custom_node">
      <header className="flex items-center font-bold text-gray-700 relative">
        <Handle
          type="target"
          position="top"
          style={handlerTargetStyle}
          isValidConnection={({ source, sourceHandle }) =>
            isValidConnection(source) && !isExistInSource(source, sourceHandle)
          }
        />
      </header>
      <figure
        className="px-4 py-2 flex flex-col"
        onClick={() => node.data.handleShowNodeDetail(node)}
      >
        <figcaption className="text-gray-500 text-xs mb-1">
          {node.data?.inputs?.condition === 1 && (
            <div class="text-center">
              <p class="mb-2">{t("automation.country_code_is")}</p>
              <p class="text-sm">
                <span
                  class={`flag-icon flag-icon-${(
                    node.data?.inputs?.country || ""
                  ).toLowerCase()} flag-icon-squared mr-1`}
                />
                <b>
                  {
                    countries.find(
                      (v) => v.value === node.data?.inputs?.country
                    )?.name
                  }
                </b>
                <span class="ml-1 text-gray-300 text-xs">
                  (
                  {
                    countries.find(
                      (v) => v.value === node.data?.inputs?.country
                    )?.callingCode
                  }
                  )
                </span>
              </p>
            </div>
          )}

          {node.data?.inputs?.condition === 2 && (
            <div class="text-center">
              <p class="mb-2">{t("automation.string_contains")}</p>
              <span class="text-blue-600 bg-blue-100 py-1 px-2 text-sm rounded-full font-bold">
                {node.data?.inputs?.keyword}
              </span>
            </div>
          )}

          {node.data?.inputs?.condition === 3 && (
            <div class="text-xs">
              <p class="mb-2">
                {t("column_labels.date")}{" "}
                <span class="text-blue-600 bg-blue-100 py-1 px-2 rounded-full font-bold ml-1">
                  {node.data?.inputs?.date}
                </span>
              </p>
              <p class="mb-2">
                {t("date_labels.timezone")}
                <span class="ml-1 text-blue-600 bg-blue-100 py-1 px-2 rounded-full font-bold">
                  {node.data?.inputs?.timezone}{" "}
                </span>
              </p>
              <p class="mb-2">
                {t("column_labels.time")}
                {node.data?.inputs?.timefallsOutside
                  ? ` ${t("automation.outside_between_and.0")}`
                  : ""}{" "}
                {t("automation.outside_between_and.1")}{" "}
                <span class="ml-1 text-blue-600 bg-blue-100 py-1 px-2 rounded-full font-bold">
                  {node.data?.inputs?.time[0]}{" "}
                  {t("automation.outside_between_and.2")}{" "}
                  {node.data?.inputs?.time[1]}{" "}
                </span>
              </p>
            </div>
          )}

          {node.data?.inputs?.condition === 4 && (
            <div class="text-center">
              <p class="text-sm">
                {t("automation.is_a.0")}{" "}
                <span class="text-blue-600 bg-blue-100 py-1 px-2 rounded-full font-bold">
                  {node.data?.inputs?.date}
                </span>{" "}
                {t("automation.is_a.1")}{" "}
                <span class="text-blue-600 bg-blue-100 py-1 px-2 rounded-full font-bold">
                  {node.data?.inputs?.day}
                </span>{" "}
                ?
              </p>
            </div>
          )}
          {node.data?.inputs?.condition === 5 && (
            <div class="text-center">
              <p class="mb-2">
                {t("automation.condition")} {node.data?.inputs?.index + 1}
              </p>
              <span class="text-blue-600 bg-blue-100 py-1 px-2 text-sm rounded-full font-bold">
                {node.data?.inputs?.script
                  ? `{{${node.data?.inputs?.script}}}`
                  : "NULL"}
              </span>
            </div>
          )}

          {(node.data?.inputs?.condition === "!=" ||
            node.data?.inputs?.condition === "==") && (
            <div class="text-center">
              <span class="text-blue-600 bg-blue-100 py-1 px-2 text-sm rounded-full font-bold">
                DTMF{" "}
                {node.data?.inputs?.label
                  ? node.data?.inputs?.label.toLowerCase()
                  : node.data?.inputs?.condition}
                &nbsp; {node.data?.inputs?.dtmfValue}
              </span>
            </div>
          )}
        </figcaption>
      </figure>
      <footer className="relative">
        <span
          role="button"
          tabIndex={0}
          ref={showStepRef}
          onClick={(e) => {
            e.preventDefault();
            setShowStepsBubble(!showStepsBubble);
            node.data.handleHideSidebar();
          }}
          className={`cursor-pointer border-t border-gray-200 flex items-center justify-center text-blue-500 p-2 font-medium rounded-b transition-colors duration-100 ease-in hover:bg-blue-500 hover:text-white relative ${
            showStepsBubble ? "bg-blue-500 text-white" : ""
          }`}
        >
          <i className="material-icons text-lg mr-1">add</i>
          {!(node?.data?.target).length
            ? t("automation.add_next_step")
            : t("automation.insert_next_step")}
        </span>
        <Handle
          id="a"
          isValidConnection={({ target }) => isValidConnection(target)}
          type="source"
          position="bottom"
          style={handlerSourceStyle}
        />
        {stepOptionsComponent}
      </footer>
    </div>
  );
};

export default BranchNode;
