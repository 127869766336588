import React, { useReducer, useEffect, useState } from "react";
import { Button, Modal, Form, Input, Select, Radio } from "antd";
const { Option } = Select;
import { useTranslation, Trans } from "react-i18next";

import triggerTypes from "@/json/triggers.json";

const ACTIONS = {
  INITIALIZE_FORM: "initialize-form",
  UPDATE_VALUE: "update-value",
};

function reducer(formData, action) {
  const { item, value } = action.payload;
  switch (action.type) {
    case ACTIONS.INITIALIZE_FORM:
      return { ...action.payload };
    case ACTIONS.UPDATE_VALUE:
      return { ...formData, [item]: value };
    default:
      return formData;
  }
}

const workflowTemplate = {
  subAccountId: "",
  trigger: "",
  status: true,
  definition: {
    name: "",
    steps: [],
  },
};

const WorkflowForm = ({
  visible,
  selectedWorkflow,
  handleSetWorkflow,
  handleCancel,
  smsSubaccountOptions,
  chatSubaccountOptions,
}) => {
  const [formData, dispatch] = useReducer(reducer, {});
  const [form] = Form.useForm();

  const [subaccountOptions, setSubaccountOptions] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (!smsSubaccountOptions && !chatSubaccountOptions) return;

    // Set subaccount field to empty if trigger value is changed
    form.setFieldsValue({ subAccountId: "" });

    // Assign proper subaccount based on the trigger selected
    if (
      !formData.trigger ||
      formData.trigger === "inbound_sms" ||
      formData.trigger === "http_request"
    ) {
      setSubaccountOptions(smsSubaccountOptions);
    } else if (formData.trigger === "inbound_chat_apps") {
      setSubaccountOptions(chatSubaccountOptions);
    }
  }, [
    formData.trigger,
    smsSubaccountOptions,
    chatSubaccountOptions,
    setSubaccountOptions,
  ]);

  const onSubmitForm = (values) => {
    form.validateFields().then((v) => {
      const { name, subAccountId, trigger } = values;
      let workflow = { ...workflowTemplate };
      workflow.subAccountId = subAccountId;
      workflow.trigger = trigger;
      workflow.definition = {
        ...workflow.definition,
        name,
      };
      handleSetWorkflow(workflow);
    });
  };

  const onChangeFormValue = (item, value) => {
    dispatch({
      type: ACTIONS.UPDATE_VALUE,
      payload: { item, value },
    });
  };

  return (
    <Modal
      visible={visible}
      title={`${t("column_labels.custom")} ${t("automation.workflow")}`}
      keyboard={false}
      closable={false}
      bodyStyle={{ padding: 0 }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t("actions.back")}
        </Button>,
        <Button
          form="new-workflow-form"
          key="submit"
          type="primary"
          htmlType="submit"
        >
          {t("actions.create")} {t("app_labels.new").toLowerCase()}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="new-workflow-form"
        onFinish={onSubmitForm}
        autoComplete="off"
        requiredMark={true}
        className="space-y-10 p-5"
      >
        <Form.Item
          name="trigger"
          initialValue={Object.keys(triggerTypes)[0]}
          onChange={(e) => onChangeFormValue("trigger", e.target.value)}
          label={
            <h3 className="text-gray-500 text-xs mb-1 font-bold">
              {t("validations.select", {
                value: t("automation.trigger_type").toLowerCase(),
              })}
            </h3>
          }
          rules={[
            {
              required: true,
              message: t("validations.required", {
                value: t("automation.trigger_type"),
              }),
            },
          ]}
        >
          <Radio.Group
            name="triggertype"
            className="block flex flex-nowrap h-auto"
          >
            {Object.values(triggerTypes).map((type) => (
              <Radio.Button
                value={type.value}
                key={type.value}
                className="w-full h-auto flex justify-center items-center leading-none p-2 text-center"
                disabled={type.disabled}
              >
                <div className="pt-1 flex items-center font-2xl space-x-2">
                  <i className="material-icons-outlined text-gray-400 text-4xl">
                    {triggerTypes[type.value].icon}
                  </i>
                  <small className="pr-2">{t(type.titlets)}</small>
                </div>
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="name"
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              {t("automation.workflow_name")}
            </h3>
          }
          rules={[
            {
              required: true,
              message: t("validations.select", {
                value: t("column_labels.name"),
              }),
            },
          ]}
        >
          <Input
            placeholder={t("automation.workflow_name")}
            onChange={(e) => onChangeFormValue("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item className="flex flex-nowrap">
          <Form.Item
            name="subAccountId"
            label={
              <h3 className="text-gray-500 text-xs mb-1 font-bold">
                {t("fields.subaccount")}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("fields.subaccount"),
                }),
              },
            ]}
            className="w-1/2"
          >
            <Select
              style={{ width: "400px" }}
              name="smsFormSubaccount"
              showSearch
              placeholder={t("validations.select", {
                value: t("fields.subaccount").toLowerCase(),
              })}
              filterOptions={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => onChangeFormValue("subAccountId", value)}
            >
              {subaccountOptions.map((account) => (
                <Option
                  value={account.SubAccountId}
                  key={account.SubAccountUid}
                >
                  {account.SubAccountId}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WorkflowForm;
