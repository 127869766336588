import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { Card, List, Button } from "antd";

import triggers from "@/json/triggers.json";
import { getTemplates } from "@/utils/get-templates";
import handlePackageLimit from "@/utils/handlePackageLimit";

const templates = getTemplates();

export default function Templates() {
  const { t } = useTranslation();

  const hasInboundEntitlement = handlePackageLimit(
    "feature-message-type-inbound"
  );

  const templatesList = (
    <ul className="flex flex-wrap -m-2">
      {(templates || []).map((v, i) => {
        return (
          <li key={`template-${i}`} className="w-1/3 p-2">
            <Link
              class="transform hover:-translate-y-1 transition-all ease-in duration-75 cursor-pointer block bg-white shadow border border-gray-100 rounded text-black overflow-hidden flex items-center justify-center relative"
              style={{ height: "150px" }}
              to={{
                pathname: "/builder",
                search: `?template_id=${v.templateId}`,
              }}
            >
              <i
                className="material-icons-outlined text-gray-100 absolute select-none"
                style={{
                  bottom: "-20%",
                  right: "-20%",
                  fontSize: "200px",
                  zIndex: 0,
                }}
              >
                {triggers[v.trigger].icon}
              </i>
              <div className="z-10 relative flex items-center justify-center flex-col">
                <i className="material-icons-outlined text-3xl text-8x8-red">
                  {triggers[v.trigger].icon}
                </i>
                <h2 class="text-sm mt-2 uppercase text-gray-900">
                  {v?.definition?.name}
                </h2>
                <p class="text-xs mt-2 text-gray-500">
                  {/* {triggers[v.trigger].label} */}
                  {t(triggers[v.trigger].labelts)}
                </p>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className="relative mt-20">
      <div className="flex mb-5">
        <div className="flex-1 font-medium">
          {t("automation.choose_template")}
        </div>
      </div>
      {templatesList}
    </div>
  );
}
