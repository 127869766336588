import axios from "axios";
import qs from "query-string";
import { getHttpConfig } from "./http-config";

const responseErrorConfig = (err) => Promise.reject(err.response.data);
const responseSuccessConfig = (response) => {
  const httpConfig = getHttpConfig();
  return httpConfig.responseSuccessConfig(response);
};
const requestErrorConfig = err => Promise.reject(err);
const requestSuccessConfig = (config, api) => {
  const httpConfig = getHttpConfig();
  return httpConfig.requestSuccessConfig(config, api);
};

// Create instances for Connect API v1 and v2
const httpv1 = axios.create({
  baseURL: `${
    // eslint-disable-next-line no-undef
    process.env.API_URL || "https://app.wavecell.com"
  }/api/v1`,
});

const httpv2 = axios.create({
  baseURL: `${
    // eslint-disable-next-line no-undef
    process.env.API_URL || "https://app.wavecell.com"
  }/api/v2`,
});

httpv1.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
httpv1.interceptors.request.use(
  (config) => requestSuccessConfig(config, "v1"),
  requestErrorConfig
);

httpv2.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
httpv2.interceptors.request.use(
  (config) => requestSuccessConfig(config, "v2"),
  requestErrorConfig
);

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

const generateMethods = (httpversion) => ({
  request: (options) => httpversion(options),
  get: (url, params = {}) =>
    httpversion.get(url, {
      params: {
        ...params,
      },
      paramsSerializer(p) {
        return qs.stringify(p, { indices: false });
      },
    }),
  post: (url, data = {}, config = {}) => httpversion.post(url, data, config),
  put: (url, data = {}) => httpversion({ method: "put", url, data }),
  delete: (url, data = {}) => httpversion({ method: "delete", url, data }),
  patch: (url, data = {}) => httpversion({ method: "patch", url, data }),
});

export default {
  v1: generateMethods(httpv1),
  v2: generateMethods(httpv2),
  post: (url, data = {}, config = {}) => axios.post(url, data, config),
};
