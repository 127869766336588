import React, { useRef } from "react";
import { Form, Input } from "antd";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useTranslation, Trans } from "react-i18next";

export default function Line({
  onChangeFormValue,
  onDragOverInput,
  onDropInput,
  node,
  form,
}) {
  const inputRef = useRef(null);
  const { t } = useTranslation();

  return (
    <Form.Item
      name="msisdn"
      className="px-5"
      initialValue={node?.data?.user?.msisdn || form?.from || ""}
      label={
        <h3 class="text-gray-500 text-xs mb-1 font-bold">
          {t("column_labels.destinations").split("|")[0]}
        </h3>
      }
      rules={[
        {
          required: true,
          message: t("validations.required", {
            value: t("column_labels.user_id"),
          }),
        },
        {
          validator(rule, value, callback) {
            const isVariable = String(value || "").match(/^{{.*}}$/g);
            if (!isVariable && !parsePhoneNumber(value).valid) {
              return Promise.reject(
                t("validations.invalid", {
                  value: t("column_labels.phone_no").toLowerCase(),
                })
              );
            }

            return Promise.resolve();
          },
        },
      ]}
    >
      <Input
        ref={inputRef}
        placeholder={t("automation.enter_or_drag")}
        onChange={(e) => onChangeFormValue("msisdn", e.target.value)}
        onDragEnter={() => inputRef.current.focus()}
        onDragOver={onDragOverInput}
        onDrop={(e) => onDropInput(e, "msisdn")}
      />
    </Form.Item>
  );
}
