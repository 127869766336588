import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ReactFlowProvider } from "react-flow-renderer";

import { Button, ConfigProvider, Space } from "antd";

import { Provider } from "react-redux";

import store from "@/store";

import Home from "@/views/home";
import Builder from "@/views/builder";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const user = JSON.parse(localStorage.getItem("CPV3_User") || {});

// SiteVersion_Current
Bugsnag.start({
  // eslint-disable-next-line no-undef
  apiKey: process.env.BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging", "development"],
  user: {
    id: user && user.UserId ? user.UserId : "",
  },
  metadata: {
    accountUid: user && user.AccountUid ? user.AccountUid : "",
  },
  onError(event) {
    const HTTP_UNAUTHORIZED_CODE = 401;
    const unhandledError =
      event.unhandled && event.originalError instanceof Error;
    const matches401Error =
      unhandledError?.response?.status === HTTP_UNAUTHORIZED_CODE;

    if (matches401Error) {
      // eslint-disable-next-line no-param-reassign
      event.unhandled = false;
    }

    return true;
  },
  // eslint-disable-next-line no-undef
  releaseStage: process.env.TARGET,
});

export default function App() {
  return (
    <React.Suspense fallback="loading...">
      <Provider store={store}>
        <Router basename="automation">
          <ReactFlowProvider>
            <section className="relative h-full main__section bg-white">
              {/* A <Switch> looks through its children <Route>s and
                  renders the first one that matches the current URL. */}
              <Switch>
                <Route key={1} path="/builder" component={Builder} />
                <Route key={2} path="/" component={Home} />
              </Switch>
            </section>
          </ReactFlowProvider>
        </Router>
      </Provider>
    </React.Suspense>
  );
}
