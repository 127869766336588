import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import http from "@/utils/http-request";

const initialState = {
  uploading: false,
};

export const getUploadUrl = createAsyncThunk(
  "commons/getUploadUrl",
  async (payload, { dispatch, rejectWithValue }) => {
    const { file } = payload;
    const { name, type } = file;

    // sometimes mimetype is empty or unknown
    const fallBackMimeType = "application/octet-stream";
    const mimeType = type ? type : fallBackMimeType;

    try {
      const { data } = await http.v2.get(
        `/files/upload-url?filename=${name || ""}&type=${mimeType}`
      );
      const { url: uploadUrl, db_url: dbUrl } = data;

      return {
        uploadUrl,
        dbUrl,
        mimeType,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const request = createAsyncThunk(
  "commons/request",
  async (payload, { dispatch, rejectWithValue }) => {
    const { method, url, data, headers } = payload;

    try {
      await http.v2.request({
        method,
        url,
        data,
        headers,
        transformRequest: [
          (d, h) => {
            // eslint-disable-next-line no-param-reassign
            if (h.Authorization) {
              // eslint-disable-next-line no-param-reassign
              delete h.Authorization;
            }

            return d;
          },
        ],
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const definitionsSlice = createSlice({
  name: "commons",

  initialState,
  // reducers actions
  reducers: {},
  extraReducers: {
    [getUploadUrl.pending]: (state, action) => {
      state.uploading = true;
    },
    [getUploadUrl.fulfilled]: (state, action) => {
      state.uploading = false;
    },
    [getUploadUrl.rejected]: (state, action) => {
      state.uploading = false;
    },

    [request.pending]: (state, action) => {
      state.uploading = true;
    },
    [request.fulfilled]: (state, action) => {
      state.uploading = false;
    },
    [request.rejected]: (state, action) => {
      state.uploading = false;
    },
  },
});

const { reducer } = definitionsSlice;

export default reducer;
