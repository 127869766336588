import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  selectedWorkflow: {},
  selectedNode: {},
  elements: {},
};

const flowSlice = createSlice({
  name: "flow",
  initialState,
  reducers: {
    updateElements(state, action) {
      state.elements = { ...state.elements, ...action.payload };
    },
    updateNode(state, action) {
      const { id, ...data } = action.payload;
      state.elements = {
        ...state.elements,
        [id]: { ...state.elements[id], ...data },
      };
    },
    updateSelectedNode: (state, action) => {
      state.selectedNode = action.payload;
    },
    initalizeSelectedWorkflow: (state, action) => {
      state.elements = {};
      state.selectedNode = {};
      state.selectedWorkflow = action.payload;
    },
    removeNode(state, action) {
      const { id, newTarget } = action.payload;
      const newElements = { ...state.elements };
      const currentNode = { ...newElements[id] };
      const { source, target, type } = currentNode;

      // Remove node from parent's target if node is not orphan
      let parentNode = {};
      if (source && source.length) {
        let tempParentNode = { ...newElements[source] };
        const indexOfNode = tempParentNode.target.indexOf(id);
        tempParentNode.target.splice(indexOfNode, 1);

        if (target.length === 1) {
          tempParentNode.target = [...target];
        }

        if (newTarget && newTarget.length) {
          tempParentNode.target = [...newTarget];
        }

        parentNode = {
          [source]: { ...tempParentNode },
        };
      }

      // Remove node from children's source
      let targetsWithUpdateSource = [];
      if (target) {
        targetsWithUpdateSource = (target || []).reduce((targets, nodeId) => {
          const currentChildNode = { ...newElements[nodeId] };

          currentChildNode.source = [];

          return {
            ...targets,
            [nodeId]: currentChildNode,
          };
        }, {});
      }

      if (
        Object.keys(targetsWithUpdateSource).length === 1 &&
        type.toLowerCase() !== "childbranch"
      ) {
        targetsWithUpdateSource[
          Object.keys(targetsWithUpdateSource)[0]
        ].source = [...source];
      }

      // Remove selected node from elements
      delete newElements[id];

      state.elements = {
        ...newElements,
        ...targetsWithUpdateSource,
        ...parentNode,
      };
    },
  },
});

const { actions, reducer } = flowSlice;

export const {
  updateElements,
  updateSelectedNode,
  updateNode,
  removeNode,
  initalizeSelectedWorkflow,
} = actions;

export default reducer;
