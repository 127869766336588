import React, { useEffect, useReducer, useRef } from "react";
import { useSelector } from "react-redux";
import { Form, Button, Input } from "antd";
import { useTranslation, Trans } from "react-i18next";

// import { FlattenObject as flattenObject } from "@/utils/FlattenObject";

const format = "H.mm:ss:[00]";

const ACTIONS = {
  INITIALIZE_FORM: "initialize-form",
  UPDATE_VALUE: "update-value",
};

function reducer(formData, action) {
  const { item, value } = action.payload;
  switch (action.type) {
    case ACTIONS.INITIALIZE_FORM:
      return { ...action.payload };
    case ACTIONS.UPDATE_VALUE:
      return { ...formData, [item]: value };
    default:
      return formData;
  }
}

const ConverseForm = ({
  node,
  handleSubmit,
  handleCancel,
  stepNameList,
  onUpdateStepNameList,
}) => {
  const [form] = Form.useForm();
  const { elements } = useSelector((state) => state.flow);

  const [formData, dispatch] = useReducer(reducer, {});

  const { t } = useTranslation();

  // Set 'formData' to the values of 'form instance' on mount
  useEffect(() => {
    dispatch({
      type: ACTIONS.INITIALIZE_FORM,
      payload: form.getFieldsValue(true),
    });
  }, []);

  const onChangeFormValue = (item, value) => {
    dispatch({
      type: ACTIONS.UPDATE_VALUE,
      payload: { item, value },
    });
  };

  const onSubmitForm = (values) => {
    form.validateFields().then((v) => {
      // Update store node data
      const { id } = node;
      const newData = { ...elements[id] };

      delete newData.nodeId;
      handleSubmit({
        id,
        inputs: { nameId: v.nameId, payload: "{{data.payload}}" },
        outputs: { [`${v.nameId}_ticketId`]: "{{step.ticketId}}" },
      });

      // Update the master list of step names
      let nameList = [...stepNameList];
      const arrayIndex = nameList.indexOf(node?.data?.nameId || id);
      nameList.splice(arrayIndex, 1, values?.nameId);
      onUpdateStepNameList(nameList);
    });
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="wait-form"
        autoComplete="off"
        onFinish={onSubmitForm}
        requiredMark={true}
        className="space-y-5 w-60"
      >
        <Form.Item
          name="nameId"
          initialValue={node?.data?.nameId || formData?.nameId || node.id || ""}
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
              {t("automation.step")} {t("column_labels.name").toLowerCase()}
            </h3>
          }
          rules={[
            {
              required: true,
              message: t("validations.required", {
                value: `${t("automation.step")} ${t("column_labels.name")}`,
              }),
            },
            {
              required: true,
              pattern: new RegExp(/^[a-z0-9].*[a-z0-9]$/g),
              message: t("automation.step_name_start_end"),
            },
            {
              validator(rule, value) {
                let nameList = [...stepNameList];

                // const arrayIndex = nameList.indexOf(node?.data?.nodeId);

                // Remove current node name in the array to exclude from exist check
                nameList = nameList.filter((v) => v !== node?.data?.nodeId);

                if (nameList.includes(value)) {
                  return Promise.reject(
                    t("validations.already_exists", {
                      value: `${t("automation.step")} ${t(
                        "column_labels.name"
                      ).toLowerCase()}`,
                    })
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder={t("validations.valid", {
              value: `${t("automation.step")} ${t(
                "column_labels.name"
              ).toLowerCase()}`,
            })}
            onChange={(e) => {
              const newValue = String(e.target.value)
                .toLowerCase()
                .replace(/ /g, "_");
              form.setFieldsValue({ nameId: newValue.trim() });
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full"
            disabled={!form.isFieldsTouched(false)}
          >
            {t("actions.update")}
          </Button>
          <Button
            type="text"
            className="w-full mt-2"
            danger
            onClick={handleCancel}
          >
            {t("actions.cancel")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ConverseForm;
