import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import http from "@/utils/http-request";

const initialState = {
  usage: {},
};

export const getUsageHistory = createAsyncThunk(
  "workflowUsage/getUsageHistory",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.v2.get("/automation/workflows/usage/history");
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUsage = createAsyncThunk(
  "workflowUsage/getUsage",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.v2.get("/automation/workflows/usage");

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const definitionsSlice = createSlice({
  name: "workflowUsage",

  initialState,
  // reducers actions
  reducers: {},
  extraReducers: {
    [getUsage.fulfilled]: (state, action) => {
      const data = action.payload;

      state.usage = data;
    },

    [getUsage.fulfilled]: (state, action) => {
      const data = action.payload;

      state.usage = data;
    },
  },
});

const { reducer } = definitionsSlice;

export default reducer;
