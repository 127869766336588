import { Form, Input, Button, Select, Row, Col } from "antd";

import { MinusCircleOutlined } from "@ant-design/icons";
import { useTranslation, Trans } from "react-i18next";

const { Option } = Select;

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const DayfoWeek = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.List name="dayOfWeek">
        {(fields, { add, remove }) => {
          /**
           * `fields` internal fill with `name`, `key`, `fieldKey` props.
           * You can extends this into sub field to support multiple dynamic fields.
           */
          return (
            <div>
              <div class="flex">
                <h3 class="flex-1 text-gray-500 text-xs mb-2 font-bold">
                  {t("column_labels.date")}
                </h3>
                <h3 class="flex-1 text-gray-500 text-xs mb-2 font-bold">
                  {" "}
                  {t("column_labels.days").split("|")[0]}
                </h3>
              </div>
              {fields.map((field, index) => (
                <Row
                  key={field.key}
                  gutter={10}
                  className="bg-gray-50 px-5 pt-3"
                >
                  <Col span={11}>
                    <Form.Item
                      name={[field.name, "date"]}
                      fieldKey={[field.fieldKey, "date"]}
                      rules={[
                        {
                          required: true,
                          message: t("validations.required", {
                            value: t("column_labels.date"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        ref={props.setRef(`dateRef${field.fieldKey}`)}
                        placeholder={t("automation.enter_a_valid_or_drag", {
                          value: t("column_labels.date").toLowerCase(),
                        })}
                        onDragEnter={() =>
                          props
                            .getRef(`dateRef${field.fieldKey}`)
                            .current.focus()
                        }
                        onDragOver={props.onDragOverInput}
                        onDrop={(e) =>
                          props.onDropInput(
                            e,
                            "date",
                            field.fieldKey,
                            "dayOfWeek"
                          )
                        }
                        className="w-full text-xs px-0 py-2"
                        bordered={false}
                        style={{
                          borderBottom: "1px solid #D1D5DB",
                          borderRadius: 0,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name={[field.name, "day"]}
                      fieldKey={[field.fieldKey, "day"]}
                      rules={[
                        {
                          required: true,
                          message: t("validations.required", {
                            value: t("column_labels.days")
                              .split("|")[0]
                              .toLowerCase(),
                          }),
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        showSearch
                        placeholder={t("validations.select", {
                          value: t("column_labels.days")
                            .split("|")[0]
                            .toLowerCase(),
                        })}
                        optionFilterProp="children"
                        onChange={() => {}}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        className="w-full text-xs px-0 py-1"
                        bordered={false}
                        style={{
                          borderBottom: "1px solid #D1D5DB",
                          borderRadius: 0,
                        }}
                      >
                        {days.map((v) => (
                          <Option value={v} key={v}>
                            {v}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    {fields.length !== 1 ? (
                      <MinusCircleOutlined
                        className="text-gray-300 hover:text-red-500 text-base"
                        style={{ marginTop: "10px", color: "#ccc" }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
              ))}
              <Form.Item>
                {fields.length < 5 ? (
                  <Button
                    type="dashed"
                    size="small"
                    className="flex items-center mt-5 text-xs"
                    onClick={() => {
                      add();
                    }}
                  >
                    <i className="material-icons text-base mr-1">add</i>
                    {t("automation.add_another_pair")}
                  </Button>
                ) : null}
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default DayfoWeek;
