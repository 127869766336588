import { useEffect, useRef, useState } from "react";
import { Form, Input, Radio, Upload, message, notification } from "antd";
const { TextArea } = Input;
const { Dragger } = Upload;
import { useTranslation, Trans } from "react-i18next";

import ZaloFields from "./zalo";
import KakaoFields from "./kakao";
import WeChatFields from "./weChat";
import MessengerFields from "./messenger";
import LineFields from "./line";

const contentTypes = [
  {
    label: "Text",
    value: "text",
    icon: "description",
    labelts: "automation.content_types.0",
  },
  {
    label: "Image",
    value: "image",
    icon: "image",
    labelts: "automation.content_types.1",
  },
];

const CommonSnsForm = ({
  node,
  form,
  onChangeFormValue,
  onDragOverInput,
  onDropInput,
  onUploadFile,
  channelType,
}) => {
  const previewImageRef = useRef(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [invalidImage, setInvalidImage] = useState(false);

  let channelFields = null;

  const { t } = useTranslation();

  if (channelType === "ZL") {
    channelFields = (
      <ZaloFields
        form={form}
        node={node}
        onChangeFormValue={onChangeFormValue}
        onDragOverInput={onDragOverInput}
        onDropInput={onDropInput}
      />
    );
  } else if (channelType === "KK") {
    channelFields = (
      <KakaoFields
        form={form}
        node={node}
        onChangeFormValue={onChangeFormValue}
        onDragOverInput={onDragOverInput}
        onDropInput={onDropInput}
      />
    );
  } else if (channelType === "WC") {
    channelFields = (
      <WeChatFields
        form={form}
        node={node}
        onChangeFormValue={onChangeFormValue}
        onDragOverInput={onDragOverInput}
        onDropInput={onDropInput}
      />
    );
  } else if (channelType === "FB") {
    channelFields = (
      <MessengerFields
        form={form}
        node={node}
        onChangeFormValue={onChangeFormValue}
        onDragOverInput={onDragOverInput}
        onDropInput={onDropInput}
      />
    );
  } else if (channelType === "LN") {
    channelFields = (
      <LineFields
        form={form}
        node={node}
        onChangeFormValue={onChangeFormValue}
        onDragOverInput={onDragOverInput}
        onDropInput={onDropInput}
      />
    );
  }

  return (
    <>
      {channelFields}
      <Form.Item
        name="type"
        initialValue={node?.data?.type || form?.type || contentTypes[0]?.value}
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.type"),
            }),
          },
        ]}
        className="px-5"
      >
        <Radio.Group
          onChange={(e) => {
            onChangeFormValue("type", e.target.value);
          }}
          className="flex flex-nowrap"
        >
          {contentTypes.map((type) => (
            <Radio.Button
              key={`wa-content-${type.value}`}
              value={type.value}
              className="w-full"
            >
              <div className="flex items-center justify-center">
                <i className="material-icons text-xl mr-1 leading-8">
                  {type.icon}
                </i>
                <span className="text-xs">{t(type.labelts)}</span>
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        className="p-5"
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("actions.preview")}
          </h3>
        }
      >
        <dialog className="p-3 bg-white rounded shadow whitespace-pre-wrap block break-words relative w-full border border-gray-100 space-y-3">
          {(form.getFieldsValue(false)?.url &&
            form.getFieldsValue(false)?.type !== "text") ||
          loadingImage ? (
            <img
              alt=""
              ref={previewImageRef}
              src={form.getFieldsValue(false)?.url || ""}
              onLoad={() => {
                setLoadingImage(false);
                setInvalidImage(false);
              }}
              onError={() => setInvalidImage(true)}
              className={`w-full h-auto block rounded bg-gray-100 ${
                loadingImage ? "pb-44 bg-gray-300" : ""
              }`}
            />
          ) : (
            ""
          )}
          {form.getFieldsValue(false)?.text &&
          form.getFieldsValue(false)?.type !== "image" ? (
            <p className="p-0">{form.getFieldsValue(false)?.text || ""}</p>
          ) : (
            ""
          )}
        </dialog>
      </Form.Item>
      {form.getFieldsValue(false)?.type === "text" ||
      form.getFieldsValue(false)?.type === "buttons" ? (
        <TextForm
          node={node}
          form={form}
          onChangeFormValue={onChangeFormValue}
          onDragOverInput={onDragOverInput}
          onDropInput={onDropInput}
        />
      ) : (
        ""
      )}
      {form.getFieldsValue(false)?.type === "image" ||
      form.getFieldsValue(false)?.type === "buttons" ? (
        <MediaForm
          node={node}
          form={form}
          channelType={channelType}
          imagevPreviewRef={previewImageRef}
          onChangeFormValue={onChangeFormValue}
          onUploadFile={onUploadFile}
          loadingImage={loadingImage}
          invalidImage={invalidImage}
          setInvalidImage={(isValid) => setInvalidImage(isValid)}
          setLoadingImage={setLoadingImage}
        />
      ) : (
        ""
      )}
    </>
  );
};

const TextForm = ({
  node,
  form,
  onChangeFormValue,
  onDragOverInput,
  onDropInput,
}) => {
  const textInputRef = useRef(null);

  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name="text"
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("column_labels.message")}
          </h3>
        }
        initialValue={node?.data?.content?.text || form?.templateName || ""}
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.message"),
            }),
          },
        ]}
        className="px-5"
      >
        <TextArea
          ref={textInputRef}
          rows={8}
          placeholder={t("automation.enter_or_drag")}
          onChange={(e) => onChangeFormValue("text", e.target.value)}
          onDragEnter={() => textInputRef.current.focus()}
          onDragOver={onDragOverInput}
          onDrop={(e) => onDropInput(e, "text")}
        />
      </Form.Item>
    </>
  );
};

const MediaForm = ({
  node,
  form,
  imagevPreviewRef,
  onChangeFormValue,
  onUploadFile,
  loadingImage,
  invalidImage,
  setInvalidImage,
  setLoadingImage,
  channelType,
}) => {
  const acceptedFileTypes = ".png,.jpg,.jpeg";

  const { t } = useTranslation();

  // Validate file type and file size
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({
        message: t("upload_labels.file_must_format", { value: "PNG/JPG" }),
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      notification.error({
        message: t("upload_labels.file_must", { value: "1.5MB" }),
      });
    }
    return isJpgOrPng && isLt2M;
  };

  const onChange = async ({ file: f }) => {
    const { originFileObj: file } = f;
    try {
      const data = await onUploadFile(file);
      onChangeFormValue("url", data);
      form.setFieldsValue({ url: data });
    } catch (err) {
      throw new Error(err);
    }
  };

  // Loading for image preview
  useEffect(() => {
    if (node?.data?.content?.url) {
      setLoadingImage(true);
    }
  }, [node?.data?.content?.url]);

  // Extract the filename along with the type
  const filenameFromUrl = (url, defaultName = "Untitled") => {
    let name = defaultName;
    if (url && typeof url === "string") {
      const matches = url.match(/(?<=\/)[^\/\?#]+(?=[^\/]*$)/g);
      name = (matches || []).length ? matches[0] : name;
    }
    return name;
  };

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const url = node?.data?.content?.url || "";

    const name = filenameFromUrl(url);

    setFileList([
      {
        uid: "1",
        name,
        status: "done",
        url,
      },
    ]);
  }, [node?.data?.content?.url]);

  const uploadInputs = (
    <>
      <Form.Item
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
            {t("fields.media_url")}
          </h3>
        }
        name="url"
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.url"),
            }),
          },
          {
            type: "url",
            message: t("validations.invalid", {
              value: t("column_labels.url").toLowerCase(),
            }),
          },
          {
            pattern: new RegExp(/^.*\.(jpg|JPG|png|PNG|jpeg|JPEG)/g),
            message: t("validations.invalid", {
              value: t("column_labels.url").toLowerCase(),
            }),
          },
        ]}
        validateFirst={true}
      >
        <Input
          onChange={(e) => {
            onChangeFormValue("url", e.target.value);
            // Set fileList to empty
            setFileList([]);
          }}
          placeholder={t("validations.valid", {
            value: t("fields.media_url").toLowerCase(),
          })}
        />
      </Form.Item>
      <div
        role="presentation"
        className="border-t border-gray-200 w-full mt-5 relative"
      >
        <span
          className="bg-white text-gray-400 p-2 absolute text-xs font-bold left-1/2 top-1/2 transform -translate-y-2/4 -translate-x-2/4 leading-none text-center table"
          style={{ lineHeight: 0 }}
        >
          {t("app_labels.or").toUpperCase()}
        </span>
      </div>
      <Form.Item
        name="url"
        initialValue={node?.data?.content?.url || form?.url || ""}
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.url").toLowerCase(),
            }),
          },
        ]}
        valuePropName="file"
        className="mt-5"
      >
        <Dragger
          name="file"
          multiple={false}
          maxCount={1}
          defaultFileList={fileList}
          onChange={(f) => onChange(f)}
          onRemove={async () => {
            onChangeFormValue("url", "");
            form.setFieldsValue({ url: "" });
            setFileList([]);
            return await false;
          }}
          customRequest={false}
          accept={acceptedFileTypes}
          beforeUpload={beforeUpload}
        >
          <p className="ant-upload-drag-icon">
            <i className="material-icons text-4xl text-gray-500">inbox</i>
          </p>
          <p className="ant-upload-text">
            <p className="text-gray-500 text-sm font-bold">
              {t("upload_labels.click_or_drag")}
            </p>
          </p>
          <p className="ant-upload-hint">
            <small className="text-gray-400 text-xs">
              {t("upload_labels.supported_types")}: {acceptedFileTypes} (
              {t("upload_labels.size_less_than", { size: "1.5MB" })})
            </small>
          </p>
        </Dragger>
      </Form.Item>
    </>
  );

  const imagePreview = (
    <Form.Item
      name="url"
      rules={[
        {
          required: true,
          message: t("validations.required", {
            value: t("column_labels.url"),
          }),
        },
        {
          type: "url",
          message: t("validations.invalid", {
            value: t("column_labels.url").toLowerCase(),
          }),
        },
        {
          validator(rule, value) {
            return new Promise((resolve, reject) => {
              const img = new Image();

              img.src = value;

              img.addEventListener("load", () => {
                resolve();
              });

              img.addEventListener("error", () => {
                reject(t("mcs.messages.chat_apps.media_url[6]"));
              });
            });
          },
        },
      ]}
      validateFirst={true}
    >
      <div className="p-2 rounded-sm border border-indigo-500 bg-8x8-red-100 text-indigo-700 w-full flex justify-between items-center">
        <span className="text-xs font-bold">
          {filenameFromUrl(form.getFieldsValue(false)?.url)}
        </span>
        <a
          role="button"
          href="/"
          className="text-xs text-blue-500 hover:underline hover:text-blue-700"
          onClick={(ev) => {
            ev.preventDefault();
            onChangeFormValue("url", "");
            form.setFieldsValue({ url: "" });
            setFileList([]);
            setInvalidImage(false);
          }}
        >
          {t("actions.remove")}
        </a>
      </div>
    </Form.Item>
  );

  return (
    <>
      <Form.Item
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("mcs.messages.chat_apps.media_url.4")}
          </h3>
        }
        className="px-5"
      >
        {!invalidImage && form.getFieldsValue(false)?.url ? imagePreview : ""}
        <div
          className={
            !invalidImage && form.getFieldsValue(false)?.url ? "hidden" : ""
          }
        >
          {uploadInputs}
        </div>
      </Form.Item>
    </>
  );
};

export default CommonSnsForm;
