import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Slider,
  Row,
  Col,
  InputNumber,
  Upload,
  Radio,
  message,
  Spin,
  Switch,
  Button,
  notification,
} from "antd";

import { parsePhoneNumber } from "awesome-phonenumber";
import { useTranslation, Trans } from "react-i18next";

import { MinusCircleOutlined, LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

import { getUserCountryCode } from "@/utils/common";

import DTMFConditions from "@/json/dtmf-conditions.json";

import "./voice.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const VoiceCommonForm = (props) => {
  const {
    setRef,
    getRef,
    form,
    defaultValues,
    onDragStartTag,
    onDragOverInput,
    onDropInput,
    onUploadFile,
    voiceProfiles,
    languages,
    action,
    uploading,
  } = props;

  const getFormValue = (formName) => {
    if (form.getFieldsValue(false)[formName]) {
      return form.getFieldsValue(false)[formName];
    }

    return defaultValues[formName];
  };

  const [speedValue, setSpeedValue] = useState(0);
  const [file, setFile] = useState({});

  const { t } = useTranslation();

  const marks = {
    0: "0",
    0.5: "0.5",
    1: "1",
    1.5: "1.5",
    2: "2",
    2.5: "2.5",
    3.0: "3.0",
  };

  const uploadProps = {
    accept: "audio/wav,audio/x-wav,audio/mpeg,audio/x-mpeg-3",
    multiple: false,
    disabled: uploading,
    maxCount: 1,
    showUploadList: false,
    beforeUpload(file) {
      const isValidMimeType = [
        "audio/wav",
        "audio/mpeg",
        "audio/x-wav",
        "audio/x-mpeg-3",
      ].includes(file.type.toLowerCase());

      const isLt5120KB = file.size / 1024 / 1024 < 5.12;

      if (!isLt5120KB) {
        notification.error({
          message: t("upload_labels.file_must", { value: "5120KB" }),
        });
      }
      if (!isValidMimeType) {
        notification.error({
          message: t("upload_labels.file_mus_format", { value: "mp3 | wav" }),
        });
      }

      return isValidMimeType && isLt5120KB;
    },
    customRequest({ file, onSuccess, onError }) {
      onUploadFile(file)
        .then((url) => {
          setFile({ name: file.name, url, type: file.type });
          form.setFieldsValue({ fileUrl: url });
          onSuccess();
        })
        .catch((e) => {
          if (window.Bugsnag) {
            window.Bugsnag.notify(e);
          }
          onError();
          notification.error({
            message: t("upload_labels.file_upload_failed"),
          });
        });
    },
  };

  const filenameFromUrl = (url, defaultName = "Untitled") => {
    let name = defaultName;
    if (url && typeof url === "string") {
      const matches = url.match(/(?<=\/)[^\/\?#]+(?=[^\/]*$)/g);
      name = (matches || []).length ? matches[0] : name;
    }
    return name;
  };

  return (
    <>
      <Form.Item
        name="source"
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("column_labels.source")}
          </h3>
        }
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.source").toLowerCase(),
            }),
          },
          {
            validator(rule, value) {
              const isVariable = String(value || "").match(/^{{.*}}$/g);
              if (!isVariable && !parsePhoneNumber(value).valid) {
                return Promise.reject(
                  t("validations.invalid", {
                    value: t("fields.phone_number"),
                  })
                );
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          ref={setRef("source")}
          placeholder={t("automation.enter_or_drag")}
          onDragEnter={() => getRef("source").current.focus()}
          onDragOver={onDragOverInput}
          onDrop={(e) => onDropInput(e, "source")}
        />
      </Form.Item>
      <Form.Item
        name="destination"
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("column_labels.destinations").split("|")[0]}
          </h3>
        }
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.destinations").split("|")[0],
            }),
          },
          {
            validator(rule, value, callback) {
              const isVariable = String(value || "").match(/^{{.*}}$/g);
              if (!isVariable && !parsePhoneNumber(value).valid) {
                return Promise.reject(
                  t("validations.invalid", {
                    value: t("fields.phone_number"),
                  })
                );
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          ref={setRef("destination")}
          placeholder={t("automation.enter_or_drag")}
          onDragEnter={() => getRef("destination").current.focus()}
          onDragOver={onDragOverInput}
          onDrop={(e) => onDropInput(e, "destination")}
        />
      </Form.Item>
      {action.match(/say/i) && (
        <Form.Item
          name="text"
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              {t("column_labels.message")}
            </h3>
          }
          rules={[
            {
              required: true,
              message: t("validations.required", {
                value: t("column_labels.message").toLowerCase(),
              }),
            },
            {
              max: 3000,
              message: t("validations.char_limit", { value: 3000 }),
            },
          ]}
        >
          <TextArea
            rows={4}
            ref={setRef("text")}
            placeholder={t("automation.enter_or_drag")}
            onDragEnter={() => getRef("text").current.focus()}
            onDragOver={onDragOverInput}
            onDrop={(e) => onDropInput(e, "text")}
          />
        </Form.Item>
      )}
      {action.match(/say/i) && (
        <Form.Item
          name="language"
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              {t("column_labels.language").split("|")[0]}
            </h3>
          }
          rules={[
            {
              required: true,
              message: t("validations.required", {
                value: t("column_labels.language").split("|")[0],
              }),
            },
          ]}
        >
          <Select
            name="languages"
            showSearch
            placeholder={t("validations.select", {
              value: t("column_labels.language").split("|")[0].toLowerCase(),
            })}
            onChange={(value) => {
              const vp = voiceProfiles.find((v) => v.code === value);
              if (vp) form.setFieldsValue({ voiceProfile: vp.profile });
            }}
            className="w-60 block"
          >
            {languages.map((item) => (
              <Option value={item.code} key={item.code}>
                {item.code} <span class="font-medium ml-1">{item.name}</span>
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {action.match(/say/i) && (
        <Form.Item
          name="voiceProfile"
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              {t("column_labels.voice_profile").split("|")[0]}
            </h3>
          }
          rules={[
            {
              required: true,
              message: t("validations.required", {
                value: t("column_labels.voice_profile").split("|")[0],
              }),
            },
          ]}
        >
          <Select
            name="voiceProfiles"
            showSearch
            placeholder={t("validations.select", {
              value: t("column_labels.voice_profile")
                .split("|")[0]
                .toLowerCase(),
            })}
            onChange={(value) => {
              // console.log(value);
            }}
            className="w-60 block"
          >
            {voiceProfiles.map((item) => (
              <Option value={item.profile} key={item.profile}>
                {item.code} <span class="ml-1 font-medium">{item.profile}</span>
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {action.match(/say/i) && (
        <Form.Item
          name="speed"
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              {t("column_labels.speed")}
            </h3>
          }
        >
          <Row>
            <Col span={16}>
              <Slider
                min={0.5}
                max={3}
                marks={marks}
                onChange={(v) => {
                  setSpeedValue(parseFloat(v, 10));
                  form.setFieldsValue({ speed: parseFloat(v, 10) });
                }}
                value={
                  typeof (speedValue || getFormValue("speed")) === "number"
                    ? speedValue || getFormValue("speed")
                    : 0.5
                }
                step={0.01}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={0.5}
                max={3}
                style={{ margin: "0 16px" }}
                step={0.01}
                value={speedValue || getFormValue("speed")}
                onChange={(v) => {
                  if (isNaN(v)) return;
                  setSpeedValue(parseFloat(v, 10));
                  form.setFieldsValue({ speed: parseFloat(v, 10) });
                }}
              />
            </Col>
          </Row>
        </Form.Item>
      )}
      {(action.match(/say/i) || action === "playFile") && (
        <Form.Item
          name="repetition"
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              {t("column_labels.repetition")}
            </h3>
          }
          rules={[
            {
              required: true,
              message: t("validations.required", {
                value: t("column_labels.repetition"),
              }),
            },
          ]}
        >
          <Radio.Group>
            <Radio value={1} key={1}>
              {t("mcs.messages.voice.rep.1")}
            </Radio>
            <Radio value={2} key={2}>
              {t("mcs.messages.voice.rep.2", { no: 2 })}
            </Radio>
            <Radio value={3} key={3}>
              {t("mcs.messages.voice.rep.2", { no: 3 })}
            </Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {/* {action === "say&capture" && (
        <Form.Item
          name="dtmfCallbackUrl"
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              DTMF Callback Url
            </h3>
          }
          rules={[
            { required: true, message: "DTMF Callback Url is required!" },

            {
              validator(rule, value, callback) {
                const isValidUrl = value.match(
                  /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*))|^({{.*}})$/g
                );
                const isVariable = String(value || "").match(/^{{.*}}$/g);
                if (!isVariable && !isValidUrl) {
                  return Promise.reject("Invalid callback url");
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            className="w-full"
            ref={setRef("dtmfCallbackUrl")}
            placeholder="Enter a value or drag a variable from above"
            onDragEnter={() => getRef("dtmfCallbackUrl").current.focus()}
            onDragOver={onDragOverInput}
            onDrop={(e) => onDropInput(e, "dtmfCallbackUrl")}
          />
        </Form.Item>
      )} */}

      {action === "say&capture" && (
        <Row style={{ marginBottom: 0 }}>
          <Col span={12}>
            <Form.Item
              name="minDigits"
              label={
                <h3 class="text-gray-500 text-xs mb-1 font-bold">
                  {t("column_labels.minimum_digit")}
                </h3>
              }
              rules={[
                {
                  required: true,
                  message: t("validations.required", {
                    value: t("column_labels.minimum_digit"),
                  }),
                },
              ]}
            >
              <InputNumber style={{ width: "80%" }} min={1} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="maxDigits"
              label={
                <h3 class="text-gray-500 text-xs mb-1 font-bold">
                  {t("column_labels.maximum_digit")}
                </h3>
              }
              rules={[
                {
                  required: true,
                  message: t("validations.required", {
                    value: t("column_labels.minimum_digit"),
                  }),
                },
              ]}
            >
              <InputNumber style={{ width: "80%" }} min={1} />
            </Form.Item>
          </Col>
        </Row>
      )}

      {action === "say&capture" && (
        <Row style={{ marginBottom: 0, marginTop: 0 }}>
          <Col span={12}>
            <Form.Item
              name="digitTimeout"
              label={
                <h3 class="text-gray-500 text-xs mb-1 font-bold">
                  {t("column_labels.dtmf_timeout")} (ms)
                </h3>
              }
              rules={[
                {
                  required: true,
                  message: t("validations.required", {
                    value: t("column_labels.dtmf_timeout"),
                  }),
                },
              ]}
            >
              <InputNumber style={{ width: "80%" }} min={1} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="overallTimeout"
              label={
                <h3 class="text-gray-500 text-xs mb-1 font-bold">
                  {t("column_labels.call_timeout")} (ms)
                </h3>
              }
              rules={[
                {
                  required: true,
                  message: t("validations.required", {
                    value: t("column_labels.call_timeout").toLowerCase(),
                  }),
                },
              ]}
            >
              <InputNumber style={{ width: "80%" }} min={1} />
            </Form.Item>
          </Col>
        </Row>
      )}

      {action === "say&capture" && (
        <Row style={{ marginBottom: 0, marginTop: 0 }}>
          <Col span={12}>
            <Form.Item
              name="completeOnHash"
              label={
                <h3 class="text-gray-500 text-xs mb-1 font-bold">
                  {t("column_labels.complete_on_hash")}
                </h3>
              }
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="noOfTries"
              label={
                <h3 class="text-gray-500 text-xs mb-1 font-bold">
                  {t("column_labels.no_of_tries")}
                </h3>
              }
              rules={[
                {
                  required: true,
                  message: t("validations.required", {
                    value: t("column_labels.no_of_tries").toLowerCase(),
                  }),
                },
              ]}
            >
              <InputNumber style={{ width: "80%" }} min={1} />
            </Form.Item>
          </Col>
        </Row>
      )}

      {action.match(/say&capture/i) && (
        <Form.Item
          style={{ marginTop: 0 }}
          name="endMessage"
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              {t("column_labels.end_message")}
            </h3>
          }
        >
          <TextArea
            rows={2}
            ref={setRef("endMessage")}
            placeholder={t("automation.enter_or_drag")}
            onDragEnter={() => getRef("endMessage").current.focus()}
            onDragOver={onDragOverInput}
            onDrop={(e) => onDropInput(e, "endMessage")}
          />
        </Form.Item>
      )}

      {action === "playFile" && (
        <Form.Item
          name="fileUrl"
          rules={[
            {
              required: true,
              message: t("validations.required", {
                value: t("column_labels.audio_url").toLowerCase(),
              }),
            },
            {
              pattern: new RegExp(
                /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*))|^({{.*}})$/g
              ),
              message: t("validations.required", {
                value: t("column_labels.audio_url").toLowerCase(),
              }),
            },
          ]}
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              {t("column_labels.audio_url")}
            </h3>
          }
        >
          {/* <div>
            <Upload.Dragger {...uploadProps}>
              <Spin indicator={antIcon} spinning={uploading}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="text-sm">
                  Click or drag audio file to this area to upload
                </p>
                <p className="mt-2 text-xs text-gray">
                  Supported file types: .wav, .mp3 (Max file size: 5120 KB)
                </p>
              </Spin>
            </Upload.Dragger>
            {Object.keys(file).length || getFormValue("fileUrl") ? (
              <div class="flex text-xs mt-2">
                <p class="w-3/4 truncate">
                  {file.name
                    ? file.name
                    : getFormValue("fileUrl")
                    ? filenameFromUrl(getFormValue("fileUrl"))
                    : ""}
                </p>
                <p class="w-1/4 text-right">
                  <span
                    role="button"
                    tabIndex={0}
                    class="material-icons-outlined cursor-pointer text-gray hover:text-red text-xs"
                    onClick={() => {
                      setFile({});
                      form.setFieldsValue({ fileUrl: "" });
                    }}
                  >
                    delete
                  </span>
                </p>
              </div>
            ) : null}
          </div> */}
          <div>
            <Input
              placeholder="https://myfile.com/audio.wav"
              className="w-full"
            />
            <p className="mt-2 text-xs text-gray">
              {t("automation.post_the_url")}
            </p>
            <p className="mt-1 text-xs text-gray">
              {t("upload_labels.supported_types")}: .wav, .mp3 (
              {t("mcs.messages.chat_apps.upload_media.5")}: 5120 KB)
            </p>
          </div>
        </Form.Item>
      )}
      {action === "say&capture" && (
        <Form.List
          name="dtmfValues"
          rules={[
            {
              validator: async (_, dtmfValues) => {
                const concatValues = dtmfValues.reduce((a, b) => {
                  a.push(`${b.condition}_${b.value || "null"}`);

                  return a;
                }, []);

                const hasDuplicates = concatValues.some(
                  (e, i, arr) => arr.indexOf(e) !== i
                );

                if (hasDuplicates) {
                  return Promise.reject(
                    new Error(t("automation.condition_and_value"))
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            /**
             * `fields` internal fill with `name`, `key`, `fieldKey` props.
             * You can extends this into sub field to support multiple dynamic fields.
             */
            return (
              <div>
                <h3 class="text-gray-500 text-xs mb-1 font-bold">
                  {t("column_labels.dtmf_inputs")}
                </h3>
                {fields.map((field, index) => (
                  <Row
                    key={field.key}
                    gutter={10}
                    className="bg-gray-50 px-5 pt-3"
                  >
                    <Col span={14}>
                      <Form.Item
                        name={[field.name, "condition"]}
                        fieldKey={[field.fieldKey, "condition"]}
                        rules={[
                          {
                            required: true,
                            message: `DTMF ${t("validations.required", {
                              value: t("automation.condition"),
                            })}`,
                          },
                        ]}
                      >
                        <Select
                          name="dtmfValues"
                          showSearch
                          placeholder={t("validations.select", {
                            value: t("automation.condition").toLowerCase(),
                          })}
                          className="w-60 block"
                        >
                          {DTMFConditions.map((item) => (
                            <Option value={item.condition} key={item.id}>
                              DTMF {t(item.labelts).toLowerCase()}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={[field.name, "value"]}
                        fieldKey={[field.fieldKey, "value"]}
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      {fields.length !== 1 ? (
                        <MinusCircleOutlined
                          className="text-gray-300 hover:text-red-500 text-base"
                          style={{ marginTop: "11px", color: "#ccc" }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                ))}
                <Form.ErrorList errors={errors} />
                <Form.Item noStyle>
                  {fields.length < 20 ? (
                    <Button
                      type="dashed"
                      size="small"
                      className="flex items-center mt-5 text-xs"
                      onClick={() => {
                        add();
                      }}
                    >
                      <i className="material-icons text-base mr-1">add</i>
                      {t("automation.add_another_dtmf")}
                    </Button>
                  ) : null}
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      )}
    </>
  );
};

export default VoiceCommonForm;
