import React from "react";
import { Row, Col, Statistic, Popover, Card } from "antd";
import { useTranslation, Trans } from "react-i18next";

export default function Usage(props) {
  const { loading, usage } = props;

  const { allowedWorkflowCount, executedWorkflowCount } = usage;

  const remainingWorflowCount = parseInt(
    allowedWorkflowCount - executedWorkflowCount,
    10
  );

  const { t } = useTranslation();

  const displayUnit = (unit) => {
    if (!unit) return 0;
    const u = parseInt(unit, 10);

    return unit.toLocaleString();
  };

  const remainingInPercent = (remaining = 0, allowed = 0) => {
    const percentage = 100 - parseFloat((allowed - remaining) / allowed) * 100;

    return Number(percentage).toFixed(2);
  };

  const Content = () => {
    return (
      <p style={{ width: "290px" }}>
        {t("automation.usage.0")} <br /> {t("automation.usage.1")}{" "}
        <a href="mailto:cpaas-sales@8x8.com" target="_blank">
          cpaas-sales@8x8.com
        </a>
        .
      </p>
    );
  };

  return (
    <div className="">
      <div class="flex">
        <div class="flex text-grey-dark">
          <div class="">
            <div class="mb-2 font-semibold">
              {t("automation.allowed_worflow")}
            </div>
            <div class="font-medium">
              <Popover
                content={<Content />}
                title={
                  <div className="text-base">
                    {t("automation.allowed_worflow")}
                  </div>
                }
              >
                <span className="text-blue hover:text-blue-lighter text-sm cursor-pointer font-medium">
                  <i class="icon-novo-info-circle text-xl font-semibold mr-1 align-middle" />
                  {t("app_labels.more_info_short")}
                </span>
              </Popover>
            </div>
          </div>
          <div class="ml-8">
            <div class="mb-1">{t("automation.remaining")}</div>
            <div class="font-medium text-xl">
              <span class="align-middle text-black">
                {displayUnit(remainingWorflowCount)}
              </span>
              <span class="rounded bg-green-darkest text-white px-1 py-1 align-middle text-xs ml-2">
                {remainingInPercent(
                  remainingWorflowCount,
                  allowedWorkflowCount
                )}{" "}
                %
              </span>
            </div>
          </div>
          <div class="ml-8">
            <div class="mb-1">{t("automation.total_allowed")}</div>
            <div class="font-medium text-xl">
              <span class="align-middle text-black">
                {displayUnit(allowedWorkflowCount)}{" "}
              </span>
            </div>
          </div>
          <div class="ml-8">
            <div class="mb-1">{t("automation.executed")}</div>
            <div class="font-medium text-xl">
              <span class="align-middle text-black">
                {displayUnit(executedWorkflowCount)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
