import { Form, Input, Button, Select, Row, Col } from "antd";
import { parsePhoneNumber } from "awesome-phonenumber";
import countries from "@/json/countries.json";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useTranslation, Trans } from "react-i18next";

const { Option } = Select;

const CountryCode = (props) => {
  const isSelectedCountry = (country) => {
    const cts = props.form.getFieldValue("countries");

    return cts.some((v) => v && v.country && v.country === country);
  };

  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name="mobile"
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("automation.mobile_no_to_check")}{" "}
            <span class="text-gray-300 italic" style={{ fontSize: "10px" }}>
              {" "}
              {t("automation.int_format")} e.g +6596728122)
            </span>
          </h3>
        }
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.mobile_no"),
            }),
          },
          {
            validator(rule, value, callback) {
              const isVariable = String(value || "").match(/^{{.*}}$/g);
              if (!isVariable && !parsePhoneNumber(value).valid) {
                return Promise.reject(
                  t("validations.invalid", { value: t("column_labels.mobile") })
                );
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          ref={props.setRef("mobile")}
          placeholder={t("automation.enter_a_valid_or_drag", {
            value: t("column_labels.mobile_no").toLowerCase(),
          })}
          onDragEnter={() => props.getRef("mobile").current.focus()}
          onDragOver={props.onDragOverInput}
          onDrop={(e) => props.onDropInput(e, "mobile")}
        />
      </Form.Item>
      <Form.List name="countries" noStyle>
        {(fields, { add, remove }) => {
          /**
           * `fields` internal fill with `name`, `key`, `fieldKey` props.
           * You can extends this into sub field to support multiple dynamic fields.
           */
          return (
            <div>
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("automation.choose_country.0")} <br />
                {t("automation.choose_country.1")}
              </h3>
              <p class="text-gray-300 text-xs mb-1">
                {t("automation.add_a_country")}
              </p>
              {fields.map((field, index) => (
                <Row
                  key={field.key}
                  gutter={10}
                  className="bg-gray-50 px-5 pt-3"
                >
                  <Col span={22}>
                    <Form.Item
                      label=""
                      name={[field.name, "country"]}
                      fieldKey={[field.fieldKey, "country"]}
                      rules={[
                        {
                          required: true,
                          message: t("validations.required", {
                            value: t("column_labels.mobile_no"),
                          }).toLowerCase(),
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        showSearch
                        bordered={false}
                        placeholder={t("validations.select", {
                          value: t("column_labels.country").toLowerCase(),
                        })}
                        optionFilterProp="children"
                        onChange={() => {}}
                        filterOption={(input, option) => {
                          return (
                            option.children[1]
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        className="w-full text-xs px-0 py-2"
                        style={{
                          borderBottom: "1px solid #D1D5DB",
                          borderRadius: 0,
                        }}
                      >
                        {countries.map((v, i) => (
                          <Option
                            value={v.value}
                            key={`${v.callingCode}_${v.name}_${i}`}
                            disabled={isSelectedCountry(v.value)}
                          >
                            <span
                              class={`flag-icon flag-icon-${v.value.toLowerCase()} flag-icon-squared mr-1`}
                            />
                            {v.name}
                            <span class="ml-1 text-gray-300 text-xs">
                              ({v.callingCode})
                            </span>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    {fields.length !== 1 ? (
                      <MinusCircleOutlined
                        className="text-gray-300 hover:text-red-500 text-base"
                        style={{ marginTop: "17px", color: "#ccc" }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
              ))}
              <Form.Item noStyle>
                {fields.length < 5 ? (
                  <Button
                    type="dashed"
                    size="small"
                    className="flex items-center mt-5 text-xs"
                    onClick={() => {
                      add();
                    }}
                  >
                    <i className="material-icons text-base mr-1">add</i>
                    {t("automation.add_another_country")}
                  </Button>
                ) : null}
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default CountryCode;
