import { Popconfirm, Tooltip, Button } from "antd";
import { useHistory, useLocation, Prompt } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateElements } from "@/store/reducers/flow";
import _ from "lodash";

import { useTranslation, Trans } from "react-i18next";

const Headers = ({
  elements,
  originalElements,
  className,
  handleSave,
  loading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const handleGoBack = () => {
    history.push("/");
  };

  const [definitionName, setDefinitionName] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  // const [isBlocking, setBlocking] = useState(false);

  const titleInputRef = useRef(null);

  const handleChangeTitle = _.debounce((e) => {
    if (!Object.keys(elements || {}).length) return;
    const name = e.target.value;

    // If empty set it to it's previous value
    if (!name.trim()) {
      titleInputRef.current.value = definitionName;
      // console.log("HELLO: ", titleInputRef.current);
      return;
    }

    const newTrigger = { ...(elements["trigger"] || {}) };
    newTrigger.data = {
      ...newTrigger.data,
      name,
    };

    setDefinitionName(definitionName);
    dispatch(updateElements({ ["trigger"]: newTrigger }));
  }, 1000);

  useEffect(() => {
    if (Object.keys(elements || {}).length) {
      setDefinitionName(elements["trigger"].data?.name);
    }
  }, [elements]);

  useEffect(() => {
    if (
      !Object.keys(elements || {}).length ||
      !Object.keys(originalElements || {}).length
    )
      return;

    const elementsJson = JSON.stringify(elements);
    const originalElementsJson = originalElements;

    const sameJson = elementsJson === originalElementsJson;
    setIsUpdated(!sameJson);
  }, [elements, originalElements]);

  // history.listen((l) => {
  //   console.log('location', location.pathname);

  //   if (l.pathname !== location.pathname) {
  //     let unblock = history.block((tx) => {
  //       // Navigation was blocked! Let's show a confirmation dialog
  //       // so the user can decide if they actually want to navigate
  //       // away and discard changes they've made in the current page.
  //       let url = tx.location.pathname;
  //       if (window.confirm(`Are you sure you want to go to ${url}?`)) {
  //         // Unblock the navigation.
  //         unblock();

  //         // Retry the transition.
  //         tx.retry();
  //       }
  //     });
  //   }
  // });

  return (
    <header
      className={`py-4 px-5 absolute left-0 z-50 flex items-center space-x-1 ${className}`}
      style={{ top: "-8px" }}
    >
      <nav className="flex items-center">
        {isUpdated ? (
          <Popconfirm
            placement="bottom"
            title={`${t("app_labels.leave_this_page")}?`}
            onConfirm={handleGoBack}
            okText={t("app_labels.leave_and_discard")}
            cancelText={t("actions.cancel")}
          >
            <Button disabled={loading} type="default" className="mr-3">
              <i class="icon-novo-arrow-left-long text-xl vertical-align" />
            </Button>
          </Popconfirm>
        ) : (
          <Button
            disabled={loading}
            type="default"
            onClick={handleGoBack}
            className="mr-3"
          >
            <i class="icon-novo-arrow-left-long text-xl vertical-align" />
          </Button>
        )}
        <Button
          type="primary"
          disabled={loading || !isUpdated}
          onClick={() => {
            if (!loading) {
              handleSave();
            }
          }}
          className="mr-3"
        >
          {loading ? (
            <>
              <svg
                className="animate-spin mr-1 h-5 w-5 text-indigo-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              {t("wait.loading")}
            </>
          ) : (
            <span className="px-3">{t("actions.save")}</span>
          )}
        </Button>
      </nav>
      <div className="w-72 ml-2">
        <h1 className="text-base font-bold text-gray-900 leading-none select-none truncate">
          {definitionName}
        </h1>
        {elements?.trigger?.data?.id ? (
          <Tooltip
            placement="top"
            title={`Definition id: ${elements?.trigger?.data?.id}`}
          >
            <code className="text-xs text-green-500 rounded-full">
              {elements?.trigger?.data?.id}
            </code>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    </header>
  );
};

export default Headers;
