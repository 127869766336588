import React, { useState, useEffect, useReducer, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Form,
  Button,
  Input,
  TimePicker,
  Select,
  Tabs,
  Tooltip,
  Popconfirm,
  message,
  notification,
} from "antd";
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

import { useTranslation, Trans } from "react-i18next";

import Variables from "@/components/variables";

const format = "HH:mm:ss";

const requestMethods = {
  GET: {
    color: "text-indigo-500",
  },
  POST: {
    color: "text-green-400",
  },
  PUT: {
    color: "text-yellow-400",
  },
  PATCH: {
    color: "text-yellow-400",
  },
  DELETE: {
    color: "text-red-400",
  },
  OPTIONS: {
    color: "text-blue-500",
  },
  HEAD: {
    color: "text-blue-500",
  },
};

const ACTIONS = {
  INITIALIZE_FORM: "initialize-form",
  UPDATE_VALUE: "update-value",
};

function reducer(formData, action) {
  const { item, value } = action.payload;
  switch (action.type) {
    case ACTIONS.INITIALIZE_FORM:
      return { ...action.payload };
    case ACTIONS.UPDATE_VALUE:
      return { ...formData, [item]: value };
    default:
      return formData;
  }
}

const HttpRequestForm = ({
  node,
  onUpdateNodeValue,
  sidebarScrollWrapper,
  onDeleteNode,
  stepNameList,
  onUpdateStepNameList,
}) => {
  const [form] = Form.useForm();
  const { elements } = useSelector((state) => state.flow);
  const { t } = useTranslation();

  const outputDataInfo = {
    responseCode: t("automation.http_output.response_code"),
    reasonPhrase: t("automation.http_output.response_phrase"),
    responseBody: t("automation.http_output.response_body"),
  };

  const urlInputRef = useRef(null);
  const headerValuesInputRef = useRef([]);
  const outputValuesInputRef = useRef([]);
  const parametersValuesInputRef = useRef([]);
  const bodyInputRef = useRef(null);

  const [toggleExpandOutput, setToggleExpandOutput] = useState(false);
  const [formData, dispatch] = useReducer(reducer, {});

  // Set 'formData' to the values of 'form instance' on mount
  useEffect(() => {
    dispatch({
      type: ACTIONS.INITIALIZE_FORM,
      payload: form.getFieldsValue(true),
    });

    // Set scrollTop to 0
    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Re-render fields if selectedNode is updated but has same type with previous selectedNode
  useEffect(() => {
    form.resetFields();

    sidebarScrollWrapper.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [node]);

  // useEffect(() => {
  //   return () => {
  //     if (Object.keys(formData).length) {
  //       confirm({
  //         title: "Are you sure you want to discard your changes?",
  //         onOk() {
  //           console.log("DISCARDING CHANGING");
  //         },
  //       });
  //     }
  //   };
  // }, [formData]);

  const onChangeFormValue = (item, value) => {
    dispatch({
      type: ACTIONS.UPDATE_VALUE,
      payload: { item, value },
    });
  };

  const onSubmitForm = (v) => {
    form
      .validateFields()
      .then((formVal) => {
        // Update store node data
        const { id } = node;
        const newData = { ...elements[id] };
        const { outputs, ...v } = formVal;
        let values = v;

        // Try to parse body into JSON
        try {
          values = {
            ...v,
            body: JSON.parse(v.body),
            timeoutSeconds: moment
              .duration(v.timeoutSeconds.format("HH:mm:ss"), "HH:mm:ss")
              .asSeconds(),
            headers: (v?.headers || []).reduce((acc, curr) => {
              return {
                ...acc,
                [curr.key]: curr.value,
              };
            }, {}),
            parameters: (v?.parameters || []).reduce((acc, curr) => {
              return {
                ...acc,
                [curr.key]: curr.value,
              };
            }, {}),
          };

          let newNodeValue = {
            inputs: { ...newData.inputs, ...values },
            outputs: (outputs || []).reduce((acc, curr) => {
              let outputObj = { [curr.key]: curr.value };
              try {
                const valueJson = JSON.parse(curr.value);
                if (valueJson) {
                  outputObj = {
                    [curr.key]: { ...valueJson },
                  };
                }
              } finally {
                return {
                  ...acc,
                  ...outputObj,
                };
              }
            }, {}),
          };

          // newNodeValue = {
          //   ...newNodeValue.outputs,
          //   // [`${formVal.nameId}_step_httpCode`]: "{{step.responseCode}}",
          //   // [`${formVal.nameId}_step_httpReasonPhrase`]: "{{step.reasonPhrase}}",
          //   // [`${formVal.nameId}_step_httpResponsee`]: "{{step.responseBody}}",
          // };

          // console.log("VALUES: ", values);
          onUpdateNodeValue({ id, ...newNodeValue });

          // Update the master list of step names
          let nameList = [...stepNameList];
          const arrayIndex = nameList.indexOf(node?.data?.nameId || id);
          nameList.splice(arrayIndex, 1, values?.nameId);
          onUpdateStepNameList(nameList);
        } catch (e) {
          // form.setFields({
          //   body: {
          //     value: "ERROR",
          //     errors: [new Error("Invalid JSON format!")],
          //   },
          // });
          console.error(e);
          if (window.Bugsnag) {
            window.Bugsnag.notify(e);
          }
        }
      })
      .catch((e) => {
        console.error(e);
        notification.error({
          message: t("errors.unable_to_update", {
            value: t("app_labels.form"),
          }),
        });
        if (window.Bugsnag) {
          window.Bugsnag.notify(e);
        }
      });
  };

  const onTabTextArea = (e, params) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const { fieldItem, value } = params;
      const { selectionStart: start, selectionEnd: end } = e.target;
      const newValue = value.substring(0, start) + "\t" + value.substring(end);
      form.setFieldsValue({ [fieldItem]: newValue });
      onChangeFormValue(fieldItem, newValue);
    }
  };

  const onSubmitFormFail = (values) => {
    // console.log("Failed to submit form");
  };

  const onConfirmDelete = () => {
    onDeleteNode(node.id || "");
  };

  const onDragStartTag = (e) => {
    e.dataTransfer.setData("variable", e.target.dataset.value);
  };

  const onDragOverInput = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDropInput = (e, fieldItem) => {
    e.preventDefault();
    const variable = e.dataTransfer.getData("variable");
    const { value, selectionEnd, selectionStart } = e.target;
    const newValue =
      value.slice(0, selectionStart) + variable + value.slice(selectionEnd);

    // Update values
    form.setFieldsValue({ [fieldItem]: newValue });
    onChangeFormValue(fieldItem, newValue);
  };

  const [variables, setVariables] = useState({});

  // Get all outputs from parent nodes and set it as `variables`
  useEffect(() => {
    setVariables({ currId: node.id, elements, currOutputs: formData.outputs });
  }, [node, elements, formData.outputs]);

  return (
    <>
      <header className="bg-gradient-to-tr from-blue-500 to-indigo-500 px-5 pt-7 pb-14 w-96">
        <small className="text-xs text-red-200 tracking-widest">
          {t("automation.http_request_config")}
        </small>
        <h1 className="font-bold  text-lg text-white tracking-wider pr-20 leading-snug">
          {t("automation.modify_what_api")}
        </h1>
      </header>
      <section className="bg-gray-100 p-5 w-96">
        <div
          className="bg-white flex flex-col rounded shadow -mt-14"
          style={{ height: toggleExpandOutput ? "auto" : "150px" }}
        >
          <div className="overflow-hidden py-3 px-5 flex flex-col">
            <h2 className="font-bold text-sm text-gray-900 flex items-center">
              <span className="text-white bg-8x8-red-500 w-8 h-8 flex justify-center items-center rounded mr-2 shadow">
                <i className="material-icons text-xl text-black">data_object</i>
              </span>
              {t("automation.expected_output")}
            </h2>
            <p className="mt-3 text-xs text-gray-500">
              {t("automation.properties_of_step")}
            </p>
            <ul className="list-none mt-3 space-y-2">
              {Object.keys(outputDataInfo).map((info) => (
                <li
                  className="leading-loose text-sm text-gray-800"
                  key={`info-${info}`}
                >
                  <code className="font-mono text-xs p-1 mr-1 bg-yellow-100 text-yellow-800 rounded-sm inline-block">
                    {info}
                  </code>{" "}
                  - {outputDataInfo[info]}
                </li>
              ))}
            </ul>
            <a
              className="mt-5 text-xs text-indigo-500 inline-block ml-auto"
              target="_blank"
              href="https://developer.8x8.com/connect/reference/get-webhooks-2"
            >
              {t("automation.http_request_docu")}
            </a>
          </div>
          <div className="-mb-3 flex justify-center relative">
            <div className="-mt-3 p-2 w-full bg-opacity-50 absolute bottom-6 rounded-b bg-gradient-to-t from-white via-white to-transparent" />
            <Button
              type="dashed"
              size="small"
              shape="round"
              className="text-xs"
              onClick={() => setToggleExpandOutput(!toggleExpandOutput)}
            >
              {toggleExpandOutput
                ? t("app_labels.hide")
                : t("app_labels.show_more")}
            </Button>
          </div>
        </div>
      </section>
      <section className="w-96">
        <div className="bg-gray-100 p-5 pb-0">
          <h3 className="text-blue-500 text-xs mb-1 font-bold">
            {t("automation.pick_and_drag")}
          </h3>
          <p className="text-sm text-gray-800">
            {t("automation.pick_and_drag")}{" "}
            <strong>{t("column_labels.headers")}</strong>,{" "}
            <strong>{t("column_labels.queries").split("|")[1]}</strong>,{" "}
            {t("app_labels.or")}{" "}
            <strong>{t("column_labels.request_body")}</strong>
          </p>
        </div>
        <Variables
          className="bg-gray-100 p-4 sticky top-0 z-10"
          variables={variables}
          handleDragStart={onDragStartTag}
        />
        <Form
          form={form}
          layout="vertical"
          name="http-form"
          autoComplete="off"
          onFinish={onSubmitForm}
          onFinishFailed={onSubmitFormFail}
          requiredMark={true}
          scrollToFirstError
          className="space-y-5 p-5"
        >
          <Form.Item
            name="nameId"
            initialValue={
              node?.data?.nameId || formData?.nameId || node.id || ""
            }
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
                {t("automation.step")} {t("column_labels.name").toLowerCase()}
              </h3>
            }
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: `${t("automation.step")} ${t("column_labels.name")}`,
                }),
              },
              {
                required: true,
                pattern: new RegExp(/^[a-z0-9].*[a-z0-9]$/g),
                message: t("automation.ame_start_end"),
              },
              {
                validator(rule, value) {
                  let nameList = [...stepNameList];
                  // const arrayIndex = nameList.indexOf(node?.data?.nameId);

                  // Remove current node name in the array to exclude from exist check
                  nameList = nameList.filter((v) => v !== node?.data?.nodeId);

                  if (nameList.includes(value)) {
                    return Promise.reject(
                      t("validations.already_exists", {
                        value: `${t("automation.step")} ${t(
                          "column_labels.name"
                        ).toLowerCase()}`,
                      })
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder={t("validations.valid", {
                value: `${t("automation.step")} ${t(
                  "column_labels.name"
                ).toLowerCase()}`,
              })}
              onChange={(e) => {
                const newValue = String(e.target.value)
                  .toLowerCase()
                  .replace(/ /g, "_");
                form.setFieldsValue({ nameId: newValue.trim() });
                onChangeFormValue("nameId", newValue.trim());
              }}
            />
          </Form.Item>
          <Form.Item
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold">
                {t("column_labels.url")}
              </h3>
            }
          >
            <div className="flex flex-nowrap space-x-1">
              <Form.Item
                name="method"
                initialValue={node?.data?.method || form?.method || "GET"}
                rules={[
                  {
                    required: true,
                    message: t("validations.required", {
                      value: t("automation.request_method"),
                    }),
                  },
                ]}
                noStyle
              >
                <Select
                  name="method"
                  onChange={(value) => onChangeFormValue("method", value)}
                  style={{ width: "30%" }}
                  className={
                    requestMethods[form.getFieldsValue(true).method]?.color
                  }
                >
                  {Object.keys(requestMethods).map((item) => (
                    <Option
                      value={item}
                      key={`request-option-${item}`}
                      className={requestMethods[item].color}
                    >
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="url"
                initialValue={node?.data?.url || form?.url || ""}
                rules={[
                  {
                    required: true,
                    message: t("validations.required", {
                      value: t("column_labels.url"),
                    }),
                  },
                  {
                    required: true,
                    pattern: new RegExp(
                      /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*))|^({{.*}})$/g
                    ),
                    message: t("validations.invalid", {
                      value: t("column_labels.url"),
                    }),
                  },
                ]}
                noStyle
              >
                <Input
                  ref={urlInputRef}
                  placeholder="https://api.myproduct.com/v1"
                  className="w-full"
                  onChange={(e) => onChangeFormValue("url", e.target.value)}
                  onDragEnter={() => urlInputRef.current.focus()}
                  onDragOver={onDragOverInput}
                  onDrop={(e) => onDropInput(e, "url")}
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Tabs
            defaultActiveKey="1"
            size="small"
            className="-mx-5 text-sm"
            tabBarStyle={{ margin: "0 1.25rem 0" }}
          >
            <TabPane
              tab={
                <span className="text-sm">
                  {t("column_labels.header")}
                  {form.getFieldsValue(true)?.headers?.length ? (
                    <small
                      className="ml-1 bg-yellow-100 rounded-sm text-yellow-700 text-xs"
                      style={{ lineHeight: 0, padding: "1px 7px" }}
                    >
                      {form.getFieldsValue(true)?.headers?.length}
                    </small>
                  ) : (
                    ""
                  )}
                </span>
              }
              key="1"
              forceRender
            >
              <Form.Item className="bg-gray-50 p-5">
                <Form.List
                  name="headers"
                  initialValue={Object.keys(node?.data?.headers || []).map(
                    (key) => {
                      return {
                        key,
                        value: node?.data?.headers[key],
                      };
                    }
                  )}
                >
                  {(headersData, { add, remove }) => (
                    <div className="w-full">
                      {headersData.map((header, i) => (
                        <div
                          key={header.key}
                          className="w-full flex flex-nowrap space-x-2"
                        >
                          <Form.Item
                            name={[i, "key"]}
                            className="w-full"
                            rules={[
                              {
                                required: true,
                                message: t("validations.required", {
                                  value: t("column_labels.key"),
                                }),
                              },
                              {
                                validator(rule, value) {
                                  const headers = form
                                    .getFieldsValue(true)
                                    .headers.map((i) => i.key);
                                  const dupHeaders = headers.filter(
                                    (e, i, a) => a.indexOf(e) !== i
                                  );
                                  if (dupHeaders.includes(value)) {
                                    return Promise.reject(
                                      t("automation.duplicate_header", {
                                        item: t(
                                          "column_labels.header"
                                        ).toLowerCase(),
                                      })
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) =>
                                onChangeFormValue("headers", [
                                  ...form.getFieldsValue(true).headers,
                                  { key: e.target.value },
                                ])
                              }
                              placeholder="key"
                              className="w-full text-xs px-0 py-2"
                              bordered={false}
                              style={{
                                borderBottom: "1px solid #D1D5DB",
                                borderRadius: 0,
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[i, "value"]}
                            className="w-full"
                            rules={[
                              {
                                required: true,
                                message: t("validations.required", {
                                  value: t("column_labels.value"),
                                }),
                              },
                            ]}
                          >
                            <Input
                              ref={(el) =>
                                (headerValuesInputRef.current[header.key] = el)
                              }
                              onChange={(e) =>
                                onChangeFormValue("headers", [
                                  ...form.getFieldsValue(true).headers,
                                  { value: e.target.value },
                                ])
                              }
                              onDragEnter={() =>
                                headerValuesInputRef.current[header.key].focus()
                              }
                              onDragOver={onDragOverInput}
                              onDrop={(e, formItem = "headers") => {
                                e.preventDefault();
                                const variable =
                                  e.dataTransfer.getData("variable");
                                const { value, selectionEnd, selectionStart } =
                                  e.target;
                                const newValue =
                                  value.slice(0, selectionStart) +
                                  variable +
                                  value.slice(selectionEnd);
                                const newArrayValue = [
                                  ...form.getFieldsValue(true)[formItem],
                                ];
                                newArrayValue[header.name].value = newValue;
                                onChangeFormValue(formItem, newArrayValue);
                                form.setFieldsValue({
                                  [formItem]: [...newArrayValue],
                                });
                              }}
                              placeholder="value"
                              className="w-full text-xs px-0 py-2"
                              bordered={false}
                              style={{
                                borderBottom: "1px solid #D1D5DB",
                                borderRadius: 0,
                              }}
                            />
                          </Form.Item>
                          <Form.Item>
                            <i
                              role="button"
                              tabIndex={0}
                              className="cursosr-pointer material-icons mr-1 text-base text-gray-300 hover:text-red-500"
                              onClick={() => {
                                // Remove item in form
                                remove(header.name);
                                const newHeaders = [
                                  ...form.getFieldsValue(true).headers,
                                ];
                                newHeaders.splice(header.key, 1);
                                onChangeFormValue("headers", newHeaders);
                              }}
                            >
                              delete_outline
                            </i>
                          </Form.Item>
                        </div>
                      ))}
                      <Form.Item noStyle>
                        <Button
                          type="dashed"
                          size="small"
                          className="flex items-center"
                          onClick={() => {
                            const newArrayValue = [
                              ...form.getFieldsValue(true).headers,
                            ];
                            newArrayValue.push({ key: "", value: "" });
                            onChangeFormValue("headers", newArrayValue);
                            form.setFieldsValue({
                              headers: [...newArrayValue],
                            });
                          }}
                        >
                          <i className="material-icons text-base mr-1">add</i>
                          {t("app_labels.new")} {t("column_labels.header")}
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </Form.Item>
            </TabPane>
            <TabPane
              tab={
                <span className="text-sm">
                  {t("column_labels.queries").split("|")[0]}
                  {form.getFieldsValue(true)?.parameters?.length ? (
                    <small
                      className="ml-1 bg-yellow-100 rounded-sm text-yellow-700 text-xs"
                      style={{ lineHeight: 0, padding: "1px 7px" }}
                    >
                      {form.getFieldsValue(true)?.parameters?.length}
                    </small>
                  ) : (
                    ""
                  )}
                </span>
              }
              key="2"
              forceRender
            >
              <Form.Item className="bg-gray-50 p-5">
                <Form.List
                  name="parameters"
                  initialValue={Object.keys(node?.data?.parameters || []).map(
                    (key) => {
                      return {
                        key,
                        value: node?.data?.parameters[key],
                      };
                    }
                  )}
                >
                  {(parametersData, { add, remove }) => (
                    <div className="w-full">
                      {parametersData.map((parameter, i) => (
                        <div
                          key={parameter.key}
                          className="w-full flex flex-nowrap space-x-2"
                        >
                          <Form.Item
                            name={[i, "key"]}
                            className="w-full"
                            rules={[
                              {
                                required: true,
                                message: t("validations.required", {
                                  value: t("column_labels.key"),
                                }),
                              },
                              {
                                validator(rule, value) {
                                  const parameters = form
                                    .getFieldsValue(true)
                                    .parameters.map((i) => i.key);
                                  const dupParameters = parameters.filter(
                                    (e, i, a) => a.indexOf(e) !== i
                                  );
                                  if (dupParameters.includes(value)) {
                                    return Promise.reject(
                                      t("automation.duplicate_header", {
                                        item: t("column_labels.queries")
                                          .split("|")[0]
                                          .toLowerCase(),
                                      })
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) =>
                                onChangeFormValue("parameters", [
                                  ...form.getFieldsValue(true).parameters,
                                  { key: e.target.value },
                                ])
                              }
                              placeholder="key"
                              className="w-full text-xs px-0 py-2"
                              bordered={false}
                              style={{
                                borderBottom: "1px solid #D1D5DB",
                                borderRadius: 0,
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[i, "value"]}
                            className="w-full"
                            rules={[
                              {
                                required: true,
                                message: t("validations.required", {
                                  value: t("column_labels.value"),
                                }),
                              },
                            ]}
                          >
                            <Input
                              ref={(el) =>
                                (parametersValuesInputRef.current[
                                  parameter.key
                                ] = el)
                              }
                              onChange={(e) =>
                                onChangeFormValue("parameters", [
                                  ...form.getFieldsValue(true).parameters,
                                  { value: e.target.value },
                                ])
                              }
                              onDragEnter={() =>
                                parametersValuesInputRef.current[
                                  parameter.key
                                ].focus()
                              }
                              onDragOver={onDragOverInput}
                              onDrop={(e, formItem = "parameters") => {
                                e.preventDefault();
                                const variable =
                                  e.dataTransfer.getData("variable");
                                const { value, selectionEnd, selectionStart } =
                                  e.target;
                                const newValue =
                                  value.slice(0, selectionStart) +
                                  variable +
                                  value.slice(selectionEnd);
                                const newArrayValue = [
                                  ...form.getFieldsValue(true)[formItem],
                                ];
                                newArrayValue[parameter.name].value = newValue;
                                onChangeFormValue(formItem, newArrayValue);
                                form.setFieldsValue({
                                  [formItem]: [...newArrayValue],
                                });
                              }}
                              placeholder="value"
                              className="w-full text-xs px-0 py-2"
                              bordered={false}
                              style={{
                                borderBottom: "1px solid #D1D5DB",
                                borderRadius: 0,
                              }}
                            />
                          </Form.Item>
                          <Form.Item>
                            <i
                              role="button"
                              tabIndex={0}
                              className="cursor-pointer material-icons mr-1 text-base text-gray-300 hover:text-red-500"
                              onClick={() => {
                                // Remove item in form
                                remove(parameter.name);
                                const newParameters = [
                                  ...form.getFieldsValue(true).parameters,
                                ];
                                newParameters.splice(parameter.key, 1);
                                onChangeFormValue("parameters", newParameters);
                              }}
                            >
                              delete_outline
                            </i>
                          </Form.Item>
                        </div>
                      ))}
                      <Form.Item noStyle>
                        <Button
                          type="dashed"
                          size="small"
                          className="flex items-center"
                          onClick={() => {
                            const newArrayValue = [
                              ...form.getFieldsValue(true).parameters,
                            ];
                            newArrayValue.push({ key: "", value: "" });
                            onChangeFormValue("parameters", newArrayValue);
                            form.setFieldsValue({
                              parameters: [...newArrayValue],
                            });
                          }}
                        >
                          <i className="material-icons text-base mr-1">add</i>
                          {t("app_labels.new")}
                          {t("column_labels.queries").split("|")[0]}
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </Form.Item>
            </TabPane>
            <TabPane
              tab={
                <span className="text-sm">
                  {t("column_labels.output")}
                  {form.getFieldsValue(true)?.outputs?.length ? (
                    <small
                      className="ml-1 bg-yellow-100 rounded-sm text-yellow-700 text-xs"
                      style={{ lineHeight: 0, padding: "1px 7px" }}
                    >
                      {form.getFieldsValue(true)?.outputs?.length}
                    </small>
                  ) : (
                    ""
                  )}
                </span>
              }
              key="3"
              forceRender
            >
              <Form.Item className="bg-gray-50 p-5">
                <Form.List
                  name="outputs"
                  initialValue={Object.keys(node?.data?.outputs || []).map(
                    (key) => {
                      let value = node?.data?.outputs[key];
                      if (typeof value === "object") {
                        value = JSON.stringify(node?.data?.outputs[key]);
                      }
                      return {
                        key,
                        value: value,
                      };
                    }
                  )}
                >
                  {(outputsData, { add, remove }) => (
                    <div className="w-full">
                      {outputsData.map((output, i) => (
                        <div
                          key={output.key}
                          className="w-full flex flex-nowrap space-x-2"
                        >
                          <Form.Item
                            name={[i, "key"]}
                            className="w-full"
                            rules={[
                              {
                                required: true,
                                message: t("validations.required", {
                                  value: t("column_labels.key"),
                                }),
                              },
                              {
                                validator(rule, value) {
                                  const outputs = form
                                    .getFieldsValue(true)
                                    .outputs.map((i) => i.key);
                                  const dupOutputs = outputs.filter(
                                    (e, i, a) => a.indexOf(e) !== i
                                  );
                                  if (dupOutputs.includes(value)) {
                                    return Promise.reject(
                                      t("automation.duplicate_header", {
                                        item: t(
                                          "column_labels.outputs"
                                        ).toLowerCase(),
                                      })
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) =>
                                onChangeFormValue("outputs", [
                                  ...form.getFieldsValue(true).outputs,
                                  { key: e.target.value },
                                ])
                              }
                              placeholder="key"
                              className="w-full text-xs px-0 py-2"
                              bordered={false}
                              style={{
                                borderBottom: "1px solid #D1D5DB",
                                borderRadius: 0,
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[i, "value"]}
                            className="w-full"
                            rules={[
                              {
                                required: true,
                                message: t("validations.required", {
                                  value: t("column_labels.value"),
                                }),
                              },
                            ]}
                          >
                            <Input
                              ref={(el) =>
                                (outputValuesInputRef.current[output.key] = el)
                              }
                              onChange={(e) =>
                                onChangeFormValue("outputs", [
                                  ...form.getFieldsValue(true).outputs,
                                  { value: e.target.value },
                                ])
                              }
                              onDragEnter={() =>
                                outputValuesInputRef.current[output.key].focus()
                              }
                              onDragOver={onDragOverInput}
                              onDrop={(e, formItem = "outputs") => {
                                e.preventDefault();
                                const variable =
                                  e.dataTransfer.getData("variable");
                                const { value, selectionEnd, selectionStart } =
                                  e.target;
                                const newValue =
                                  value.slice(0, selectionStart) +
                                  variable +
                                  value.slice(selectionEnd);
                                const newArrayValue = [
                                  ...form.getFieldsValue(true)[formItem],
                                ];
                                newArrayValue[output.name].value = newValue;
                                onChangeFormValue(formItem, newArrayValue);
                                form.setFieldsValue({
                                  [formItem]: [...newArrayValue],
                                });
                              }}
                              placeholder="value"
                              className="w-full text-xs px-0 py-2"
                              bordered={false}
                              style={{
                                borderBottom: "1px solid #D1D5DB",
                                borderRadius: 0,
                              }}
                            />
                          </Form.Item>
                          <Form.Item>
                            <i
                              role="button"
                              tabIndex={0}
                              className="cursor-pointer material-icons mr-1 text-base text-gray-300 hover:text-red-500"
                              onClick={() => {
                                // Remove item in form
                                remove(output.name);
                                const newOutputs = [
                                  ...form.getFieldsValue(true).outputs,
                                ];
                                newOutputs.splice(output.key, 1);
                                onChangeFormValue("outputs", newOutputs);
                              }}
                            >
                              delete_outline
                            </i>
                          </Form.Item>
                        </div>
                      ))}
                      <Form.Item noStyle>
                        <Button
                          type="dashed"
                          size="small"
                          className="flex items-center"
                          onClick={() => {
                            const newArrayValue = [
                              ...form.getFieldsValue(true).outputs,
                            ];
                            newArrayValue.push({ key: "", value: "" });
                            onChangeFormValue("outputs", newArrayValue);
                            form.setFieldsValue({
                              outputs: [...newArrayValue],
                            });
                          }}
                        >
                          <i className="material-icons text-base mr-1">add</i>
                          {t("app_labels.new")} {t("column_labels.output")}
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </Form.Item>
            </TabPane>
          </Tabs>
          <Form.Item
            name="body"
            initialValue={JSON.stringify(node?.data?.body, null, "\t")}
            label={
              <div>
                <h3 class="text-gray-500 text-xs mb-1 font-bold">
                  {t("column_labels.request_body")}
                </h3>
                <p className="text-sm text-gray-400">
                  {t("automation.please_make_sure_content_type")}
                </p>
              </div>
            }
            rules={[
              {
                validator(rule, value) {
                  if (value) {
                    try {
                      JSON.parse(value);
                      return Promise.resolve();
                    } catch (e) {
                      return Promise.reject("Invalid JSON format");
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <TextArea
              ref={bodyInputRef}
              rows={8}
              placeholder={t("automation.enter_or_drag")}
              onChange={(e) => onChangeFormValue("body", e.target.value)}
              onDragEnter={() => bodyInputRef.current.focus()}
              onDragOver={onDragOverInput}
              onDrop={(e) => onDropInput(e, "body")}
              onKeyDown={(e) =>
                onTabTextArea(e, { fieldItem: "body", value: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            name="timeoutSeconds"
            initialValue={moment()
              .startOf("day")
              .add(moment.duration({ s: node?.data?.timeoutSeconds || 0 }))}
            label={
              <h3 class="text-gray-500 text-xs mb-1 font-bold capitalize">
                {t("automation.timeout")}
              </h3>
            }
          >
            <TimePicker
              showTime
              format={`m [minutes] s [seconds]`}
              onChange={(value) => {
                form.setFieldsValue({ timeoutSeconds: value });
                onChangeFormValue("timeoutSeconds", value);
              }}
              disabledMinutes={() => [
                5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
                38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
                54, 55, 56, 57, 58, 59, 60,
              ]}
              showNow={false}
              use12Hours={false}
              allowClear={false}
              hideDisabledOptions
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={!form.isFieldsTouched(false)}
            >
              {t("actions.update")}
            </Button>
            <Popconfirm
              placement="top"
              title={t("confirmations.delete2", { item: t("automation.step") })}
              onConfirm={onConfirmDelete}
              okText={`${t("actions.delete")} ${t("automation.step")}`}
              cancelText={t("actions.cancel")}
            >
              <Button type="text" className="w-full mt-2" danger>
                {t("actions.delete")}
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
        {/* <code className="whitespace-pre-wrap text-xs fixed bottom-0 left-0 bg-white z-20">
          {JSON.stringify(form.getFieldsValue(true), null, "\t")}
        </code> */}
      </section>
    </>
  );
};

export default HttpRequestForm;
