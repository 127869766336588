import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import i18next from "i18next";
import Root from "@/root.component";

import "@/utils/i18n.js";

import "@/assets/css/main.css";
import "@/assets/css/app.less";
import { setHttpConfig } from "./utils/http-config";

// Dom `automation` was attach in main-portal
function domElementGetter() {
  return document.getElementById("automation");
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: (props) => {
    const { localeMessages, httpConfig } = props;

    setHttpConfig(httpConfig);

    Object.keys(localeMessages).forEach((v) => {
      i18next.addResourceBundle(v, "translation", localeMessages[v]);
    });

    return <Root />;
  },
  domElementGetter,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
