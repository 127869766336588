import { configureStore } from "@reduxjs/toolkit";
import workflowDefinitions from "@/store/reducers/workflows/definitions";
import workflowUsage from "@/store/reducers/workflows/usage";
import flow from "@/store/reducers/flow";
import user from "@/store/reducers/user";
import templates from "@/store/reducers/templates";
import commons from "@/store/reducers/commons";

const store = configureStore({
  reducer: {
    workflowDefinitions,
    workflowUsage,
    flow,
    user,
    templates,
    commons,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
