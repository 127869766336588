import React, { useState, useRef, useEffect, useMemo } from "react";
import { Handle } from "react-flow-renderer";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import nodes from "@/json/nodes";

import StepList from "@/components/compatibleStepList";
import Bubble from "@/components/bubble";
import Tag from "@/components/tag";
import SecureImage from "@/components/secureImage";

const handlerTargetStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "-7px",
  bottom: "auto",
  transform: "translateX(-50%)",
};

const handlerSourceStyle = {
  position: "absolute",
  right: "auto",
  left: "50%",
  top: "auto",
  bottom: "-7px",
  transform: "translateX(-50%)",
};

const ChatAppNode = (node) => {
  const { elements } = useSelector((state) => state.flow);

  const { t } = useTranslation();

  const isValidConnection = (destinationNode) => {
    const currentNode = elements[node.id];
    const targetNode = elements[destinationNode];
    if (!targetNode || destinationNode === node.id) return;
    return nodes[currentNode.type].compatibleNode.includes(targetNode.type);
  };

  const showStepRef = useRef(null);
  const [showStepsBubble, setShowStepsBubble] = useState(false);

  const addChild = (type) => {
    node.data.handleCreateNewNode(type, {
      ...elements[node.id],
      id: node.id,
    });
    setShowStepsBubble(false);
  };

  const stepOptionsComponent = (
    <Bubble
      visible={showStepsBubble}
      style={{ maxHeight: "100px" }}
      className="overflow-y-auto"
      handleClickOutside={() => setShowStepsBubble(false)}
      wrapperRef={showStepRef}
    >
      <div className="py-4 px-2 w-60">
        <StepList
          visible={true}
          nodeType={elements[node.id]?.type}
          handleSelectStep={(step) => addChild(step)}
        />
      </div>
    </Bubble>
  );

  const isExistInSource = (source, sourceHandle) => {
    if (sourceHandle !== "default-handle") return false;
    return elements[source].target.includes(node.id);
  };

  // Set node as 'selected' if input is empty upon initialize so that this node will appear on the sidebar
  useEffect(() => {
    if (!Object.keys(elements[node.id]?.inputs || {}).length) {
      node.data.handleShowNodeDetail(node);
    }
  }, []);

  // Loading for image preview
  const [imagePreviewLoading, setImagePreviewLoading] = useState(false);
  useEffect(() => {
    setImagePreviewLoading(true);
  }, [node?.data?.content?.url]);

  // Load template message
  const templateMessage = useMemo(() => {
    const subAccountId = node?.data?.subAccountId;
    const templateName = node?.data?.content?.template?.name;

    if (!subAccountId || !templateName) return null;

    return templateName;
  }, [node?.data?.subAccountId, node?.data?.content?.template?.name]);

  return (
    <div className="bg-white shadow rounded w-72 relative custom_node">
      <header className="text-base px-4 py-2 flex items-center font-bold text-gray-700 border-b border-gray-200 relative">
        {!Object.keys(elements[node.id]?.inputs || {}).length ? (
          <Tag className="absolute -top-2 right-2 text-white bg-yellow-500">
            {t("app_labels.new").toUpperCase()}
          </Tag>
        ) : (
          ""
        )}
        <Handle
          type="target"
          position="top"
          style={handlerTargetStyle}
          isValidConnection={({ source, sourceHandle }) =>
            isValidConnection(source) && !isExistInSource(source, sourceHandle)
          }
        />
        <span
          role="presentation"
          onClick={() => node.data.handleShowNodeDetail(node)}
          class="w-full flex"
        >
          <span className="-mt-6 inline-flex justify-center items-center w-12 h-12 p-1 rounded bg-gradient-to-tr from-blue-500 to-indigo-500 mr-2">
            <i className="material-icons text-white text-2xl">chat</i>
          </span>
          {elements[node.id]?.inputs?.nameId || node?.data?.nameId ? (
            <span className="ml-1 block truncate text-gray-600 bg-gray-100 py-1 px-2 text-sm rounded-full font-bold text-center">
              {elements[node.id]?.inputs?.nameId || node?.data?.nameId}
            </span>
          ) : (
            <span class="text-sm">{t("automation.nodes.send_chatapp")}</span>
          )}
        </span>
      </header>
      <div
        role="presentation"
        onClick={() => node.data.handleShowNodeDetail(node)}
      >
        <figure className="px-4 py-3 flex items-center">
          <figcaption className="text-gray-500 text-xs mr-2">To</figcaption>
          <p className="truncate text-blue-600 bg-blue-100 py-1 px-2 text-xs rounded-full font-bold tracking-wide inline-flex items-center break-all">
            {node?.data?.user?.msisdn || "John Doe"}
          </p>
        </figure>
        {templateMessage ? (
          <figure className="px-4 py-2 flex flex-col border-gray-200 border-t border-dashed relative">
            <figcaption className="text-gray-500 text-xs mb-1">
              {t("automation.whats_app_template")}
            </figcaption>
            <div className="relative">
              <p className="text-xs truncate font-bold whitespace-pre-line overflow-hidden text-gray-900">
                {templateMessage || "No Template"}
              </p>
            </div>
            <span className="p-2 pb-0 mb-2 w-full bg-opacity-50 absolute left-0 right-0 bottom-0 bg-gradient-to-t from-white via-white to-transparent"></span>
          </figure>
        ) : (
          <figure className="px-4 py-2 flex flex-col border-gray-200 border-t border-dashed relative">
            <figcaption className="text-gray-500 text-xs mb-1">
              {t("column_labels.message")}
            </figcaption>
            <div className="relative">
              <p
                className={`text-xs font-bold whitespace-pre-line overflow-hidden ${
                  node?.data?.content?.text ? "text-gray-800" : "text-gray-400"
                }`}
                style={{ maxHeight: "100px" }}
              >
                {/* {node?.data?.content?.url ? (
                  <SecureImage
                    source={node?.data?.content?.url}
                    onLoad={() => setImagePreviewLoading(false)}
                    alt="chat-preview"
                    className={`h-auto bg-gray-300 rounded float-right ml-3 ${
                      node?.data?.content?.text ? "w-12" : "w-full"
                    }`}
                  />
                ) : (
                  ""
                )} */}
                <span class="truncate">
                  {node?.data?.content?.text || t("automation.say_hello_c")}
                </span>
              </p>
            </div>
            <span className="p-2 pb-0 mb-2 w-full bg-opacity-50 absolute left-0 right-0 bottom-0 bg-gradient-to-t from-white via-white to-transparent"></span>
          </figure>
        )}
      </div>
      <footer className="relative">
        <span
          role="button"
          tabIndex={0}
          ref={showStepRef}
          onClick={(e) => {
            e.preventDefault();
            setShowStepsBubble(!showStepsBubble);
            node.data.handleHideSidebar();
          }}
          className={`cursor-pointer border-t border-gray-200 flex items-center justify-center text-blue-500 p-2 font-medium rounded-b transition-colors duration-100 ease-in hover:bg-blue-500 hover:text-white relative ${
            showStepsBubble ? "bg-blue-500 text-white" : ""
          }`}
        >
          <i className="material-icons text-lg mr-1">add</i>
          {!(node?.data?.target).length
            ? t("automation.add_next_step")
            : t("automation.insert_next_step")}
        </span>
        <Handle
          id="a"
          isValidConnection={({ target }) => isValidConnection(target)}
          type="source"
          position="bottom"
          style={handlerSourceStyle}
        />
        {stepOptionsComponent}
      </footer>
    </div>
  );
};

export default ChatAppNode;
