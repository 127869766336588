/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useEffect, useRef, useState } from "react";
import { parsePhoneNumber } from "awesome-phonenumber";
import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, Radio, Upload, message, notification } from "antd";
const { TextArea } = Input;
const { Dragger } = Upload;

import { useTranslation, Trans } from "react-i18next";

import SecureImage from "@/components/secureImage";

const ViberForm = ({
  node,
  form,
  onChangeFormValue,
  onDragOverInput,
  onDropInput,
  onUploadFile,
  channelType,
}) => {
  const msisdnInputRef = useRef(null);
  const previewImageRef = useRef(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [invalidImage, setInvalidImage] = useState(false);

  const { t } = useTranslation();

  const contentTypes = [
    {
      label: "Text",
      value: "text",
      icon: "description",
      labelts: t("automation.content_types.0"),
    },
    {
      label: "Image",
      value: "image",
      icon: "image",
      labelts: t("automation.content_types.1"),
    },
    {
      label: "Rich media",
      value: "buttons",
      icon: "mms",
      labelts: t("automation.content_types.2"),
    },
  ];

  return (
    <>
      <Form.Item
        name="msisdn"
        initialValue={node?.data?.user?.msisdn || form?.from || ""}
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("column_labels.destinations").split("|")[0]}
          </h3>
        }
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.destinations").split("|")[0],
            }),
          },
          {
            validator(rule, value, callback) {
              const isVariable = String(value || "").match(/^{{.*}}$/g);
              if (!isVariable && !parsePhoneNumber(value).valid) {
                return Promise.reject(
                  t("validations.invalid", {
                    value: t("column_labels.phone_no"),
                  })
                );
              }

              return Promise.resolve();
            },
          },
        ]}
        className="p-5"
      >
        <Input
          ref={msisdnInputRef}
          placeholder={t("automation.enter_or_drag")}
          onChange={(e) => onChangeFormValue("msisdn", e.target.value)}
          onDragEnter={() => msisdnInputRef.current.focus()}
          onDragOver={onDragOverInput}
          onDrop={(e) => onDropInput(e, "msisdn")}
        />
      </Form.Item>
      <Form.Item
        name="type"
        initialValue={node?.data?.type || form?.type || contentTypes[0]?.value}
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.type"),
            }),
          },
        ]}
        className="px-5"
      >
        <Radio.Group
          onChange={(e) => {
            onChangeFormValue("type", e.target.value);
          }}
          className="flex flex-nowrap"
        >
          {contentTypes.map((type) => (
            <Radio.Button
              key={`wa-content-${type.value}`}
              value={type.value}
              className="w-full"
            >
              <div className="flex items-center justify-center">
                <i className="material-icons text-xl mr-1 leading-8">
                  {type.icon}
                </i>
                <span className="text-xs">{t(type.labelts)}</span>
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        className="p-5"
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("actions.preview")}
          </h3>
        }
      >
        <dialog className="p-3 bg-white rounded shadow whitespace-pre-wrap block break-words relative w-full border border-gray-100 space-y-3">
          {form.getFieldsValue(false)?.text &&
          form.getFieldsValue(false)?.type !== "image" ? (
            <p className="p-0">{form.getFieldsValue(false)?.text || ""}</p>
          ) : (
            ""
          )}
          {(form.getFieldsValue(false)?.url &&
            form.getFieldsValue(false)?.type !== "text") ||
          loadingImage ? (
            <SecureImage
              ref={previewImageRef}
              source={form.getFieldsValue(false)?.url || ""}
              onLoad={() => {
                setLoadingImage(false);
                setInvalidImage(false);
              }}
              onError={() => setInvalidImage(true)}
              className={`w-full h-auto block rounded bg-gray-100 ${
                loadingImage ? "bg-gray-300" : ""
              }`}
            />
          ) : (
            ""
          )}
          {form.getFieldsValue(false)?.buttonText ? (
            <p
              className="py-2 px-3 bg-blue-500 text-white rounded-full text-center table mx-auto font-bold"
              style={{ minWidth: "100px" }}
            >
              {form.getFieldsValue(false)?.buttonText || ""}
            </p>
          ) : (
            ""
          )}
        </dialog>
      </Form.Item>
      {form.getFieldsValue(false)?.type === "text" ||
      form.getFieldsValue(false)?.type === "buttons" ? (
        <TextForm
          node={node}
          form={form}
          onChangeFormValue={onChangeFormValue}
          onDragOverInput={onDragOverInput}
          onDropInput={onDropInput}
        />
      ) : (
        ""
      )}
      {form.getFieldsValue(false)?.type === "image" ||
      form.getFieldsValue(false)?.type === "buttons" ? (
        <MediaForm
          node={node}
          form={form}
          imagevPreviewRef={previewImageRef}
          onChangeFormValue={onChangeFormValue}
          onUploadFile={onUploadFile}
          loadingImage={loadingImage}
          invalidImage={invalidImage}
          setInvalidImage={(isValid) => setInvalidImage(isValid)}
          setLoadingImage={setLoadingImage}
          channelType={channelType}
        />
      ) : (
        ""
      )}
      {form.getFieldsValue(false)?.type === "buttons" ? (
        <Form.Item
          label={
            <h3 class="text-gray-500 text-xs mb-1 font-bold">
              {t("column_labels.button_text")}
            </h3>
          }
          className="px-5"
        >
          <Form.Item
            name="buttonText"
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("column_labels.button_text"),
                }),
              },
            ]}
            className="w-1/3 rounded-l float-left"
          >
            <Input
              placeholder={t("column_labels.button_text")}
              onChange={(e) => onChangeFormValue("buttonText", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="buttonUrl"
            rules={[
              {
                required: true,
                message: t("validations.required", {
                  value: t("column_labels.url"),
                }),
              },
              {
                type: "url",
                message: t("validations.invalid", {
                  value: t("column_labels.url"),
                }),
              },
            ]}
            className="w-2/3 rounded-l float-left pl-1"
          >
            <Input
              placeholder={t("column_labels.url")}
              onChange={(e) => onChangeFormValue("buttonUrl", e.target.value)}
            />
          </Form.Item>
        </Form.Item>
      ) : (
        ""
      )}
    </>
  );
};

const TextForm = ({
  node,
  form,
  onChangeFormValue,
  onDragOverInput,
  onDropInput,
}) => {
  const textInputRef = useRef(null);

  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name="text"
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {t("column_labels.message")}
          </h3>
        }
        initialValue={node?.data?.content?.text || form?.templateName || ""}
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.message"),
            }),
          },
        ]}
        className="px-5"
      >
        <TextArea
          ref={textInputRef}
          rows={8}
          placeholder={t("automation.enter_or_drag")}
          onChange={(e) => onChangeFormValue("text", e.target.value)}
          onDragEnter={() => textInputRef.current.focus()}
          onDragOver={onDragOverInput}
          onDrop={(e) => onDropInput(e, "text")}
          showCount
          maxLength={1000}
        />
      </Form.Item>
    </>
  );
};

const MediaForm = ({
  node,
  form,
  imagevPreviewRef,
  onChangeFormValue,
  onUploadFile,
  loadingImage,
  invalidImage,
  setInvalidImage,
  setLoadingImage,
  channelType,
}) => {
  const acceptedFileTypes = ".png,.jpg,.jpeg";
  const { t } = useTranslation();

  // Validate file type and file size
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({ message: "You can only upload JPG/PNG file!" });
    }
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      notification.error({ message: "Image must smaller than 1.5MB!" });
    }
    return isJpgOrPng && isLt2M;
  };

  const onChange = async ({ file: f }) => {
    const { originFileObj: file } = f;
    setLoadingImage(true);
    try {
      const data = await onUploadFile(file);
      onChangeFormValue("url", data);
      form.setFieldsValue({ url: data });
    } catch (err) {
      throw new Error(err);
    }
  };

  // Loading for image preview
  useEffect(() => {
    if (node?.data?.content?.url) {
      setLoadingImage(true);
    }
  }, [node?.data?.content?.url]);

  // Extract the filename along with the type
  const filenameFromUrl = (url, defaultName = "Untitled") => {
    let name = defaultName;
    if (url && typeof url === "string") {
      const matches = url.match(/(?<=\/)[^\/\?#]+(?=[^\/]*$)/g);
      name = (matches || []).length ? matches[0] : name;
    }
    return name;
  };

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const url = node?.data?.content?.url || "";

    const name = filenameFromUrl(url);

    setFileList([
      {
        uid: "1",
        name,
        status: "done",
        url,
      },
    ]);
  }, [node?.data?.content?.url]);

  const uploadInputs = (
    <>
      <Form.Item
        name="url"
        initialValue={node?.data?.content?.url || form?.url || ""}
        rules={[
          {
            required: true,
            message: t("validations.required", {
              value: t("column_labels.url").toLowerCase(),
            }),
          },
          {
            type: "url",
            message: t("validations.invalid", {
              value: t("column_labels.url").toLowerCase(),
            }),
          },
          {
            validator(rule, value) {
              return new Promise((resolve, reject) => {
                const img = new Image();

                img.src = value;

                img.addEventListener("load", () => {
                  resolve();
                });

                img.addEventListener("error", () => {
                  reject(t("mcs.messages.chat_apps.media_url[6]"));
                });
              });
            },
          },
        ]}
        validateFirst={true}
      >
        <Input
          onChange={(e) => {
            onChangeFormValue("url", e.target.value);

            // Set fileList to empty
            setFileList([]);
          }}
        />
      </Form.Item>
    </>
  );

  const imagePreview = (
    <Form.Item
      name="url"
      rules={[
        {
          required: true,
          message: t("validations.required", {
            value: t("column_labels.url"),
          }),
        },
        {
          type: "url",
          message: t("validations.invalid", {
            value: t("column_labels.url").toLowerCase(),
          }),
        },
        {
          validator(rule, value) {
            return new Promise((resolve, reject) => {
              const img = new Image();

              img.src = value;

              img.addEventListener("load", () => {
                resolve();
              });

              img.addEventListener("error", () => {
                reject(t("mcs.messages.chat_apps.media_url[6]"));
              });
            });
          },
        },
      ]}
      validateFirst={true}
    >
      <div className="p-4 rounded-sm border border-gray-200 bg-gray-100 text-gray-800 w-full flex justify-between items-center">
        <div className="space-x-2 flex items-center">
          {loadingImage ? <LoadingOutlined /> : ""}
          <span className="text-xs font-bold">
            {filenameFromUrl(form.getFieldsValue(false)?.url)}
          </span>
        </div>
        <a
          role="button"
          className="text-xs text-gray-500 hover:underline hover:text-red-500"
          onClick={(e) => {
            e.preventDefault();
            onChangeFormValue("url", "");
            form.setFieldsValue({ url: "" });
            setFileList([]);
            setInvalidImage(false);
          }}
        >
          {t("actions.remove")}
        </a>
      </div>
    </Form.Item>
  );

  return (
    <>
      <Form.Item
        label={
          <h3 class="text-gray-500 text-xs mb-1 font-bold">
            {form.getFieldsValue(false)?.url
              ? t("mcs.messages.chat_apps.media_url.7")
              : t("mcs.messages.chat_apps.media_url.3")}
          </h3>
        }
        className="px-5"
      >
        {(loadingImage || !invalidImage) && form.getFieldsValue(false)?.url
          ? imagePreview
          : ""}
        <div
          className={
            (loadingImage || !invalidImage) && form.getFieldsValue(false)?.url
              ? "hidden"
              : ""
          }
        >
          {uploadInputs}
        </div>
      </Form.Item>
    </>
  );
};

export default ViberForm;
