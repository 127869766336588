const TemplateHeader = ({ template }) => {
  const header = (template?.components || []).find((t) => t.type === "HEADER");

  let headerFormat = null;
  if (!Object.keys(header || {}).length) {
    headerFormat = null;
  } else if (header?.format === "TEXT") {
    headerFormat = <p className="text-base text-gray-900">{header.text}</p>;
  } else if (header.format === "IMAGE") {
    headerFormat = (
      <p className="flex justify-center">
        <i className="material-icons text-4xl text-gray-300">image</i>
      </p>
    );
  } else if (header?.format === "DOCUMENT") {
    headerFormat = (
      <p className="flex justify-center">
        <i className="material-icons text-4xl text-gray-300">description</i>
      </p>
    );
  } else if (header?.format === "LOCATION") {
    headerFormat = (
      <p className="flex justify-center">
        <i className="material-icons text-4xl text-gray-300">location_on</i>
      </p>
    );
  } else if (header?.format === "VIDEO") {
    headerFormat = (
      <p className="flex justify-center">
        <i className="material-icons text-4xl text-gray-300">videocam</i>
      </p>
    );
  } else {
    headerFormat = <code>{JSON.stringify(header)}</code>;
  }

  return header ? (
    <div className="p-3 text-xs text-gray-500">{headerFormat}</div>
  ) : (
    ""
  );
};

export default TemplateHeader;
