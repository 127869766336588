import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import http from "@/utils/http-request";

const initialState = {
  templates: [],
  enums: {},
  optOutContactGroup: {},
  createdOptOutContactGroup: {},
  templatesLoading: false,
  enumsLoading: false,
  getOptOutContactGroupLoading: false,
  createOptOutContactGroupLoading: false,
};

export const get = createAsyncThunk(
  "user/get",
  async (payload, { dispatch, rejectWithValue }) => {
    const { channelId } = payload;

    try {
      const { data } = await http.v2.get(
        `/chats/templates?channelId=${channelId}&status=APPROVED`
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getEnums = createAsyncThunk(
  "user/getEnums",
  async (payload, { dispatch, rejectWithValue }) => {
    const { channelId } = payload;

    try {
      const { data } = await http.v2.get(
        `/chats/templates/enums?channelId=${channelId}`
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOptOutContactGroup = createAsyncThunk(
  "user/getOptOutContactGroup",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await http.v1.get(
        "/contacts-management/groups?limit=20&search=Opt-out Contact Group"
      );
      const optOutContactGroup = data?.items?.find(
        (group) => group.name === "Opt-out Contact Group"
      );
      return optOutContactGroup;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createOptOutContactGroup = createAsyncThunk(
  "user/createOptOutContactGroup",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await http.v1.post("/contacts-management/groups", {
        name: "Opt-out Contact Group",
        description:
          "System generated for Opt-out template usage in Automation builder",
        isBlacklist: true,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const definitionsSlice = createSlice({
  name: "user",

  initialState,
  // reducers actions
  reducers: {},
  extraReducers: {
    [get.pending]: (state, action) => {
      state.templatesLoading = true;
    },
    [get.fulfilled]: (state, action) => {
      const data = action.payload;
      state.templates = data;
      state.templatesLoading = false;
    },
    [get.rejected]: (state, action) => {
      state.templatesLoading = false;
    },

    [getEnums.pending]: (state, action) => {
      state.enumsLoading = true;
    },
    [getEnums.fulfilled]: (state, action) => {
      const data = action.payload;
      state.enums = data;
      state.enumsLoading = false;
    },
    [getEnums.rejected]: (state, action) => {
      state.enumsLoading = false;
    },

    [getOptOutContactGroup.pending]: (state, action) => {
      state.getOptOutContactGroupLoading = true;
    },
    [getOptOutContactGroup.fulfilled]: (state, action) => {
      const data = action.payload;
      state.optOutContactGroup = data;
      state.getOptOutContactGroupLoading = false;
    },
    [getOptOutContactGroup.rejected]: (state, action) => {
      state.getOptOutContactGroupLoading = false;
    },

    [createOptOutContactGroup.pending]: (state, action) => {
      state.createOptOutContactGroupLoading = true;
    },
    [createOptOutContactGroup.fulfilled]: (state, action) => {
      const data = action.payload;
      state.createdOptOutContactGroup = data;
      state.createOptOutContactGroupLoading = false;
    },
    [createOptOutContactGroup.rejected]: (state, action) => {
      state.createOptOutContactGroupLoading = false;
    },
  },
});

const { reducer } = definitionsSlice;

export default reducer;
